export enum OrderRewardsWithoutSeasonTypes {
  LOAD_REQUEST = '@orderRewardsWithoutSeason/LOAD_REQUEST',
  LOAD_SUCCESS = '@orderRewardsWithoutSeason/LOAD_SUCCESS',
}

export interface IOrderRewardsWithoutSeasonRequest {
  RewardID: number;
  TicketsItemID: number;
  StateId: number;
  ActivationChannel: string;
}

export interface IOrderRewardsWithoutSeasonResponse {
  orderRewardID: number;
  ticketItemAmount: number;
  name: string;
  location: string;
  ticketPickUpPlace: string;
  date: string;
  time: string;
  qrCode: string;
  qrCodeUrlImage: string;
  howtoUse: string;
  isSponsored: boolean;
  partnerID: number;
  locationID: number;
  
  rewardID: number;
  description: string;
  howToUs: string;
  ownTerms: boolean;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  defaultText: string;
  alertConfirmRedemption: string;
  aboutEvent: string;
  termsOfUseSponsorship: string;
  addressLocation: string;
}

export interface IOrderRewardsWithoutSeasonState {
  readonly req: IOrderRewardsWithoutSeasonRequest;
  readonly data: IOrderRewardsWithoutSeasonResponse;
  readonly loading: boolean;
}
