export enum RewardCellphoneAndGadgetsOrderTypes {
  LOAD_REQUEST = '@reward_cellphone_and_gadgets_order/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_cellphone_and_gadgets_order/LOAD_SUCCESS',
  LOAD_RESET = '@reward_cellphone_and_gadgets_order/LOAD_RESET',
}

export interface IRewardCellphoneAndGadgetsOrderRequest {
  RewardID: number;
  StateID: number | undefined | string;
  ActivationChannel: string;
}

export interface IRewardCellphoneAndGadgetsOrderResponse {
  orderRewardID: number;
  partnerID: number;
  rewardID: number;
  tag: string;
  title: string;
  endDate: string;
  description: string;
  howToUse: string;
  success?: boolean;
  errorMessages?: any[];
}

export interface IRewardCellphoneAndGadgetsOrderState {
  readonly data: IRewardCellphoneAndGadgetsOrderResponse;
  readonly loading: boolean;
}
