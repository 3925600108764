import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import API from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* sendTokenSMS(action) {
  try {
    const { data } = yield call(
      API.post,
      'v1/api/security/token/store/password2fa',
      action.payload.data,
    );
    yield put(loadSuccess(data));
  } catch (error) {
    yield put(push('/participante-bloqueado'));
  }
}
