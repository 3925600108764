import { Reducer } from 'redux';
import { QrCodeTypes, IQrCodeState } from './types';

const INITIAL_STATE: IQrCodeState = {
  data: {
    qrCodeImageUrl: '',
  },
  loading: false,
  error: false,
};

const reducer: Reducer<IQrCodeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QrCodeTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case QrCodeTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          qrCodeImageUrl: action.payload.data,
        },
      };
    case QrCodeTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: {
          qrCodeImageUrl: '',
        },
      };
    case QrCodeTypes.RESET_QRCODE:
      return {
        ...INITIAL_STATE,
      };
    default:
      return state;
  }
};

export default reducer;
