import PrivateRoutesWithSubRoutes from 'components/PrivateRoutesWithSubRoutes/PrivateRoutesWithSubRoutes';
import React, { lazy } from 'react';

const SearchResult = lazy(() => import('pages/SearchResult/SearchResult'));

export enum RoutesSearchResult {
  BUSCA = '/busca',
}

const routes = [
  {
    path: RoutesSearchResult.BUSCA,
    component: SearchResult,
    exact: true,
  },
];

const SurveyRoutes = () => {
  return <PrivateRoutesWithSubRoutes routes={routes} />;
};

export default SurveyRoutes;
