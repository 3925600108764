import { ICategory } from 'store/ducks/category/types';

export enum BenefitOfferDetailsTypes {
  LOAD_REQUEST = '@benefit_offer_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@benefit_offer_details/LOAD_SUCCESS',
  LOAD_RESET = '@benefit_offer_details/LOAD_RESET',
}

export enum TagHomeEnum {
  SEMDADOS = 0,
  NOVIDADE = 1,
  EXPIRAEM = 2,
  ULTIMASUNIDADES = 3,
  QUANTIDADELIMITADA = 4,
  VALIDOATE = 5,
}
export interface IBenefitOfferDetails {
  rewardID: number;
  title: string;
  name: string;
  description: string;
  endDate: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  tag: string;
  terms: string;
  validDate: string;
  category: ICategory[];
  securityTokenProtected: boolean;
  success?: false;
  errorMessages?: false;
  btnRescue: string;
  partnerId: number;
  alertMessage: string;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;

  averageNote: number,
  enableAverage: boolean,
  initialCount: number,
  showCount: boolean,
  tagHome: TagHomeEnum.SEMDADOS;
  timesClicked: number,
}

export interface IBenefitOfferDetailsState {
  readonly data: IBenefitOfferDetails;
  readonly loading: boolean;
}
