import { Reducer } from 'redux';
import { IRewardUnforgettableBannerState, RewardUnforgettableBannerTypes } from './types';

const INITIAL_STATE: IRewardUnforgettableBannerState = {
  data: {
    unforgettableRedeemId: 0,
    title: '',
    linkTitle: '',
    unforgettableRedeemTypeLinkId: 1,
    link: '',
    description: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload480: '',
    urlDownload690: '',
    urlDownload430: '',
  },
  error: false,
  loading: false,
};

const reducer: Reducer<IRewardUnforgettableBannerState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardUnforgettableBannerTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardUnforgettableBannerTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data };
    case RewardUnforgettableBannerTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: {
          unforgettableRedeemId: 0,
          image: '',
          title: '',
          linkTitle: 'Acessar Anteriores',
          unforgettableRedeemTypeLinkId: 1,
          link: '',
          description: '',
          urlDownload210: '',
          urlDownload384: '',
          urlDownload480: '',
          urlDownload690: '',
          urlDownload430: '',
        },
      };

    default:
      return state;
  }
};

export default reducer;
