import { action } from 'typesafe-actions';
import { IRewardInternetPackage, RewardInternetPackageTypes } from './types';
import { IAccounts } from 'store/ducks/participant/accounts/types';

export const getRewardInternetPackageDetails = (id: number, isDeeplink: boolean) =>
  action(RewardInternetPackageTypes.LOAD_REQUEST, { id, isDeeplink });

export const selectRewardInternetPackageAcccount = (phone: IAccounts) =>
  action(RewardInternetPackageTypes.SELECT_ACCOUNT, { phone });

export const setRewardInternetPackageDetails = (reward: IRewardInternetPackage) =>
  action(RewardInternetPackageTypes.SET_REWARD_INTERNET_PACKAGE, { reward });

export const loadSuccess = (data: IRewardInternetPackage) =>
  action(RewardInternetPackageTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardInternetPackageTypes.LOAD_RESET);
