export enum RewardWithoutSeasonDetailsTypes {
  LOAD_REQUEST = '@reward_without_season/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_without_season/LOAD_SUCCESS',
  LOAD_RESET = '@reward_without_season/LOAD_RESET',
}

export interface IRewadWithoutSeasonTicketsOrderItem {
  ticketOrderItemAmount: number;
  rewadTicketsOrderItemID: number;
}

export interface IRewardWithoutSeasonDetails {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  startDate: string;
  maxPurchaseDate: string;
  terms: string;
  address: string;
  ticketPickUpPlace: string;
  genre: string;
  targetAudience: string;
  ageRating: string;
  securityTokenProtected: boolean;
  eventType: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  rewadSessionTicketsOrderItem: IRewadWithoutSeasonTicketsOrderItem[];
  success?: boolean;
  errorMessages?: boolean;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  isSponsored: boolean;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;
  locationID: number;
  
  endDate: string;
  alertMessage: string;
  maxCancellationDate: number;
  tagHome: number;
  averageNote: number;
  enableAverage: boolean;
  timesClicked: number;
  initialCount: number;
  showCount: boolean;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  termsOfUseSponsorship: string;
  alertConfirmRedemption: string;
  aboutEvent: string;
  showVoucherLimitNotification: boolean;
}

export interface IRewardWithoutSeasonDetailsState {
  readonly data: IRewardWithoutSeasonDetails;
  readonly loading: boolean;
}
