import { action } from 'typesafe-actions';
import { IRewards, RewardsTypes } from './types';

export const getRewards = () => action(RewardsTypes.LOAD_REQUEST);

export const loadSuccess = (data: IRewards) => action(RewardsTypes.LOAD_SUCCESS, { data });

export const loadError = () => action(RewardsTypes.LOAD_ERROR);

export const getRewardOfDeepLink = (rewardID: number) =>
  action(RewardsTypes.GET_REWARD_DEEPLINK, { rewardID });

export const resetRewardDeepLink = () => action(RewardsTypes.RESET_REWARD_DEEPLINK);
