export enum SurveyTypes {
  BUTTON_CONTINUE_SHOULD_DISABLED = '@survey/BUTTON_CONTINUE_SHOULD_DISABLED',
  COMPLETED_SURVEY = '@survey/COMPLETED_SURVEY',
  LOAD_REQUEST = '@survey/LOAD_REQUEST',
  LOAD_SUCCESS = '@survey/LOAD_SUCCESS',
  GET_ANSWERS_QUESTIONS = '@survey/GET_ANSWERS_QUESTIONS',
  GET_QUESTION_TYPE = '@survey/GET_QUESTION_TYPE',
  SAVE_SURVEY = '@survey/SAVE_SURVEY',
  SAVE_SURVEY_SUCCESS = '@survey/SAVE_SURVEY_SUCCESS',
  RESET_SURVEY = '@survey/RESET_SURVEY_SUCCESS',
}

export interface IQuestionMultipleChoices {
  questionMultipleChoiceID: number;
  questionID: number;
  text: string;
  checked: boolean;
}

export interface ISurveySuccess {
  name: string;
  description: string;
  link: string;
  linkTitle: string;
}

export interface ISurveyQuestion {
  campaignID: number;
  questionID: number;
  questionText: string;
  position: number;
  questionTypeID: number;
  visible: boolean;
  insertDate: string;
  questionMultipleChoices: IQuestionMultipleChoices[];
  answer?: string;
}

export interface ISurveyState {
  readonly data: ISurveyQuestion[];
  readonly loading: boolean;
  readonly isButtonContinueDisabled: boolean;
  readonly isCompletedSurvey: boolean;
  readonly questionType: number;
  readonly success: ISurveySuccess;
}
