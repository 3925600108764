import { action } from 'typesafe-actions';
import {
  IRewardOfferOrderRequest,
  IRewardOfferOrderResponse,
  RewardOfferOrderTypes,
} from './types';

export const saveOrderRewardOffer = (data: IRewardOfferOrderRequest) =>
  action(RewardOfferOrderTypes.LOAD_REQUEST, { data });
export const loadSuccess = (data: IRewardOfferOrderResponse) =>
  action(RewardOfferOrderTypes.LOAD_SUCCESS, { data });
export const resetOrderRewardOffer = () => action(RewardOfferOrderTypes.LOAD_RESET);
