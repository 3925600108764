// Action types
export enum MobileAccountsTypes {
  LOAD_REQUEST = '@mobile_accounts/LOAD_REQUEST',
  LOAD_REQUEST_ATLYS_AMDOCS = '@mobile_accounts/LOAD_REQUEST_ATLYS_AMDOCS',
  LOAD_SUCCESS = '@mobile_accounts/LOAD_SUCCESS',
}

// Data types
export interface IMobileAccounts {
  accountId: number;
  state: string;
  areaCode: string;
  lineNumber: string;
  areaCodeLineNumber: string;
  externalAccount: number;
  externalProductCode: string;
  platform: string;
  sourceSystem: string;
  channel: string;
  productName: string;
  billingSource: string;
}
// State type
export interface IMobileAccountsState {
  readonly data: IMobileAccounts[];
  readonly loading: boolean;
}
