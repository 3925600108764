export enum BadgesTypes {
  LOAD_REQUEST = '@badges/LOAD_REQUEST',
  LOAD_SUCCESS = '@badges/LOAD_SUCCESS',
  LOAD_RESET = '@badges/LOAD_RESET',
  SELECT_BADGE = '@badges/SELECT_BADGE',
}

export interface IBadge {
  badgeID: number;
  rewardID: number;
  clusterID: number;
  title: string;
  descricao: string;
  badgeIcon: string;
  createDate: string;
  updateDate: string;
}

export interface IBadgesState {
  readonly data: IBadge[];
  readonly loading: boolean;
  readonly badge: IBadge;
}
