import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadTag(action) {
  try {
    let categoryID = action.payload.selectedCategory || 0
    let stateID = action.payload.stateID

    const { data } = yield call(API.get, `v1/api/benefits/tagCategory?categoryId=${categoryID}&stateID=${stateID}`);

    yield put(loadSuccess(data.data));
  } catch (error) {
    console.log(error);
    yield put(setError(error.response));
  }
}