export const ErrorTypes = {
  SET: 'error/SET_ERROR',
};

export interface IEMessages {
  code: string;
  description: string;
  internalCode: number;
  internalDescription: string;
}

export interface IErrorMessages {
  errorMessages: IEMessages[];
}

export interface ErrorState {
  data: IErrorMessages;
  success?: boolean;
}
