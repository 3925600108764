import PrivateRoutesWithSubRoutes from 'components/PrivateRoutesWithSubRoutes/PrivateRoutesWithSubRoutes';
import React, { lazy } from 'react';

const MyValoriza = lazy(() => import('pages/MyValoriza/MyValoriza'));
const Categories = lazy(() => import('pages/Categories/Categories'));
const MyBadges = lazy(() => import('pages/MyBadges/MyBadges'));

const routes = [
  { path: '/meu-valoriza', exact: true, component: MyValoriza },
  { path: '/meu-valoriza/categorias', component: Categories },
  { path: '/meu-valoriza/minhas-medalhas', component: MyBadges },
];

const MyValorizaRoutes = () => {
  return <PrivateRoutesWithSubRoutes routes={routes} />;
};

export default MyValorizaRoutes;
