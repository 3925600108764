import IParticipant from '_models/participant/iparticipant';

export enum AuthenticateTypes {
  LOAD_REQUEST = '@authenticate/LOAD_REQUEST',
  LOAD_SUCCESS = '@authenticate/LOAD_SUCCESS',
  LOAD_VALID_TOKEN = '@authenticate/LOAD_VALID_TOKEN',
  RESET_AUTHENTICATE = '@authenticate/RESET_AUTHENTICATE',
  REDIRECT_DEEP_LINK = '@authenticate/REDIRECT_DEEP_LINK',
  LOAD_OAUTH_AUTHENTICATION = '@authenticate/LOAD_OAUTH_AUTHENTICATION',
  PARTICIPANT_LOGGED = '@authenticate/PARTICIPANT_LOGGED',
  PARTICIPANT_LOGOUT = '@authenticate/PARTICIPANT_LOGOUT',
}

export interface IToken {
  accessToken: string;
  tokenType: string;
  tokenCreate: Date | null;
  expiresIn: Date | null;
  data: any;
}

export interface IPayloadAuthenticate {
  cookie: string;
  // deepLink: IDeepLink;
  oauth: number;
}
export interface IPayloadActionAuthenticate {
  payload: {
    cookie: string;
    deepLink: IDeepLink;
    oauth: number;
  };
}

export interface IAppEnvironment {
  isOauthAuthentication: boolean;
  appEnvironment: number;
  cookie: string;
}

export interface IDeepLink {
  benefitId?: string | null;
  categoryId?: string | null;
  rewardId?: string | null;
  tagId?: string | null;
}

export interface IAuthenticateState {
  data: IToken;
  loading: boolean;
  participant: IParticipant;
  environment: IAppEnvironment;
  deepLink: IDeepLink;
  participantLogged: boolean;
  isConfiguredAppEnvironment: boolean;
}
