import { Reducer } from 'redux';
import { IOrderBenefitWithSeasonState, OrderBenefitWithSeasonTypes } from './types';

const INITIAL_STATE: IOrderBenefitWithSeasonState = {
  req: {
    RewardID: '',
    SessionID: '',
    SessionTicketID: '',
    StateId: '',
    ActivationChannel: '',
  },
  data: {
    orderRewardID: 0,
    name: '',
    location: '',
    date: '',
    time: '',
    qrCode: '',
    qrCodeUrlImage: '',
    howtoUse: '',
    ticketItemAmount: 0,
    sessionName: '',
    isSponsored: false,
    ticketPickUpPlace: '',
    rewardID: 0,
    partnerID: 0,
    locationID: 0,
    enabledCTA: false,
    rewadSessionTicketsOrderItem: [],
  },
  loading: true,
};

const reducer: Reducer<IOrderBenefitWithSeasonState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderBenefitWithSeasonTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case OrderBenefitWithSeasonTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
