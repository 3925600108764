import { Reducer } from 'redux';
import { IRewardOfferOrderState, RewardOfferOrderTypes } from './types';
import { IRewardJourney } from '_models/RewardJourney/IRewardJourney';

const INITIAL_STATE: IRewardOfferOrderState = {
  data: {
    orderRewardID: 0,
    tag: '',
    title: '',
    description: '',
    emailParticipant: '',
    rewardVoucherTypeID: 0,
    voucherCode: '',
    website: '',
    name: '',
    date: '',
    qrCode: '',
    qrCodeUrlImage: '',
    howToUse: '',
    partnerId: 0,
    rewardId: 0,
    rewardJourney : '',
    rewardID: 0,
    endDate: '',
    terms: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    securityTokenProtected: false,
    success: false,
    errorMessages: false,
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
    alertMessage: '',
    descriptionBenefit: '',
    descriptionRedeeem: '',
    evaluationDescription: '',
    mainCategoryID: '',
    ready: '',
    showVoucherLimitNotification: false,
    termsOfUseBenefit: '',
    useBenefit: '',
    defaultText: '',
  },
  loading: false,
};

const reducer: Reducer<IRewardOfferOrderState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardOfferOrderTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardOfferOrderTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardOfferOrderTypes.LOAD_RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
