import 'assets/scss/components/_toastr.scss';
import React from 'react';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import Style from './Toastr.module.scss';

const options = {
  className: Style.customBox,
  timeOut: 0,
  position: 'top-left',
  closeOnToastrClick: true,
  icon: <span className={Style.checkSuccess} />,
};

const optionsInfo = {
  className: Style.customBox,
  timeOut: 5000,
  position: 'bottom-center',
  onShowComplete: () => {
    document.querySelectorAll('button,a,i')?.forEach(item => {
      item.addEventListener('click', () => {
        toastr.removeByType('info');
      });
    });
  },
};

const optionsDefault = {
  className: Style.customBoxDefault,
  timeOut: 5000,
  position: 'bottom-center',
  closeOnToastrClick: true,
  icon: '',
};

const Toastr = props => {
  return (
    <ReduxToastr
      preventDuplicates={true}
      closeOnToastrClick={true}
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      className={Style.customToastr}
      {...props}
    />
  );
};

export const toastDefault = (message: string = '') =>
  toastr.success('', message, optionsDefault);

export const success = (message: string, title: string = '') =>
  toastr.success(title, message, options);

export const info = (message: string, title: string = '') =>
  toastr.info(title, message, optionsInfo);

export const error = (message: string, title: string = '') => toastr.error(title, message, options);
export const reset = () => {
  toastr.removeByType('success');
  toastr.removeByType('info');
  toastr.removeByType('error');
};

export default Toastr;
