import { Reducer } from 'redux';
import { IRewardCinemarkOrderState, RewardCinemarkOrderTypes } from './types';

const INITIAL_STATE: IRewardCinemarkOrderState = {
  data: {
    orderRewardID: 0,
    name: '',
    date: '',
    howToUse: '',
    tag: '',
    title: '',
    description: '',
    emailParticipant: '',
    rewardVoucherTypeID: 0,
    voucherCode: '',
    website: '',
    qrCode: '',
    qrCodeUrlImage: '',
    success: false,
    errorMessages: false,
    rewardID: 0,
    partnerID: 0,
  },
  loading: false,
};

const reducer: Reducer<IRewardCinemarkOrderState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardCinemarkOrderTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardCinemarkOrderTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        error: false,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
