import { Reducer } from 'redux';
import { categoryThematic } from '../category/types';
import { BenefitsStoreTypes, IBenefitsStoreSpecial, IBenefitsStoreState } from './types';

const INITIAL_STATE: IBenefitsStoreState = {
  data: {
    numberPage: 1,
    data: [],
    pageRows: 6,
    totalRows: 0,
    specials: [],
  },
  selected: categoryThematic.categoryID,
  tagSelected: 0,
  stateIdSelected: 0,
  currentPagination: 1,
  loading: false,
  error: false,
  success: false,
  lastPositionCard: 0,
  isHeaderFixed: true,
};

const reducer: Reducer<IBenefitsStoreState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BenefitsStoreTypes.LOAD_REQUEST:
      return { ...state, 
        data: {
          data: [],
          specials: [],
          numberPage: 1,
          pageRows: 6,
          totalRows: 0,
        },
        loading: true, 
        error: false,
        success: false,
      };
    case BenefitsStoreTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.data,
          data:
            action.payload.data.numberPage > 1
              ? [
                  ...state.data.data.filter(
                    oldItem =>
                      action.payload.data.data.filter(
                        newItem => newItem.benefitID == oldItem.benefitID,
                      ).length == 0,
                  ),
                  ...action.payload.data.data,
                ]
              : [...action.payload.data.data],
          specials: [],
        },
        error: false,
        success: true,
        loading: false,
      };
    case BenefitsStoreTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, success: false, data: { ...INITIAL_STATE.data } };
    case BenefitsStoreTypes.SET_SELECTED:
      return {
        ...state,
        data: { ...INITIAL_STATE.data },
        selected: action.payload.item,
        currentPagination: 1,
      };
    case BenefitsStoreTypes.SET_TAG_SELECTED:
      return {
        ...state,
        data: { ...INITIAL_STATE.data },
        tagSelected: action.payload.item,
        currentPagination: 1,
      };
    case BenefitsStoreTypes.SET_HEADER_FIXED:
      return {
        ...state,
        isHeaderFixed: action.payload.isHeaderFixed,
      };
    case BenefitsStoreTypes.CLEAR_DATA:
      return {
        ...state,
        data: {
          ...INITIAL_STATE.data,
        },
        currentPagination: 1,
        loading: false,
        error: false,
        success: false,
      };
    case BenefitsStoreTypes.SET_STATEID_SELECTED:
      return {
        ...state,
        data: { ...INITIAL_STATE.data },
        stateIdSelected: action.payload.stateId,
        currentPagination: 1,
      };
    case BenefitsStoreTypes.SET_PAGINATION:
      return {
        ...state,
        currentPagination: action.payload.page,
      };
    case BenefitsStoreTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    case BenefitsStoreTypes.LAST_POSITION_CARD:
      return {
        ...state,
        lastPositionCard: action.payload.lastPositionCard,
      };
    default:
      return state;
  }
};

export default reducer;
