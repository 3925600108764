export enum RewardCinemarkOrderTypes {
  LOAD_REQUEST = '@reward_cinemark_order/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_cinemark_order/LOAD_SUCCESS',
}

export interface IRewardCinemarkOrderRequest {
  RewardID: number;
  StateId: number;
  ActivationChannel: string;
}

export interface IRewardCinemarkOrderResponse {
  orderRewardID: number;
  name: string;
  date: string;
  howToUse: string;
  tag: string;
  title: string;
  description: string;
  emailParticipant: string;
  rewardVoucherTypeID: number;
  voucherCode: string;
  website: string;
  qrCode: string;
  qrCodeUrlImage: string;
  success?: boolean;
  errorMessages?: boolean;
  rewardID: number;
  partnerID: number;
}

export interface IRewardCinemarkOrderState {
  readonly data: IRewardCinemarkOrderResponse;
  readonly loading: boolean;
}
