import { Reducer } from 'redux';
import { IRewardMonthlyFeeDiscountDetailsState, RewardMonthlyFeeDiscountTypes } from './types';

const INITIAL_STATE: IRewardMonthlyFeeDiscountDetailsState = {
  data: {
    rewardID: 0,
    tag: '',
    title: '',
    description: '',
    maxPurchaseDate: '',
    terms: '',
    endPeriod: '',
    startPeriod: '',
    productSystemAtlysID: 0,
    productSystemAmdocsID: 0,
    securityTokenProtected: false,
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    success: false,
    errorMessages: false,
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
  },
  loading: true,
};

const reducer: Reducer<IRewardMonthlyFeeDiscountDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardMonthlyFeeDiscountTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardMonthlyFeeDiscountTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardMonthlyFeeDiscountTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    case RewardMonthlyFeeDiscountTypes.SELECT_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          account: action.payload.phone,
          selectedId: +action.payload.phone.accountId,
        },
      };
    case RewardMonthlyFeeDiscountTypes.SET_REWARD_MONTHLY_FEE_DISCOUNT:
      return {
        ...state,
        data: {
          ...action.payload.reward,
        },
      };
    default:
      return state;
  }
};

export default reducer;
