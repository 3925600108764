import { Reducer } from 'redux';
import { DeeplinkTypes, IDeeplinkState } from './types';

const INITIAL_STATE: IDeeplinkState = {
  data: undefined,
  deeplink: {
    benefitId: undefined,
    rewardId: undefined,
    categoryId: undefined,
    tagId: undefined,
  },
  loading: false,
};

const reducer: Reducer<IDeeplinkState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeeplinkTypes.LOAD_REQUEST_BENEFIT_TYPES:
      return {
        ...state,
        deeplink: {
          benefitId: action.payload.benefitId,
        },
        loading: false,
      };
    case DeeplinkTypes.LOAD_SUCCESS__BENEFIT_TYPES:
      return {
        ...state,
        loading: true,
        data: action.payload.data,
      };
    case DeeplinkTypes.LOAD_REQUEST_REWARD_TYPES:
      return {
        ...state,
        deeplink: {
          rewardId: action.payload.rewardId,
        },
        loading: false,
      };
    case DeeplinkTypes.LOAD_SUCCESS__REWARD_TYPES:
      return {
        ...state,
        loading: true,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
