export enum UseBenefitWithoutSeasonDetailsTypes {
  LOAD_REQUEST = '@use_benefit_without_season_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@use_benefit_without_season_details/LOAD_SUCCESS',
  LOAD_CANCEL_REQUEST = '@use_benefit_without_season_details/LOAD_CANCEL_REQUEST',
  LOAD_CANCEL_SUCCESS = '@use_benefit_without_season_details/LOAD_CANCEL_SUCCESS',
  LOAD_CANCEL_ERROR = '@use_benefit_without_season_details/LOAD_CANCEL_ERROR',
}

export interface IUseBenefitWithoutSeasonDetails {
  orderRewardID: number;
  location: string;
  title: string;
  tag: string;
  maxPurchaseDate: string;
  expirationDate: string;
  redemptionDate: string;
  qrCode: string;
  qrCodeUrlImage: string;
  howToUse: string;
  orderNumber: string;
  startDate: string;
  startHour: string;
  ticketNumber: string;
  genre: string;
  targetAudience: string;
  ageRating: string;
  address: string;
  ticketPickUpPlace: string;
  description: string;
  terms: string;
  name: string;
  date: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  enabledCTA: boolean;

  rewardID: number;
  isSponsored: boolean;
  averageNote: number;
  enableAverage: boolean;
  timesClicked: number;
  initialCount: number;
  showCount: boolean;
  ownTerms: boolean;
  validateDate: string;
  tagHome: string;
  defaultImage: string;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  defaultText: string;
  alertConfirmRedemption: string;
  aboutEvent: string;
  termsOfUseSponsorship: string;
  addressLocation: string;
}
export interface IUseBenefitWithoutSeasonCancel {
  loading: boolean;
  success: boolean;
  errorMessages: any;
}

export interface IUseBenefitWithoutSeasonDetailsState {
  readonly data: IUseBenefitWithoutSeasonDetails;
  readonly cancel: IUseBenefitWithoutSeasonCancel;
  readonly loading: boolean;
}
