import { Reducer } from 'redux';
import { BadgesInformativesTypes, IBadgesInformativesState } from './types';

const INITIAL_STATE: IBadgesInformativesState = {
  data: [],
  loading: false,
};

const reducer: Reducer<IBadgesInformativesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BadgesInformativesTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BadgesInformativesTypes.LOAD_SUCCESS:
      return { loading: false, data: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
