import { Reducer } from 'redux';
import { BannerFooterTypes, IBannerFooterState } from './types';

const INITIAL_STATE: IBannerFooterState = {
  data: {
    title: null,
    linkTitle: '',
    link: '',
    description: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload480: '',
    urlDownload690: '',
    urlDownload430: '',
  },
  loading: true,
  isBannerEmpty: true,
};

const reducer: Reducer<IBannerFooterState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BannerFooterTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BannerFooterTypes.LOAD_SUCCESS:
      return {
        loading: false,
        data: action.payload.data,
        isBannerEmpty: action.payload.data.title === null,
      };
    case BannerFooterTypes.LOAD_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
