export enum UseRewardCellphoneGadgetsItemTypes {
  LOAD_REQUEST = '@use_reward_cellphone_gadgets_item/LOAD_REQUEST',
  LOAD_SUCCESS = '@use_reward_cellphone_gadgets_item/LOAD_SUCCESS',
}

export interface IUseRewardCellphoneGadgetsItem {
  orderRewardID: number;
  rewardID: number;
  partner: string;
  title: string;
  redemptionDate: string;
  howToUse: string;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
}

export interface IUseRewardCellphoneGadgetsItemState {
  readonly data: IUseRewardCellphoneGadgetsItem;
  readonly loading: boolean;
}
