import classNames from 'classnames';
import BackButton from 'components/BackButton/BackButton';
import Logotype from 'components/Logotype/Logotype';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from 'store';
import Helpers from '_utils/helpers';
import { Platform } from '_utils/Platform';
import Style from './Header.module.scss';

const isShowBackButton = (): boolean => {
  const platform = Helpers.getCookie('platform');

  switch (platform) {
    case Platform.MOVEL_APP:
      return false;
    case Platform.MOVEL_MOBILE:
    case Platform.FIXO_MOBILE:
    case Platform.FIXO_APP:
      return true;
    default:
      return true;
  }
};

const hiddenBackButtonOnboard = (icon: string) => !(icon === 'welcome');
interface IOwnProps extends RouteComponentProps {
  icon?: string;
  isHeaderFixed?: boolean;
}

const Header: React.FC<IOwnProps> = ({ icon = '', location, isHeaderFixed }) => {
  const { pathname } = location;
  const cantShowBackButton =
    pathname === '/' ||
    pathname === '/error-oam' ||
    pathname === '/servico-indisponivel' ||
    pathname === '/resgate-indisponivel' ||
    pathname === '/inactive' ||
    pathname === '/manutencao' ||
    pathname === '/block' ||
    pathname.includes('sucesso');

  const headerFixed = isHeaderFixed && pathname.indexOf('/beneficios/loja') >= 0

  return Helpers.isPlatformVivoFixo() ? (
    <header
      className={
        `${headerFixed ? Style.headerFixed : ''} ${classNames([
          Style.container,
          { [Style.withoutBackButton]: !isShowBackButton() || cantShowBackButton },
          Style[`${icon}`],
        ])}` 
      }
    >
      {isShowBackButton() && hiddenBackButtonOnboard(icon) && !cantShowBackButton && <BackButton />}
      <Logotype
        parentClassCss={classNames([
          {
            [Style.logotype]:
              isShowBackButton() && !cantShowBackButton && hiddenBackButtonOnboard(icon),
          },
        ])}
      />
    </header>
  ) : null;
};

const mapStateToProps = (state: ApplicationState) => ({
  icon: state.header.data.icon,
  isHeaderFixed: state?.benefitsStore?.isHeaderFixed,
});

export default withRouter(connect(mapStateToProps, null)(Header));
