import { Reducer } from 'redux';
import { TagTypes, ITagState } from './types';

const INITIAL_STATE: ITagState = {
  data: [],
  loading: true,
  success: false,
  error: false,
};

const reducer: Reducer<ITagState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TagTypes.LOAD_REQUEST:
      return { ...state, 
        loading: true, 
        success: false,
        error: false,
        data: [],
      };
    case TagTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.payload.data,
      };
    case TagTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        data: [],
      };
    case TagTypes.LOAD_RESET:
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      };
    default:
      return state;
  }
};

export default reducer;
