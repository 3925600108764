const useViews = (timesClicked) => {
  
  const toFixedNoRound = (number, precision = 1) => {
    const factor = Math.pow(10, precision)
    return Math.floor(number * factor) / factor
  }

  const getViews = (views) => {
    switch (true) {
      case (views > 0 && views <= 999):
        return `${views}`;
      case (views > 999 && views <= 99999):
        return `+${toFixedNoRound(Math.abs(views)/1000)}k`;
      case (views > 99999 && views <= 999999):
        return `+${Math.trunc(Math.abs(views)/1000)}k`;
      case views > 999999 && views <= 99999999:
        return `+${toFixedNoRound(Math.abs(views)/1000000)}M`;
      case views > 99999999:
        return `+${Math.trunc(Math.abs(views)/1000000)}M`;
      default:
        return ``;
    }
  };

  const views = getViews(timesClicked);
  return views;
};

export { useViews };