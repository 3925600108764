import { action } from 'typesafe-actions';
import { DeeplinkTypes } from './types';

export const getBenefitTypes = (benefitId: number) =>
  action(DeeplinkTypes.LOAD_REQUEST_BENEFIT_TYPES, { benefitId });

export const loadSuccessBenefitTypes = (data: string) =>
  action(DeeplinkTypes.LOAD_SUCCESS__BENEFIT_TYPES, { data });

export const getRewardTypes = (rewardId: number) =>
  action(DeeplinkTypes.LOAD_REQUEST_REWARD_TYPES, { rewardId });

export const loadSuccessRewardTypes = (data: string) =>
  action(DeeplinkTypes.LOAD_SUCCESS__REWARD_TYPES, { data });
