import { IRewardJourney } from '_models/RewardJourney/IRewardJourney';

export enum UseRewardOfferDetailsTypes {
  LOAD_REQUEST = '@use_reward_offer_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@use_reward_offer_details/LOAD_SUCCESS',
}

export interface IUseRewardOfferDetails {
  rewardVoucherTypeID: number;
  voucherCode: string;
  orderRewardID: number;
  rewardID: number;
  partner: string;
  title: string;
  description: string;
  redemptionDate: string;
  expirationDate: string;
  qrCode: string;
  qrCodeUrlImage: string;
  partnerLink: string;
  howToUse: string;
  terms: string;
  name: string;
  date: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  rewardJourney?: IRewardJourney;
}

export interface IUseRewardOfferDetailsState {
  readonly data: IUseRewardOfferDetails;
  readonly loading: boolean;
}
