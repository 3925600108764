import React, { useEffect, useState } from 'react';
import Style from './InputSearch.module.scss';

interface IOwnProps {
  label: string;
  value: string;
  onChange(val: string): void;
  handleSearch(): void;
}

const InputSearch = ({ onChange, handleSearch, label, value }: IOwnProps) => {
  const handleOnClear = () => onChange('');

  const handleOnPressEnter = e => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  const [isShowPlaceholder, setisShowPlaceholder] = useState<boolean>(false);

  useEffect(() => setisShowPlaceholder(value.length === 0), [value]);

  return (
    <div className={Style.container}>
      <form
        action="."
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <input
          onKeyDown={handleOnPressEnter}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          className={Style.input}
          value={value}
          type="search"
          name="search"
        />
      </form>
      {isShowPlaceholder && (
        <label className={Style.label}>
          <span className={Style.searchIcon} />
          {label}
        </label>
      )}
      {value.length > 0 && <span className={Style.clear} onClick={handleOnClear} />}
    </div>
  );
};

export default InputSearch;
