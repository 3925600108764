import { action } from 'typesafe-actions';
import { BenefitWithoutSeasonDetailsTypes, IBenefitWithoutSeasonDetails } from './types';

export const getBenefitWithoutSeasonDetails = (id: number, isfirstCount: boolean) =>
  action(BenefitWithoutSeasonDetailsTypes.LOAD_REQUEST, { id, isfirstCount });

export const loadSuccess = (data: IBenefitWithoutSeasonDetails) =>
  action(BenefitWithoutSeasonDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(BenefitWithoutSeasonDetailsTypes.LOAD_RESET);
