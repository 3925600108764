import { connectRouter } from 'connected-react-router';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import authenticate from './authenticate';
import badges from './badges';
import badgesInformatives from './badges-informatives';
import banners from './banners';
import bannerFooter from './banner_footer';
import benefitOfferDetails from './benefits/offer';
import benefitOfferOrder from './benefits/offer_order';
import benefitWithoutSeason from './benefits/without_season';
import benefitWithoutSeasonOrder from './benefits/without_season_order';
import benefitWithSeason from './benefits/with_season';
import benefitWithSeasonOrder from './benefits/with_season_order';
import benefitsStore from './benefits_store';
import homePosition from './home_position';
import category from './category';
import tag from './tag';
import landingpage from './landingpage';
import deeplink from './deeplink';
import errorHandler from './error';
import filters from './filters';
import header from './header';
import loading from './Loading';
import ourPartners from './our_partners';
import accounts from './participant/accounts';
import activateParticipant from './participant/activate_participant';
import cancelParticipant from './participant/cancel_participant';
import mobileAccounts from './participant/mobile_accounts';
import qrCode from './qrcode';
import regions from './regions';
import rewardCellphoneAndGadgets from './rewards/cellphone_and_gadgets';
import rewardCellphoneAndGadgetsOrder from './rewards/cellphone_and_gadgets_order';
import rewardCinemark from './rewards/cinemark';
import rewardCinemarkOrder from './rewards/cinemark_order';
import rewardInternetPackageDetails from './rewards/internet_package';
import rewardInternetPackageOrder from './rewards/internet_package_order';
import rewardMonthlyFeeDiscountDetails from './rewards/monthly_fee_discount';
import rewardMonthlyFeeDiscountOrder from './rewards/monthly_fee_discount_order';
import rewardOfferDetails from './rewards/offer';
import rewardOfferOrder from './rewards/offer_order';
import rewards from './rewards/rewards';
import rewardTravel from './rewards/travel';
import rewardTravelOrder from './rewards/travel_order';
import rewardsWithoutSeasonDetails from './rewards/without_season';
import rewardsWithoutSeasonOrder from './rewards/without_season_order';
import withSeason from './rewards/with_season';
import withSeasonOrder from './rewards/with_season_order';
import rewardsUnforgettable from './rewards_unforgettable';
import rewardUnforgettableBanner from './rewards_unforgettable_banner';
import search from './search';
import sendEmailVoucher from './send_email_voucher';
import surveyInterception from './survey/interception';
import surveyList from './survey/list';
import survey from './survey/survey';
import storePassword from './token/store_password';
import validateTokenSMS from './token/validate_token_sms';
import activeRedemptions from './use/active_redemptions';
import useBenefitsOfferDetails from './use/benefits/offer';
import useBenefitsWithoutSeasonDetails from './use/benefits/without_season';
import useBenefitsWithSeasonDetails from './use/benefits/with_season';
import userHistory from './use/history';
import useRewardsCellphoneGadgetsDetails from './use/rewards/cellphone_gadgets/index';
import useRewardsCellphoneGadgetsItem from './use/rewards/cellphone_gadgets_item/index';
import useRewardsCinemarkDetails from './use/rewards/cinemark';
import useRewardsOfferDetails from './use/rewards/offer';
import useRewardsTravelDetails from './use/rewards/travel';
import useRewardsWithoutSeasonDetails from './use/rewards/without_season';
import useRewardsWithSeasonDetails from './use/rewards/with_season';
import whiteList from './white_list';
import waitingFor from './waiting_for';
import textParam from './text_param';
import evaluation from './evaluation';
import evaluationSave from './evaluation_save';

export default history =>
  combineReducers({
    router: connectRouter(history),
    accounts,
    authenticate,
    activateParticipant,
    banners,
    badges,
    badgesInformatives,
    benefitOfferDetails,
    benefitOfferOrder,
    benefitWithSeason,
    benefitWithSeasonOrder,
    benefitWithoutSeason,
    benefitWithoutSeasonOrder,
    category,
    tag,
    landingpage,
    deeplink,
    errorHandler,
    filters,
    header,
    regions,
    rewardUnforgettableBanner,
    rewards,
    rewardsUnforgettable,
    storePassword,
    sendEmailVoucher,
    validateTokenSMS,
    withSeason,
    withSeasonOrder,
    rewardsWithoutSeasonDetails,
    rewardsWithoutSeasonOrder,
    rewardCinemark,
    rewardCinemarkOrder,
    rewardOfferDetails,
    rewardOfferOrder,
    rewardMonthlyFeeDiscountDetails,
    rewardMonthlyFeeDiscountOrder,
    rewardTravel,
    rewardTravelOrder,
    rewardInternetPackageDetails,
    rewardInternetPackageOrder,
    rewardCellphoneAndGadgets,
    rewardCellphoneAndGadgetsOrder,
    cancelParticipant,
    toastr: toastrReducer,
    activeRedemptions,
    userHistory,
    benefitsStore,
    homePosition,
    useBenefitsOfferDetails,
    useRewardsOfferDetails,
    useBenefitsWithoutSeasonDetails,
    useRewardsWithoutSeasonDetails,
    useBenefitsWithSeasonDetails,
    useRewardsWithSeasonDetails,
    useRewardsTravelDetails,
    useRewardsCinemarkDetails,
    useRewardsCellphoneGadgetsDetails,
    useRewardsCellphoneGadgetsItem,
    whiteList,
    mobileAccounts,
    ourPartners,
    qrCode,
    survey,
    surveyInterception,
    surveyList,
    bannerFooter,
    search,
    loading,
    waitingFor,
    textParam,
    evaluation,
    evaluationSave
  });
