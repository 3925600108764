import { Reducer } from 'redux';
import { IRewardTravelState, RewardTravelTypes } from './types';

const INITIAL_STATE: IRewardTravelState = {
  data: {
    rewardID: 0,
    tag: '',
    title: '',
    description: '',
    dailyRateNumber: 0,
    activationDeadlineLimit: 0,
    startDate: '',
    maxPurchaseDate: '',
    endPeriod: '',
    productSystemAtlysID: 0,
    productSystemAmdocsID: 0,
    terms: '',
    securityTokenProtected: false,
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    orderRewardTravel: undefined,
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
  },
  loading: true,
};

const reducer: Reducer<IRewardTravelState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardTravelTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardTravelTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardTravelTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
