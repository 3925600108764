export enum SaveEvaluationTypes {
  LOAD_REQUEST = '@save_evaluation/LOAD_REQUEST',
  LOAD_SUCCESS = '@save_evaluation/LOAD_SUCCESS',
  LOAD_FAILURE = '@save_evaluation/LOAD_FAILURE',
}

export interface ISaveEvaluationRequest {
}

export interface ISaveEvaluationResponse {
  answerEvaluationID: number
  option: number
  orderReward: number
  participantID: string
}

export interface ISaveEvaluationAwnserState {
  readonly req: ISaveEvaluationRequest;
  readonly data: ISaveEvaluationResponse;
  readonly loading: boolean;
}
