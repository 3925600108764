import PrivateRoutesWithSubRoutes from 'components/PrivateRoutesWithSubRoutes/PrivateRoutesWithSubRoutes';
import React, { lazy } from 'react';

const Use = lazy(() => import('pages/Use/Use'));
const BenefitOffer = lazy(() => import('pages/Use/Details/Benefits/Offer/Offer'));
const BenefitWithoutSeason = lazy(() =>
  import('pages/Use/Details/Benefits/WithoutSeason/WithoutSeason'),
);
const BenefitWithSeason = lazy(() => import('pages/Use/Details/Benefits/WithSeason/WithSeason'));

const RewardOffer = lazy(() => import('pages/Use/Details/Rewards/Offer/Offer'));
const RewardWithoutSeason = lazy(() =>
  import('pages/Use/Details/Rewards/WithoutSeason/WithoutSeason'),
);
const RewardWithSeason = lazy(() => import('pages/Use/Details/Rewards/WithSeason/WithSeason'));
const RewardTravel = lazy(() => import('pages/Use/Details/Rewards/Travel/Travel'));
const Cinemark = lazy(() => import('pages/Use/Details/Rewards/Cinemark/Cinemark'));

const CellphonaAndGadgets = lazy(() =>
  import('pages/Use/Details/Rewards/CellphoneAndGadgets/CellphoneAndGadgets'),
);

const CellphoneAndGadgetsItem = lazy(() =>
  import('pages/Use/Details/Rewards/CellphoneAndGadgets/CellphoneAndGadgetsItem'),
);

const routes = [
  { path: '/use', exact: true, component: Use },
  { path: '/use/beneficios/oferta/:id', component: BenefitOffer },
  { path: '/use/beneficios/evento/:id', component: BenefitWithoutSeason, exact: true },
  { path: '/use/beneficios/evento/temporada/:id', component: BenefitWithSeason },

  { path: '/use/rewards/oferta/:id', component: RewardOffer },
  { path: '/use/rewards/evento/:id', component: RewardWithoutSeason, exact: true },
  { path: '/use/rewards/evento/temporada/:id', component: RewardWithSeason },
  { path: '/use/rewards/diaria-vivo-travel/:id', component: RewardTravel },
  { path: '/use/rewards/meia-entrada-cinemark/:id', component: Cinemark },
  { path: '/use/rewards/desconto-em-aparelhos-acessorios/:id', component: CellphonaAndGadgets },
  {
    path: '/use/rewards/desconto-em-aparelhos-acessorios-item/:id',
    component: CellphoneAndGadgetsItem,
  },
];

const UseRoutes = () => {
  return <PrivateRoutesWithSubRoutes routes={routes} />;
};

export default UseRoutes;
