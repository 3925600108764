import { action } from 'typesafe-actions';
import { UseBenefitWithoutSeasonDetailsTypes, IUseBenefitWithoutSeasonDetails, IUseBenefitWithoutSeasonCancel } from './types';

export const getUseBenefitWithoutSeasonDetails = (id: number) =>
  action(UseBenefitWithoutSeasonDetailsTypes.LOAD_REQUEST, { id });
export const cancelBenefitWithoutSeason = (id: number) =>
  action(UseBenefitWithoutSeasonDetailsTypes.LOAD_CANCEL_REQUEST, { id });
export const loadSuccess = (data: IUseBenefitWithoutSeasonDetails) =>
  action(UseBenefitWithoutSeasonDetailsTypes.LOAD_SUCCESS, { data });
export const loadCancelSuccess = (data: IUseBenefitWithoutSeasonCancel) =>
  action(UseBenefitWithoutSeasonDetailsTypes.LOAD_CANCEL_SUCCESS, { data });
export const loadCancelError = (data: IUseBenefitWithoutSeasonCancel) =>
  action(UseBenefitWithoutSeasonDetailsTypes.LOAD_CANCEL_ERROR, { data });
