import React from 'react';
import CL from 'react-content-loader';
import Style from './ContentLoader.module.scss';

const Skeleton = () => (
  <div className={Style.box}>
    <CL
      speed={1}
      style={{ width: '100%' }}
      viewBox="0 0 400 340"
      backgroundColor="#DADADA"
      foregroundColor="#e8e8e8"
    >
      <rect x="0" y="0" width="400" height="220" />
      <rect x="30" y="234" rx="2" ry="2" width="140" height="14" />
      <rect x="30" y="264" rx="2" ry="2" width="316" height="32" />
      <rect x="30" y="304" rx="2" ry="2" width="316" height="32" />
    </CL>
  </div>
);

const ContentLoader = () => {
  const quantity = new Array(3).fill(1);

  return (
    <div className={Style.container}>
      {quantity.map((sk, i) => (
        <Skeleton key={i} />
      ))}
    </div>
  );
};

export default ContentLoader;
