import classNames from 'classnames';
import Filters from 'components/Filters/Filters';
import NavbarStyle from 'components/Navbar/Navbar.module.scss';
import OverlaySlide from 'components/OverlaySlide/OverlaySlide';
import TextWithAction from 'components/TextWithAction/TextWithAction';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as BenefitsStoreActions from 'store/ducks/benefits_store/actions';
import * as FiltersActions from 'store/ducks/filters/actions';
import { IFilter, IFilterRequest } from 'store/ducks/filters/types';
import * as SearchActions from 'store/ducks/search/actions';
import { ISearchRequest, ISearchState } from 'store/ducks/search/types';
import Style from './SearchFilter.module.scss';

interface IStateProps {
  selected: number;
  stateIdSelected: number;
  loading: boolean;
  filters: IFilter;
  search: ISearchState;
  showAllButton?: boolean;
  parentClassCSS?: string;
}

interface IDispatchProps {
  setBenefitsStateIdSelected(stateId: number): void;
  clearActiveBenefits(): void;
  getFilters(): void;
  setPagination(page: number): void;
  setSearchValueAndRedirect(filter: ISearchRequest): void;
  setRegionSelected(regionId: number): void;
  handleResetFilter?: () => void;
  applyFilterCallback?: () => void;
}

type props = IStateProps & IDispatchProps;

interface IState {
  showModal: boolean;
}

const SearchFilter = ({
  setSearchValueAndRedirect,
  setRegionSelected,
  handleResetFilter,
  applyFilterCallback,
  getFilters,
  parentClassCSS,
  filters,
  search,
  showAllButton = true,
}: props) => {
  const [state, setState] = useState<IState>({
    showModal: false,
  });

  const handleModal = () => {
    setState({
      ...state,
      showModal: !state.showModal,
    });
  };

  const handleApplyFilter = () => {
    const { partnerName, stateId } = search.filter;
    if (partnerName) {
      setSearchValueAndRedirect({
        partnerName,
        stateId,
      });
    }

    if (applyFilterCallback !== undefined) {
      applyFilterCallback();
    }

    handleModal();
  };

  useEffect(() => {
    getFilters();
    window.scrollTo(0, 0);
    const menuItem = document.querySelector('a[class^="Navbar_link"]');
    if (menuItem) {
      menuItem.classList.add(NavbarStyle.active);
    }
  }, [getFilters]);

  const onResetFilter = () => {
    if (handleResetFilter !== undefined) {
      handleResetFilter();
      handleModal();
    }
  };

  const handleSetRegion = region => {
    setRegionSelected(+search.filter.stateId === +region.stateId ? 0 : +region.stateId);
  };

  return (
    <>
      <div className={classNames([Style.container, parentClassCSS])}>
        <span className={Style.counter}>
          {showAllButton && (
            <>
              {search.data.totalRows} {search.data.totalRows === 1 ? 'Resultado' : 'Resultados'}
            </>
          )}
        </span>
        <button className={Style.buttonFilter} onClick={handleModal}>
          <span className={Style.filterIcon} /> Filtrar
        </button>
      </div>
      <OverlaySlide show={state.showModal} controlState={handleModal}>
        <div className={Style.boxWrap}>
          <div className={Style.filtersWrap}>
            {showAllButton && (
              <Filters
                title={'Todos'}
                controlState={() => null}
                filters={[]}
                selecteds={[]}
                totalSelected={search.data.totalRows}
                idProp={''}
                openable={false}
                multiple={false}
              />
            )}
            <Filters
              title={'Estados'}
              idProp="stateId"
              filters={filters.regions}
              selecteds={[search.filter.stateId]}
              totalSelected={filters.regions.length}
              controlState={(regions: IFilter) => handleSetRegion(regions)}
              multiple={false}
            />
          </div>
          <div className={Style.footer}>
            <div className={Style.actions}>
              <TextWithAction
                size={20}
                label={'Aplicar Filtro'}
                fontColor={'purple'}
                fontStyle={'medium'}
                func={handleApplyFilter}
                parentClassCss={Style.apply}
              />
            </div>
          </div>
        </div>
      </OverlaySlide>
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  selected: state.benefitsStore.selected,
  stateIdSelected: state.benefitsStore.stateIdSelected,
  loading: state.benefitsStore.loading,
  filters: state.filters.data,
  search: state.search,
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setBenefitsStateIdSelected: BenefitsStoreActions.setBenefitsStateIdSelected,
      clearActiveBenefits: BenefitsStoreActions.clearActiveBenefits,
      getFilters: FiltersActions.getFilters,
      setPagination: BenefitsStoreActions.setPagination,
      ...SearchActions,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchFilter);
