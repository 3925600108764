export enum DeeplinkTypes {
  LOAD_REQUEST_BENEFIT_TYPES = '@deeplink/LOAD_REQUEST_BENEFIT_TYPES',
  LOAD_SUCCESS__BENEFIT_TYPES = '@deeplink/LOAD_SUCCESS__BENEFIT_TYPES',
  LOAD_REQUEST_REWARD_TYPES = '@deeplink/LOAD_REQUEST_REWARD_TYPES',
  LOAD_SUCCESS__REWARD_TYPES = '@deeplink/LOAD_SUCCESS__REWARD_TYPES',
}

export enum BenefitTypes {
  OFFER = 'offer',
  NO_SEASON = 'noseason',
  SEASON = 'season',
}

export enum RewardTypes {
  OFFER = 'offer',
  NO_SEASON = 'noseason',
  SEASON = 'season',
  DISCOUNT_VOUCHER = 'discountVoucher',
  INTERNET_PACKAGE = 'internetPackage', 
  MOUNTHLY_FREE_DISCOUNT = 'monthlyFreeDiscount', 
  TRAVEL = 'travel',
  CINEMARK = 'cinemark',
}

export interface IDeepLink {
  benefitId?: string | null;
  categoryId?: string | null;
  rewardId?: string | null;
  tagId?: string | null;
}

export interface IDeeplinkState {
  data: RewardTypes | BenefitTypes | undefined;
  deeplink: IDeepLink;
  loading: boolean;
}
