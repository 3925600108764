import { call, put } from 'redux-saga/effects';
import { setError } from '../error';
import API from './../../../_services/_API/Api';
import { loadSuccess } from './action';

export function* loadRewardUnforgettableBanner() {
  try {
    const response = yield call(API.get, 'v1/api/unforgettableredeem/default');
    yield put(loadSuccess(response.data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}
