import { updateNavigationBar } from '@tef-novum/webview-bridge';

const AppBackButtonBehavior = (isHomePage: boolean = false): void => {
  if (navigator.userAgent.indexOf('VivoBR/') !== -1) {
    const version = parseFloat(navigator.userAgent.split('VivoBR/')[1].split(' ')[0]);

    if (version >= 12.1) {
      if (isHomePage) {
        updateNavigationBar({
          title: 'Vivo Valoriza',
          expandedTitle: 'Vivo Valoriza',
          showBackButton: false,
          showReloadButton: false,
        });
      } else {
        updateNavigationBar({
          title: 'Vivo Valoriza',
          expandedTitle: '',
          showBackButton: true,
          showReloadButton: false,
        });
      }
    }
  }
};

export default AppBackButtonBehavior;
