import { action } from 'typesafe-actions';
import { IRewardCinemarkDetails, RewardCinemarkDetailsTypes } from './types';

export const getRewardCinemarkDetails = (id: number, isDeeplink: boolean) =>
  action(RewardCinemarkDetailsTypes.LOAD_REQUEST, { id, isDeeplink });

export const loadSuccess = (data: IRewardCinemarkDetails) =>
  action(RewardCinemarkDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardCinemarkDetailsTypes.LOAD_RESET);
