import { IAccounts } from 'store/ducks/participant/accounts/types';

export enum RewardMonthlyFeeDiscountTypes {
  LOAD_REQUEST = '@reward_monthly_fee_discount/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_monthly_fee_discount/LOAD_SUCCESS',
  LOAD_RESET = '@reward_monthly_fee_discount/LOAD_RESET',
  SELECT_ACCOUNT = '@reward_mothly_fee_discount/SELECT_ACCOUNT',
  SET_REWARD_MONTHLY_FEE_DISCOUNT = '@reward_monthly_fee_discount/SET_REWARD_MONTHLY_FEE_DISCOUNT',
}

export interface IRewardMonthlyFeeDiscount {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  maxPurchaseDate: string;
  terms: string;
  endPeriod: string;
  startPeriod: string;
  productSystemAtlysID: number;
  productSystemAmdocsID: number;
  securityTokenProtected: boolean;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  success?: boolean;
  errorMessages?: boolean;
  account?: IAccounts;
  selectedId?: number;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;
}

export interface IRewardMonthlyFeeDiscountDetailsState {
  readonly data: IRewardMonthlyFeeDiscount;
  readonly loading: boolean;
}
