export enum BannerFooterTypes {
  LOAD_REQUEST = '@banner_footer/LOAD_REQUEST',
  LOAD_SUCCESS = '@banner_footer/LOAD_SUCCESS',
  LOAD_ERROR = '@banner_footer/LOAD_ERROR',
}

export interface IBannerFooter {
  title: string | null;
  linkTitle: string;
  link: string;
  description: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  urlDownload430: string;
}

export interface IBannerFooterState {
  readonly data: IBannerFooter;
  readonly loading: boolean;
  readonly isBannerEmpty: boolean;
}
