import { Reducer } from 'redux';
import { ISurveyListState, SurveyListTypes } from './types';

const INITIAL_STATE: ISurveyListState = {
  data: [],
  isHasSurveyList: false,
  loading: true,
};

const reducer: Reducer<ISurveyListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SurveyListTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case SurveyListTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        isHasSurveyList: action.payload.data.length !== 0,
      };
    case SurveyListTypes.LOAD_ERROR:
      return {
        ...state,
        data: [],
      };
    default:
      return state;
  }
};

export default reducer;
