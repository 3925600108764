import ContentLoader from 'components/Loaders/ContentLoader/ContentLoader';
import HeaderFixed from 'components/HeaderFixed/HeaderFixed';
import SurveyRedirect from 'components/SurveyRedirect/SurveyRedirect';
import BadgesInformatives from 'containers/BadgesInformatives/BadgesInformatives';
import BannerFooter from 'containers/BannerFooter/BannerFooter';
// import BannerHighlight from 'containers/BannerHighlight/BannerHighlight';
import BannerList from 'containers/BannerList/BannerList';
import RewardList from 'containers/RewardList/RewardList';
// import RewardsUnforgettable from 'containers/RewardsUnforgettable/RewardsUnforgettable';
import RewardsUnforgettableStatic from 'containers/RewardsUnforgettableStatic/RewardsUnforgettableStatic';
import RewardsUnforgettableBanner from 'containers/RewardsUnforgettableBanner/RewardsUnforgettableBanner';
import React, { PropsWithChildren, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as BannersActions from 'store/ducks/banners/actions';
import { IBanners } from 'store/ducks/banners/types';
import * as BannerFooterActions from 'store/ducks/banner_footer/actions';
import * as HomePositionAction from 'store/ducks/home_position/actions';
import * as RewardsActions from 'store/ducks/rewards/rewards/actions';
import { IRewardHighlightcoItems, RewardSituation } from 'store/ducks/rewards/rewards/types';
import * as RewardsUnforgettableActions from 'store/ducks/rewards_unforgettable/actions';
import { IRewardUnfogettable } from 'store/ducks/rewards_unforgettable/types';
import { IRewardUnforgettableBanner } from 'store/ducks/rewards_unforgettable_banner/types';
import * as SurveyInterceptionAction from 'store/ducks/survey/interception/actions';
import Helpers from '_utils/helpers';
import ScrollPage from '_utils/ScrollPage';
import {
  InteractionEvent,
  NavigationFlowStep,
  PushTagNavigation,
  rootPath,
  SubCategory,
} from '_utils/TagManager';
import {
  ISurveyInterception,
  SurveyInterceptionPageId,
  surveyInterceptionUrl,
} from '../../store/ducks/survey/interception/types';
import { reset } from 'components/Toastr/Toastr';

interface IStateToProps {
  banners: IBanners[];
  rewards: IRewardHighlightcoItems[];
  rewardsUnforgettable: IRewardUnfogettable;
  rewardsUnforgettableBanner: IRewardUnforgettableBanner;
  surveyInterception: ISurveyInterception[];
  isRedeemedThisMonth: boolean;
  loading: boolean;
  lastClickItemHome: number;
}

interface IDispatchToProps {
  getInterceptionSurvey(pageId: number): void;
  getRewards(): void;
  getBanners(): void;
  getRewardsUnforgettable(): void;
  getBannersFooter(): void;
  setLastPositionItemHome(): void;
}

type props = IStateToProps & IDispatchToProps & PropsWithChildren<any>;

const Home: React.FC<props> = ({
  banners,
  rewards,
  rewardsUnforgettable,
  isRedeemedThisMonth,
  getInterceptionSurvey,
  history,
  loading,
  getRewards,
  getBanners,
  getRewardsUnforgettable,
  getBannersFooter,
  setLastPositionItemHome,
  lastClickItemHome,
}) => {
  useEffect(() => {
    reset();
    getRewards();
    getBanners();
    getRewardsUnforgettable();
    getBannersFooter();
  }, []);

  useEffect(() => {
    getInterceptionSurvey(SurveyInterceptionPageId.HOME);

    PushTagNavigation(
      rootPath.ESCOLHA,
      NavigationFlowStep.HOME,
      InteractionEvent.VIRTUAL_PAGE_VIEW,
      SubCategory.ESCOLHA,
    );
  }, [getInterceptionSurvey, history]);

  useEffect(() => {
    const isSendEndLoading = banners || rewards || rewardsUnforgettable;

    if (isSendEndLoading) {
      Helpers.sendEndLoading();
    }
  }, [banners, rewards, rewardsUnforgettable]);

  useEffect(() => {
    localStorage.removeItem("cxreg");
    localStorage.removeItem("cxseg");
  }, []);

  const COMPONENT_LIST: any[] = [
    {
      Component: RewardsUnforgettableStatic,
      order: rewardsUnforgettable?.title ? 1 : 0,
      name: 'RewardsUnforgettableStatic',
    },
    {
      Component: RewardList,
      order: (() => {
        if (!rewardsUnforgettable?.title && !isRedeemedThisMonth) {
          return 1;
        }

        if (rewardsUnforgettable?.title && !isRedeemedThisMonth) {
          return 2;
        }

        if (
          rewardsUnforgettable?.title &&
          rewards.filter(e => e.situation == RewardSituation.ACTIVE).length == 0 &&
          banners.length > 0
        ) {
          return 3;
        }

        if (isRedeemedThisMonth) {
          return 3;
        }

        return 2;
      })(),
      name: 'RewardList',
    },
    {
      Component: BannerList,
      order: (() => {
        if (!rewardsUnforgettable?.title && rewards.length == 0) {
          return 1;
        }

        if (!rewardsUnforgettable?.title && isRedeemedThisMonth) {
          return 2;
        }

        if (
          rewardsUnforgettable?.title &&
          rewards.filter(e => e.situation == RewardSituation.ACTIVE).length == 0
        ) {
          return 2;
        }

        return 3;
      })(),
      name: 'BannerList',
    },
    // {
    //   Component: BannerHighlight,
    //   order: (() => {
    //     if (!rewardsUnforgettable?.title && isRedeemedThisMonth) {
    //       return 0;
    //     }

    //     if (rewards.length == 0 && !rewardsUnforgettable?.title) {
    //       return 0;
    //     }

    //     return 0;
    //   })(),
    //   name: 'BannerHighlight',
    // },
    {
      Component: RewardsUnforgettableBanner,
      order: !rewardsUnforgettable?.title ? 5 : 0,
      name: 'RewardsUnforgettableBanner',
    },
  ];

  useEffect(() => {
    let hasProduct = banners || rewards || rewardsUnforgettable;

    if (!loading && hasProduct) {
      setTimeout(() => {
        let getPositionItem = ScrollPage.getPositionHome(lastClickItemHome.lastPositionItemHome)
        ScrollPage.scrollBottom(getPositionItem);
      }, 1000);
    }
  }, [loading]);

  return (
    <SurveyRedirect
      surveyInterceptionUrl={surveyInterceptionUrl}
      surveyInterceptionPageId={SurveyInterceptionPageId.HOME}
    >
      <>
        <HeaderFixed />
        <BadgesInformatives />
        {loading ? (
          <ContentLoader />
        ) : (
          COMPONENT_LIST.filter(e => e.order !== 0)
            .sort((a, b) => (a.order > b.order ? 1 : -1))
            .map(({ Component }, i) => <Component key={i} />)
        )}
        <BannerFooter /> 
      </>
    </SurveyRedirect>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  banners: state.banners.data,
  rewards: state.rewards.data.rewardHighlightcoItems,
  rewardsUnforgettable: state.rewardsUnforgettable.data,
  rewardsUnforgettableBanner: state.rewardUnforgettableBanner.data,
  isRedeemedThisMonth: state.rewards.data.alreadyRedeemedThisMonth,
  surveyInterception: state.surveyInterception.data,
  loading:
    state.rewardsUnforgettable.loading ||
    state.rewards.loading ||
    state.banners.loading ||
    state.bannerFooter.loading,
    lastClickItemHome: state.homePosition,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...SurveyInterceptionAction,
      getRewards: RewardsActions.getRewards,
      getBanners: BannersActions.getBanners,
      getRewardsUnforgettable: RewardsUnforgettableActions.getRewardsUnforgettable,
      getBannersFooter: BannerFooterActions.loadBannerFooter,
      setLastPositionItemHome: HomePositionAction.setLastPositionItemHome,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
