import ToastInformation from 'components/ToastInformation/ToastInformation';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as BadgesInformativesActions from 'store/ducks/badges-informatives/actions';
import { IBadgesInformatives } from 'store/ducks/badges-informatives/types';

interface IStateToProps {
  badgesInformatives: IBadgesInformatives[];
  loading: boolean;
}

interface IDispatchToProps {
  // getBadgesInformatives(): void;
}

type props = IStateToProps & IDispatchToProps;

const BadgesInformatives: React.FC<props> = ({
  badgesInformatives,
  // getBadgesInformatives,
  loading,
}) => {
  useEffect(() => {
    // getBadgesInformatives();
  }, []);

  const isOnlyBadge = badgesInformatives.length === 1;
  const hasMoreThanOneBadge = badgesInformatives.length > 1;
  const showNotification = () => (hasMoreThanOneBadge || isOnlyBadge) && !loading;

  return showNotification() ? (
    <ToastInformation
      badge={isOnlyBadge ? badgesInformatives[0].badgeIconUrl : ''}
      description={
        isOnlyBadge ? badgesInformatives[0].description : 'Você tem novas medalhas. Confira!'
      }
    />
  ) : null;
};

const mapsStateToProps = (state: ApplicationState) => ({
  badgesInformatives: state.badgesInformatives.data,
  loading: state.badgesInformatives.loading,
});

const mapsDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(BadgesInformativesActions, dispatch);

export default connect(mapsStateToProps, mapsDispatchToProps)(BadgesInformatives);
