import { action } from 'typesafe-actions';
import { IValidateTokenSMS, ValidateTokenSMSTypes } from './types';

export const validateToken = (token: number) =>
  action(ValidateTokenSMSTypes.LOAD_REQUEST, { token });

export const loadSuccess = (data: IValidateTokenSMS) =>
  action(ValidateTokenSMSTypes.LOAD_SUCCESS, { data });

export const loadInitial = () => action(ValidateTokenSMSTypes.LOAD_INITIAL);
