import { Reducer } from 'redux';
import { IValidateTokenSMState, ValidateTokenSMSTypes } from './types';

const INITIAL_STATE: IValidateTokenSMState = {
  data: {
    success: true,
    loading: false,
    errorMessages: [{ code: '', description: '' }],
  },
  loading: false,
  isActivateOrder: false,
};

const reducer: Reducer<IValidateTokenSMState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ValidateTokenSMSTypes.LOAD_INITIAL:
      return { ...state, data: state.data, loading: false, isActivateOrder: false };
    case ValidateTokenSMSTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ValidateTokenSMSTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        isActivateOrder: true,
      };
    default:
      return state;
  }
};

export default reducer;
