import React, { useEffect, useState } from 'react';
import Style from './ToastInformation.module.scss';

interface IOwnProps {
  description: string;
  badge: string;
}

const BadgesInformation: React.FC<IOwnProps> = ({ description, badge }) => {
  const [show, setShow] = useState(true);
  const close = () => setShow(false);

  useEffect(() => {
    return () => setShow(false);
  }, []);

  return show ? (
    <div onClick={close} className={Style.container}>
      {badge && (
        <div className={Style.badge}>
          <img src={badge} alt={description} />
        </div>
      )}
      <p className={Style.description}>{description}</p>
      <i className={Style.close} />
    </div>
  ) : null;
};

export default BadgesInformation;
