import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import BannerLarge from 'components/BannerLarge/BannerLarge';
import { 
  ComponentType,
  InteractionType, 
  pushInteractionExitUrl, 
  normalizeTitle 
} from '_utils/TagManager';

interface IStateProps {
  rewardsUnforgettable: any;
}

type props = IStateProps;

const RewardsUnforgettableStatic: React.FC<props> = ({ rewardsUnforgettable }) => {
  const pushInteraction = (title: string, link: string) => () => {
    let exitLink = ''

    if (link && link.indexOf('http') >= 0) {
      exitLink = link
    }
    pushInteractionExitUrl(
      ComponentType.HOME_INESQUECIVEIS,
      InteractionType.CLICK_CARD,
      normalizeTitle(title),
      exitLink
    );
  }


  return (
    <section>
      <BannerLarge
        title={rewardsUnforgettable.title}
        description={rewardsUnforgettable.description}
        link={rewardsUnforgettable.link}
        linkTitle={rewardsUnforgettable.linkTitle}
        linkTargetId={rewardsUnforgettable.unforgettableRedeemTypeLinkId}
        pushInteraction={pushInteraction(rewardsUnforgettable.title, rewardsUnforgettable.link)}
        urlDownload210={rewardsUnforgettable.urlDownload210}
        urlDownload384={rewardsUnforgettable.urlDownload384}
        urlDownload480={rewardsUnforgettable.urlDownload480}
        urlDownload690={rewardsUnforgettable.urlDownload690}
        urlDownload430={rewardsUnforgettable.urlDownload430}
      />
    </section>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  rewardsUnforgettable: state.rewardsUnforgettable.data,
});

const RewardsUnforgettableContainer = connect(mapStateToProps)(RewardsUnforgettableStatic);

export default RewardsUnforgettableContainer;
