import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess, loadCancelSuccess, loadCancelError } from './actions';
import { push } from 'connected-react-router';
import { success } from 'components/Toastr/Toastr';
import { IUseBenefitOfferDetailsCancel } from './types';

export function* loadUseBenefitOfferDetails(action) {
  try {
    const { data } = yield call(
      API.get,
      `v1/api/use/order/active/benefit/offer/${action.payload.id}`,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}

export function* cancelBenefitOffer(action) {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.put, `v1/api/benefit/order/offer/${id}/cancel`);
    
    if (data) {
      let cancelResponse:IUseBenefitOfferDetailsCancel = {
        loading: false,
        success: data.success,
        errorMessages: data.errorMessages
      }
      yield put(loadCancelSuccess(cancelResponse));
      yield put(push('/beneficios/cancelamento/sucesso?isEvent=false'));
    }
  } catch (error) {
    let cancelResponse:IUseBenefitOfferDetailsCancel = {
      loading: false,
      success: false,
      errorMessages: error || []
    }
    yield put(loadCancelError(cancelResponse));
    yield put(setError(error.response));
  }
}
