import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import { addLoading, removeLoading } from 'store/ducks/Loading/actions';
import Api from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadCellphoneAcessoriesOrder(action) {
  yield put(addLoading());

  try {
    const { data } = yield call(
      Api.post,
      `v1/api/reward/order/discountvoucher`,
      action.payload.data,
    );
    yield put(loadSuccess(data.data));
    yield put(push('sucesso'));
  } catch (error) {
    yield put(removeLoading());
    yield put(setError(error.response));
  }
}
