import { push } from 'connected-react-router';
import { RoutesForScreenError } from 'routes/Error/ErrorRoutes';
import store from 'store';
import { AppEnvironment } from '_utils/AppEnviroment';

export interface IErrorResponse {
  status: number;
  success: boolean;
  errorMessages: [{ 
    code: string; 
    description: string;
    internalCode: number;
    internalDescription: string;
  }];
}

const redirectRoute = (route: string, isDeeplink?: boolean) => {
  store.dispatch(push(`${route}${isDeeplink ? '?isDeeplink=true' : ''}`));
};

const Error = {
  REQUEST: {
    status: 400,
    route: (isDeeplink) => redirectRoute(RoutesForScreenError.RESGATE_INSDISPONIVEL, isDeeplink),
  },
  UNAUTHORIZED: {
    status: 401,
    route: () => redirectRoute(RoutesForScreenError.NAO_AUTORIZADO),
  },
  UNAUTHORIZED_VIVO_FIXO: {
    status: 401,
    route: () => redirectRoute(RoutesForScreenError.NAO_AUTORIZADO),
  },
  FORBIDDEN_VIVO_OAM: {
    status: 403,
    route: () => redirectRoute(RoutesForScreenError.TELA_ERRO_OAM),
  },
  BUSINESS_ERROR_REDEEM_ORDER: {
    status: 422,
    route: (isDeeplink) => redirectRoute(RoutesForScreenError.RESGATE_INSDISPONIVEL, isDeeplink),
  },
  NOUNCE_VIVO_OAM: {
    status: 423,
    route: () => redirectRoute(RoutesForScreenError.TELA_ERRO_OAM),
  },
  MANY_REQUEST: {
    status: 429,
    route: () => redirectRoute(RoutesForScreenError.TELA_BLOQUEIO),
  },
  SERVER: {
    status: 500,
    route: () => redirectRoute(RoutesForScreenError.SERVICO_INDISPONIVEL),
  },
  GENERIC: {
    route: () => redirectRoute(RoutesForScreenError.SERVICO_INDISPONIVEL),
  },
};

export default class ErrorHandler {
  static exception(
    errorResponse: IErrorResponse,
    appEnvironment: AppEnvironment = AppEnvironment.MOVEL,
    isDeeplink?: boolean | undefined
  ) {
    const { status } = errorResponse;

    if (status === Error.UNAUTHORIZED_VIVO_FIXO.status && appEnvironment === AppEnvironment.FIXO) {
      return Error.UNAUTHORIZED_VIVO_FIXO.route();
    }

    switch (status | appEnvironment) {
      case Error.REQUEST.status:
        Error.REQUEST.route(isDeeplink);
        break;
      case Error.UNAUTHORIZED.status:
        Error.UNAUTHORIZED.route();
        break;
      case Error.FORBIDDEN_VIVO_OAM.status:
        Error.FORBIDDEN_VIVO_OAM.route();
        break;
      case Error.BUSINESS_ERROR_REDEEM_ORDER.status:
        Error.BUSINESS_ERROR_REDEEM_ORDER.route(isDeeplink);
        break;
      case Error.NOUNCE_VIVO_OAM.status:
        Error.NOUNCE_VIVO_OAM.route();
        break;
      case Error.MANY_REQUEST.status:
        Error.MANY_REQUEST.route();
        break;
      case Error.SERVER.status:
        Error.SERVER.route();
        break;

      default:
        Error.GENERIC.route();
        break;
    }
  }
}
