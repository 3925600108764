import { Reducer } from 'redux';
import { IRewardsState, RewardsTypes } from './types';

const INITIAL_STATE: IRewardsState = {
  data: {
    rewardHighlightTooltip: '',
    rewardHighlightDescription: '',
    alreadyRedeemedThisMonth: false,
    hasSimpleRewardsToRedeem: false,
    rewardHighlightcoItems: [],
  },
  loading: true,
  rewardDeeplink: {
    autoPlay: false,
    rewardID: 0,
  },
};

const reducer: Reducer<IRewardsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardsTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardsTypes.LOAD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case RewardsTypes.GET_REWARD_DEEPLINK:
      return {
        ...state,
        rewardDeeplink: {
          autoPlay: false,
          rewardID: action.payload.rewardID,
        },
      };
    case RewardsTypes.RESET_REWARD_DEEPLINK:
      return {
        ...state,
        rewardDeeplink: {
          autoPlay: false,
          rewardID: 0,
        },
      };
    default:
      return state;
  }
};

export default reducer;
