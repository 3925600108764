import { action } from 'typesafe-actions';
import { ISearchRequest, SearchTypes } from './types';

export const setSearchValueAndRedirect = (filter: ISearchRequest) =>
  action(SearchTypes.SET_SEARCH_VALUE_AND_REDIRECT, { filter });

export const setSearchValue = (value: string) => action(SearchTypes.SET_SEARCH_VALUE, { value });

export const setCategorySelected = (categoryId: number) =>
  action(SearchTypes.SET_CATEGORY_ID, { categoryId });

export const setRegionSelected = (regionId: number) =>
  action(SearchTypes.SET_REGION_ID, { regionId });

export const getSearch = (req: ISearchRequest) => action(SearchTypes.LOAD_REQUEST, { req });

export const loadSuccess = (data: any[]) => action(SearchTypes.LOAD_SUCCESS, { data });

export const loadError = () => action(SearchTypes.LOAD_ERROR);

export const setLabelSearchCommon = () => action(SearchTypes.SET_LABEL_SEARCH_COMMON);

export const setLabelSearchBenefits = () => action(SearchTypes.SET_LABEL_SEARCH_BENEFITS);

export const resetSearchFilterID = () => action(SearchTypes.RESET_SEARCH_FILTER_ID);
