import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { categoryThematic } from '../category/types';
import { loadSuccess } from './actions';

export function* loadBenefitsStore(action) {
  try {
    let tagId = action.payload.tagId ? `&tagId=${action.payload.tagId}` : ''
    if (action.payload.body === undefined) {
      const { data } = yield call(
        API.get,
        `v1/api/benefits/category?categoryId=${action.payload.id}&stateId=${action.payload.stateId}${tagId}`,
      );
      yield put(loadSuccess(data));
    } else if (action.payload.body) {
      const { data } = yield call(API.post, `v1/api/benefits/category/filter`, action.payload.body);
      yield put(loadSuccess(data.data));
    }
  } catch (error) {
    yield put(setError(error.response));
  }
}
