import InputSearch from 'components/InputSearch/InputSearch';
import SearchFilter from 'containers/SearchFilter/SearchFilter';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as SearchActions from 'store/ducks/search/actions';
import { ISearchRequest, ISearchState } from 'store/ducks/search/types';
import Style from './Search.module.scss';
import { ComponentType, InteractionType, PushTagInteraction } from '_utils/TagManager';
interface IStateProps {
  search: ISearchState;
  showAllButton?: boolean;
  showFilter?: boolean;
  showNumberResult?: boolean;
  handleResetFilter?: () => void;
  applyFilterCallback?: () => void;
}

interface IDispatchProps {
  setSearchValue(value: string): void;
  setSearchValueAndRedirect(filter: ISearchRequest): void;
  setSearchLabelCommon(): void;
}

type ISearchProps = IStateProps & IDispatchProps & RouteComponentProps<any>;

const Search = ({
  search,
  setSearchValue,
  setSearchValueAndRedirect,
  handleResetFilter,
  applyFilterCallback,
  setSearchLabelCommon,
  showFilter = true,
  showAllButton = true,
  showNumberResult = false,
}: ISearchProps) => {
  const handleOnChange = (val: string) => {
    setSearchValue(val);
  };

  const pushInteraction = (title: string,) => {
    PushTagInteraction(ComponentType.PARCEIROS, InteractionType.BOX_CLICK, title,);
  } 

  const handleOnSearch = () => {
    pushInteraction(`pesquisa`)
    setSearchLabelCommon();
    if (search.filter.partnerName !== '' && search.filter.partnerName.trimStart() !== '') {
      setSearchValueAndRedirect(search.filter);
    }
  };

  return (
    <>
      <InputSearch
        label={'Procure por um parceiro'}
        onChange={handleOnChange}
        value={search.filter.partnerName}
        handleSearch={handleOnSearch}
      />
      {showFilter && (
        <SearchFilter
          showAllButton={showAllButton}
          handleResetFilter={handleResetFilter}
          applyFilterCallback={applyFilterCallback}
        />
      )}
      {showNumberResult &&
        <div className={Style.container}>
          <span className={Style.counter}>
            {search.data.totalRows} {search.data.totalRows === 1 ? 'Resultado' : 'Resultados'}
          </span>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  search: state.search,
});

const mapDispachToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...SearchActions,
      setSearchValue: SearchActions.setSearchValue,
      setSearchValueAndRedirect: SearchActions.setSearchValueAndRedirect,
      setSearchLabelCommon: SearchActions.setLabelSearchCommon,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispachToProps)(withRouter(Search));
