import Home from 'pages/Home/Home';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as AuthenticateActions from 'store/ducks/authenticate/actions';
import { IPayloadAuthenticate } from 'store/ducks/authenticate/types';
import IParticipant from '_models/participant/iparticipant';
import Helpers from '_utils/helpers';
import { StatusParticipant } from '_utils/StatusParticipant';
import AppBackButtonBehavior from '_utils/AppBackButtonBehavior';
import * as TextParamActions from 'store/ducks/text_param/actions';
import './App.scss';

interface IStateToProps {
  search: string;
  participant: IParticipant;
  loading: boolean;
  isParticipantNotLogged: boolean;
  isConfiguredAppEnvironment: boolean;
}
interface IDispatchToProps {
  getAuthentication(payload: any): void;
  validToken(): void;
  redirectDeepLink(benefitId?: number): void;
  setOauthAuthentication(payload: IPayloadAuthenticate): void;
  loadOauthAuthenticate(): void;
  getTextparam(): void;
}

type props = IDispatchToProps & IStateToProps;

const App: React.FC<props> = ({
  participant,
  search,
  loading,
  isParticipantNotLogged,
  isConfiguredAppEnvironment,
  setOauthAuthentication,
  getAuthentication,
  getTextparam,
}) => {
  const setup = () => {
    const payload = {
      oauth: Helpers.getParamsRedirectOauth(search),
      cookie: Helpers.getCookie('65djiwu289283jka8'),
    };

    if (isConfiguredAppEnvironment) {
      setOauthAuthentication(payload);
    }

    if (isParticipantNotLogged) {
      getAuthentication(payload);
    }
  };

  useEffect(() => {
    setup();
  }, []);

  useEffect(() => {
    if (!loading) {
      getTextparam();
    }
  }, [loading]);

  const isParticipantActive = participant.Situation === StatusParticipant.ACTIVE;

  useEffect(() => {
    if (!loading) {
      AppBackButtonBehavior(isParticipantActive);
    }

    return () => {
      if (!loading) {
        AppBackButtonBehavior();
      }
    };
  }, [loading]);

  return loading ? null : isParticipantActive ? <Home /> : <Redirect to="/onboard" />;
};

const mapStateToProps = (state: ApplicationState) => ({
  search: state.router.location.search,
  participant: state.authenticate.participant,
  loading: state.authenticate.loading,
  isParticipantNotLogged: !state.authenticate.participantLogged,
  isConfiguredAppEnvironment: !state.authenticate.isConfiguredAppEnvironment,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...AuthenticateActions, ...TextParamActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
