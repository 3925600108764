export enum SendEmailVoucherTypes {
  LOAD_REQUEST = '@send_email_voucher/LOAD_REQUEST',
  LOAD_SUCCESS = '@send_email_voucher/LOAD_SUCCESS',
}

export interface ISendEmailVoucher {
  Email: string;
  OrderRewardID: number;
}

export interface ISendEmailVoucherState {
  readonly body: ISendEmailVoucher;
  readonly data: any;
  readonly loading: boolean;
}
