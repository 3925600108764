import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { RoutesSearchResult } from 'routes/Search/SearchRoutes';
import store from 'store';
import { setError } from 'store/ducks/error';
import { PayloadMetaAction } from 'typesafe-actions/dist/type-helpers';
import Api from '_services/_API/Api';
import Helpers from '_utils/helpers';
import { getSearch as getSearchAction, loadError, loadSuccess, setSearchValue } from './actions';
import { ISearchActionRequest, SearchTypes } from './types';

export function* setSearchValueAndRedirect(
  action: PayloadMetaAction<SearchTypes, ISearchActionRequest, null>,
) {
  yield put(setSearchValue(action.payload.filter.partnerName));
  const isOnSearch = store.getState().router.location.pathname.includes(RoutesSearchResult.BUSCA);
  if (!isOnSearch) {
    yield put(push('/busca'));
  }

  const { partnerName, stateId, partnerID } = action.payload.filter;
  yield put(
    getSearchAction({
      partnerName: partnerName || '',
      stateId: stateId === 0 ? Helpers.getStateIdParticipant() : stateId,
      partnerID: partnerID || 0,
    }),
  );
}

export function* getSearch(action) {
  let { partnerName, stateId, partnerID } = action.payload.req;

  try {
    const { data } = yield call(
      Api.get,
      `v1/api/search/partner?partnerName=${encodeURIComponent(
          partnerName,
        )}&partnerID=${partnerID}&stateId=${stateId}`,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
    yield put(loadError());
  }
}
