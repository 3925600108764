import { Reducer } from 'redux';
import { ISurveyState, SurveyTypes } from './types';

const INITIAL_STATE: ISurveyState = {
  data: [],
  loading: true,
  isButtonContinueDisabled: true,
  isCompletedSurvey: false,
  questionType: 0,
  success: {
    name: '',
    description: '',
    link: '',
    linkTitle: '',
  },
};

const reducer: Reducer<ISurveyState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SurveyTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case SurveyTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case SurveyTypes.GET_ANSWERS_QUESTIONS:
      return {
        ...state,
        data: action.payload.data,
      };
    case SurveyTypes.SAVE_SURVEY:
      return {
        ...state,
        data: action.payload.data,
      };
    case SurveyTypes.SAVE_SURVEY_SUCCESS:
      return {
        ...state,
        success: action.payload.success,
      };
    case SurveyTypes.BUTTON_CONTINUE_SHOULD_DISABLED:
      return {
        ...state,
        isButtonContinueDisabled: action.payload.active,
      };
    case SurveyTypes.COMPLETED_SURVEY:
      return {
        ...state,
        // loading: true,
        isCompletedSurvey: state.data.length === action.payload.positionOfQuestion,
      };
    case SurveyTypes.RESET_SURVEY:
      return {
        ...state,
        data: [],
      };
    case SurveyTypes.GET_QUESTION_TYPE:
      return {
        ...state,
        questionType: action.payload.questionType,
      };
    default:
      return state;
  }
};

export default reducer;
