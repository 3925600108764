import { action } from 'typesafe-actions';
import { IPartnersList, OurPartnersTypes } from './types';

export const loadOurPartners = (stateID: number) =>
  action(OurPartnersTypes.LOAD_REQUEST, { stateID });

export const setIndexWordSelected = (indexWordSelected: string) =>
  action(OurPartnersTypes.SET_INDEX_WORD_SELECTED, { indexWordSelected });

export const loadSuccess = (data: IPartnersList) => action(OurPartnersTypes.LOAD_SUCCESS, { data });
