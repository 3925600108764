import { Reducer } from 'redux';
import { CategoryTypes, ICategoryState } from './types';

const INITIAL_STATE: ICategoryState = {
  data: {
    categories: [],
  },
  loading: true,
  categoryThematicName: 'Destaques',
  error: false,
};

const reducer: Reducer<ICategoryState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CategoryTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case CategoryTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          categories: action.payload.data.categories,
        },
      };
    case CategoryTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        data: { categories: [] },
      };
    case CategoryTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    case CategoryTypes.LOAD_CATEGORY_THEMATIC_NAME:
      return {
        ...state,
        categoryThematicName: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
