import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadBenefitWithoutSeasonDetails(action) {
  try {
    const { data } = yield call(
      Api.get,
      `v1/api/benefit/event/noseason/detail/${action.payload.id}?firstCount=${action.payload.isfirstCount}`,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}
