export enum TextparamTypes {
  LOAD_REQUEST = '@textparam/LOAD_REQUEST',
  LOAD_SUCCESS = '@textparam/LOAD_SUCCESS',
}
export interface ITexts {
  categoryMenuTitle: string;
  bannerListTitle: string;
  bannerListDescription: string;
}
export interface ITextparam {
  data: ITexts;
}
