import React from 'react';
import { Switch } from 'react-router';
import PrivateRoute from '../PrivateRoute/PrivateRoute';

interface IRoute {
  path: string;
  component: any;
  exact?: boolean;
  resolve?: any | undefined;
}

interface IConfig {
  routes: IRoute[];
}

const RouteWithSubRoutesPrivates = (configRoutes: IConfig) => {
  const routesPrivate = configRoutes.routes.map((route, i) => (
    <PrivateRoute
      exact={route.exact}
      key={i}
      path={route.path}
      component={route.component}
      resolve={route.resolve}
    />
  ));

  return <Switch>{routesPrivate}</Switch>;
};

export default RouteWithSubRoutesPrivates;
