import React, { lazy } from 'react';
import PrivateRoutesWithSubRoutes from '../../components/PrivateRoutesWithSubRoutes/PrivateRoutesWithSubRoutes';

const BenefitsStore = lazy(() => import('pages/BenefitsStore/BenefitsStore'));

const Offer = lazy(() => import('../../pages/Success/Benefits/Offer/Offer'));

const DetailsBenefitOffer = lazy(() => import('../../pages/Details/Benefits/Offer/Offer'));

const DetailsBenefitOfferRegulation = lazy(() => import('../../pages/Details/Benefits/Offer/Regulation'));
const DetailsBenefitWithSeasonRegulation = lazy(() => import('../../pages/Details/Benefits/WithSeason/Regulation'));
const DetailsBenefitWithoutSeasonRegulation = lazy(() => import('../../pages/Details/Benefits/WithoutSeason/Regulation'));

const DetailsBenefitWithSeason = lazy(() =>
    import('../../pages/Details/Benefits/WithSeason/WithSeason'),
);

const DetailsBenefitWithSeasonSelect = lazy(() =>
    import(
        '../../pages/Details/Benefits/WithSeason/WithSeasonRedemptionFlow/WithSeasonRedemptionFlow'
    ),
);

const OrderBenefitsWithSeasonSuccess = lazy(() =>
    import('../../pages/Success/Benefits/WithSeason/WithSeason'),
);

const DetailsBenefitWithoutSeason = lazy(() =>
    import('../../pages/Details/Benefits/WithoutSeason/WithoutSeason'),
);

const BenefitWithoutSeasonRedemptionFlow = lazy(() =>
    import(
        '../../pages/Details/Benefits/WithoutSeason/WithoutSeasonRedemptionFlow/WithoutSeasonRedemptionFlow'
    ),
);

const OrderBenefitsWithoutSeasonSuccess = lazy(() =>
    import('../../pages/Success/Benefits/WithoutSeason/WithoutSeason'),
);
const CancelationSuccess = lazy(() =>
    import('../../pages/CancelationSuccess/CancelationSuccess'),
);

const EvaluationBenefit = lazy(() =>
  import('../../pages/Evaluation/EvaluationBenefit/EvaluationBenefit'),
);

const EvaluationBenefitSuccess = lazy(() =>
  import('../../pages/Evaluation/EvaluationBenefitSuccess/EvaluationBenefitSuccess'),
);

const routes = [
  {
    path: '/beneficios/loja/',
    component: BenefitsStore,
  },
  {
    path: '/beneficios/loja/:id',
    component: BenefitsStore,
  },
  {
    path: '/beneficios/oferta/:id/sucesso',
    component: Offer,
  },
  {
    path: '/beneficios/oferta/:id/regulamento',
    component: DetailsBenefitOfferRegulation,
  },
  {
    path: '/beneficios/oferta/:id',
    component: DetailsBenefitOffer,
    exact: true,
  },
  {
    path: '/beneficios/evento/temporada/:id',
    component: DetailsBenefitWithSeason,
    exact: true,
  },
  {
    path: '/beneficios/evento/temporada/:id/regulamento',
    component: DetailsBenefitWithSeasonRegulation,
  },
  {
    path: '/beneficios/evento/:id/regulamento',
    component: DetailsBenefitWithoutSeasonRegulation,
    exact: true,
  },
  {
    path: '/beneficios/evento/temporada/:id/selecionar',
    component: DetailsBenefitWithSeasonSelect,
  },
  {
    path: '/beneficios/evento/temporada/:id/sucesso',
    component: OrderBenefitsWithSeasonSuccess,
  },
  {
    path: '/beneficios/evento/:id',
    component: DetailsBenefitWithoutSeason,
    exact: true,
  },
  {
    path: '/beneficios/evento/:id/selecionar',
    component: BenefitWithoutSeasonRedemptionFlow,
    exact: true,
  },
  {
    path: '/beneficios/evento/:id/sucesso',
    component: OrderBenefitsWithoutSeasonSuccess,
    exact: true,
  },
  {
    path: '/beneficios/cancelamento/sucesso',
    component: CancelationSuccess,
  },
  {
    path: '/beneficios/evaluation/:id',
    component: EvaluationBenefit,
    exact: true,
  },
  {
    path: '/beneficios/evaluation/:id/sucesso/:evaluationId',
    component: EvaluationBenefitSuccess,
  },
];

const BenefitsRoutes = () => {
    return <PrivateRoutesWithSubRoutes routes={routes} />;
};

export default BenefitsRoutes;