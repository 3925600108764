import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess, loadCategoryThematicName } from './actions';
import { initialCategories } from './types';

export function* loadCategory(action) {
  try {
    const { data } = yield call(API.get, `v1/api/category?stateID=${action.payload.stateID}`);

    let selectedCategoryObject;
    let categories;

    selectedCategoryObject = initialCategories.filter(
      e => e.categoryID === action.payload.selectedCategory,
    );

    // coloca a categoria selecionada na última posição
    categories = data.data.categories.concat(selectedCategoryObject);

    data.data.categories = categories;

    yield put(loadSuccess(data.data));
  } catch (error) {
    console.log(error);
    yield put(setError(error.response));
  }
}

export function* loadResetCache(action) {
  try {
    const { data } = yield call(API.get, `v1/api/category?stateID=0`);
  } catch (error) {
    console.log(error);
    yield put(setError(error.response));
  }
}
