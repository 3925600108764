import { Reducer } from 'redux';
import { UseRewardTravelDetailsTypes, IUseRewardTravelDetailsState } from './types';

const INITIAL_STATE: IUseRewardTravelDetailsState = {
  data: {
    orderRewardID: 0,
    rewardID: 0,
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    startDate: '',
    endDate: '',
    tag: '',
    title: '',
    terms: '',
    description: '',
    expired: false,
  },
  loading: false,
};

const reducer: Reducer<IUseRewardTravelDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UseRewardTravelDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UseRewardTravelDetailsTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
