import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { ApplicationState } from 'store';
import { SurveyInterceptionPageId } from 'store/ducks/survey/interception/types';

interface IStateProps {
  surveyInterceptionUrl: string;
  surveyInterceptionPageId: number;
  showedSurveys?: number[];
  router?: any;
  surveys?: any[];
  children: any;
  to?: string;
}

const SurveyRedirect = ({
  surveyInterceptionUrl,
  surveyInterceptionPageId,
  children,
  showedSurveys,
  surveys,
  to,
}: IStateProps) => {
  const lastLocation = useLastLocation();

  const shouldRedirectToSurvey = () => {
    const isSurveyBenefits =
      surveyInterceptionPageId !== SurveyInterceptionPageId.HOME &&
      surveyInterceptionPageId !== SurveyInterceptionPageId.MY_VALORIZA;

    if (surveys && surveys.length > 0) {
      if (lastLocation && lastLocation.pathname.includes('pesquisa')) {
        return false;
      }

      if (isSurveyBenefits) {
        return true;
      }

      const notVieweredSurveys = surveys.filter(
        item => showedSurveys?.indexOf(item.campaignID) === -1,
      );

      if (notVieweredSurveys.length === 0) {
        return false;
      }

      return true;
    }

    return false;
  };

  return shouldRedirectToSurvey() ? (
    <Redirect to={to ? to : `${surveyInterceptionUrl}/${surveyInterceptionPageId}`} />
  ) : (
    children
  );
};

const mapState = (state: ApplicationState) => ({
  router: state.router,
  showedSurveys: state.surveyInterception.showedSurveys,
  surveys: state.surveyInterception.data,
});

export default connect(mapState, null)(SurveyRedirect);
