import { Reducer } from 'redux';
import { ISurveyInterceptionState, SurveyInterceptionTypes } from './types';

const INITIAL_STATE: ISurveyInterceptionState = {
  data: [],
  showedSurveys: [],
  loading: true,
  isViewSurveyPageHome: true,
  isViewSurveyPageMyValoriza: true,
  rewardID: 0,
  isNotInterceptionOfReward: true,
  routeOfnavigation: '',
  actionOfReward: null,
  loadingToReward: true,
  campaignID: 0,
  pageId: 0,
};

const reducer: Reducer<ISurveyInterceptionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SurveyInterceptionTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loadingToReward: true,
        rewardID: action.payload.rewardID,
        pageId: action.payload.pageId,
        isNotInterceptionOfReward: action.payload.rewardID ? false : true,
      };
    case SurveyInterceptionTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        campaignID: action.payload.data ? action.payload.data.campaignId : 0,
        loading: false,
        loadingToReward: false,
      };
    case SurveyInterceptionTypes.GET_ROUTE_OF_NAVIGATION:
      return {
        ...state,
        routeOfnavigation: action.payload.routeOfNavigation,
      };
    case SurveyInterceptionTypes.GET_ACTION_OF_REWARD:
      return {
        ...state,
        actionOfReward: action.payload.actionOfReward,
      };
    case SurveyInterceptionTypes.RESET_INTERCEPTION_SURVEY:
      return {
        ...state,
        data: [],
      };
    case SurveyInterceptionTypes.SET_SHOWED_SURVEY:
      const id = action.payload.id;
      if (id !== undefined && id > 0) {
        return {
          ...state,
          showedSurveys: [...state.showedSurveys, id],
        };
      }
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
