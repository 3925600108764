import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadError, loadSuccess } from './actions';

export function* getSurveyList() {
  try {
    const { data } = yield call(Api.get, `v1/api/campaign/intercept/show/profile`);
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
    yield put(loadError());
  }
}
