import BannerLarge from 'components/BannerLarge/BannerLarge';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as RewardsUnforgettableBannerAction from 'store/ducks/rewards_unforgettable_banner/action';
import { IRewardUnforgettableBanner } from 'store/ducks/rewards_unforgettable_banner/types';
import * as HomePositionAction from 'store/ducks/home_position/actions';
import {
  ComponentType,
  InteractionEvent,
  InteractionType,
  pushInteractionExitUrl,
  normalizeTitle 
} from '_utils/TagManager';

interface IStateProps {
  rewardsUnforgettableBanner: IRewardUnforgettableBanner;
}

interface IDispatchProps {
  loadRequest(): void;
  setLastPositionItemHome(lastPositionItemHome: string): void;
}

type props = IStateProps & IDispatchProps;

const RewardsUnforgettableBanner: React.FC<props> = ({
  rewardsUnforgettableBanner,
  loadRequest,
  setLastPositionItemHome, 
}) => {
  const {
    title,
    description,
    link,
    linkTitle,
    unforgettableRedeemTypeLinkId,
    urlDownload210,
    urlDownload384,
    urlDownload480,
    urlDownload690,
    urlDownload430,
  } = rewardsUnforgettableBanner;

  useEffect(() => {
    loadRequest();
  }, []);

  const pushInteraction = (title: string, link: string) => () => {
    let exitLink = ''

    if (link && link.indexOf('http') >= 0) {
      exitLink = link
    }
    pushInteractionExitUrl(
      ComponentType.HOME_INESQUECIVEIS_DEFAULT,
      InteractionType.CLICK_CARD,
      `link:${normalizeTitle(title)}`,
      exitLink
    );

    setLastPositionItemHome('unforgettable-banner')
  };

  return !title ? null : (
    <BannerLarge
      title={title}
      description={description}
      link={link}
      linkTitle={linkTitle}
      linkTargetId={unforgettableRedeemTypeLinkId}
      pushInteraction={pushInteraction(title, link)}
      urlDownload210={urlDownload210}
      urlDownload384={urlDownload384}
      urlDownload480={urlDownload480}
      urlDownload690={urlDownload690}
      urlDownload430={urlDownload430}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  rewardsUnforgettableBanner: state.rewardUnforgettableBanner.data,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      ...RewardsUnforgettableBannerAction,
      setLastPositionItemHome: HomePositionAction.setLastPositionItemHome,
    },
     dispatch,
  );

const RewardsUnforgettableBannerContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(RewardsUnforgettableBanner);

export default RewardsUnforgettableBannerContainer;
