import { action } from 'typesafe-actions';
import {
  IRewardCinemarkOrderRequest,
  IRewardCinemarkOrderResponse,
  RewardCinemarkOrderTypes,
} from './types';

export const saveOrderCinemark = (data: IRewardCinemarkOrderRequest) =>
  action(RewardCinemarkOrderTypes.LOAD_REQUEST, { data });

export const loadSuccess = (data: IRewardCinemarkOrderResponse) =>
  action(RewardCinemarkOrderTypes.LOAD_SUCCESS, { data });
