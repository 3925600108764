import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Breakpoint from '_utils/breakpoints';
import Style from './TextWithAction.module.scss';

interface IOwnProps extends RouteComponentProps {
  label: string;
  fontStyle: 'light' | 'medium' | 'regular';
  fontColor: 'purple' | 'orange' | 'white';
  route?: string;
  size?: number;
  func?: any;
  parentClassCss?: string;
  id?: string;
}

const selectFont = (fontStyle: string): string => {
  switch (fontStyle) {
    case 'light':
      return Style.actionLight;
    case 'medium':
      return Style.actionMedium;
    case 'regular':
      return Style.actionRegular;
    default:
      return Style.actionRegular;
  }
};

const selectColor = (fontColor: string): string => {
  switch (fontColor) {
    case 'purple':
      return Style.actionPurple;
    case 'orange':
      return Style.actionOrange;
    case 'white':
      return Style.actionWhite;
    default:
      return Style.actionPurple;
  }
};

const getSizeFont = (size: number, mediaQuerie: number, setFontSize: any) => {
  let clientWidth = document.documentElement.clientWidth;

  const getNumber = (sizeFont: number, clientWidthScreen: number, mediaQueries: number) =>
    setFontSize(sizeFont + 8 * ((clientWidthScreen - mediaQueries) / 160));

  window.addEventListener('resize', () => {
    clientWidth = document.documentElement.clientWidth;
    if (clientWidth < Breakpoint.screenSm) {
      getNumber(size, clientWidth, mediaQuerie);
    } else {
      setFontSize(size + 8);
    }
  });

  setFontSize(clientWidth < 480 ? size + 8 * ((clientWidth - mediaQuerie) / 160) : size + 8);
};

const TextWithAction = ({
  label,
  fontColor,
  size = 16,
  fontStyle,
  route,
  func,
  parentClassCss = '',
  id,
}: IOwnProps) => {
  const [fontSize, setFontSize] = useState(size);

  useEffect(() => {
    getSizeFont(fontSize, 375, setFontSize);
  }, []);

  const styleCss = {
    fontSize: `${fontSize}px `,
  };

  const familyFont = selectFont(fontStyle);
  const colorFont = selectColor(fontColor);

  return route ? (
    <Link
      id={id}
      onClick={func}
      className={classNames([Style.action, familyFont, colorFont, parentClassCss])}
      style={styleCss}
      to={route}
    >
      {label}
    </Link>
  ) : (
    <span
      id={id}
      onClick={func}
      className={classNames([Style.action, familyFont, colorFont, parentClassCss])}
      style={styleCss}
    >
      {label}
    </span>
  );
};

export default withRouter(TextWithAction);
