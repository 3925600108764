import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import Loader from '../Loader/Loader';

enum Type {
  request = 'request',
  response = 'response',
}

interface IStateToProps {
  loading: boolean;
}

const Loading: React.FC<IStateToProps> = ({ loading }) => {
  return loading ? <Loader /> : null;
};

const mapStateToProps = (state: ApplicationState) => ({
  pathname: state.router.location.pathname,
  loading: state.loading.loading,
});

export default connect(mapStateToProps)(Loading);
