import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import store from 'store';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import Helpers from '_utils/helpers';
import { PushTagNavigationBenefitOfferError, PushTagNonInteractionError } from '_utils/TagManager';
import { loadSuccess } from './actions';

export function* orderBenefitOffer(action) {

  try {
    const { data } = yield call(API.post, 'v1/api/benefit/order/offer', action.payload.data);
    Helpers.saveSuccessRewardBenefits(data.data);
    yield put(loadSuccess(data.data));
    yield put(push(`${data.data.orderRewardID}/sucesso`));
  } catch (error) {
    const isExistDescription = error.response.data.errorMessages.length > 0;
    const { title, category } = store.getState().benefitOfferDetails.data;
    PushTagNavigationBenefitOfferError(title, category);
    if (isExistDescription) {
      PushTagNonInteractionError(error.response.data.errorMessages[0].description);
    }

    yield put(setError(error.response));
  }
}