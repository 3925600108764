import { push } from 'connected-react-router';
import { Reducer } from 'redux';
import { call, put } from 'redux-saga/effects';
import { resetAuthenticate } from 'store/ducks/authenticate/actions';
import { setError } from 'store/ducks/error';
import { action } from 'typesafe-actions';
import Api from '../../../../_services/_API/Api';
import Session from '_models/Session/Session';

export enum ActivateParticipantTypes {
  LOAD_REQUEST = '@activate_participant/LOAD_REQUEST',
  LOAD_SUCCESS = '@activate_participant/LOAD_SUCCESS',
}

export interface IActivateParticipant {
  success: boolean;
  errorMessages: [];
}

export interface IActivateParticipantState {
  readonly data: IActivateParticipant;
}

export const CreatorsActivateParticipant = {
  getActivateParticipant: () => action(ActivateParticipantTypes.LOAD_REQUEST),
};

export function* loadActiveParticipant() {
  try {
    const { success } = (yield call(Api.get, `v1/api/participant/activate`)).data;

    // if (success) {
    //   Session.clearSession();
    //   yield put(resetAuthenticate());
    // }

    yield put(push('/'));
  } catch (error) {
    yield put(setError(error.response));
  }
}

const INITIAL_STATE: IActivateParticipantState = {
  data: {
    success: false,
    errorMessages: [],
  },
};

const reducer: Reducer<IActivateParticipantState> = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case ActivateParticipantTypes.LOAD_REQUEST:
      return { ...state };
    case ActivateParticipantTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: actions.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
