import { Reducer } from 'redux';
import { action } from 'typesafe-actions';

export const HeaderTypes = {
  SET_COLOR_HEADER: 'header/SET_COLOR_HEADER',
};

export interface IHeader {
  icon: string;
}

export interface HeaderState {
  data: IHeader;
}

export const CreatorsHeader = {
  getIconOnboard: data => action(HeaderTypes.SET_COLOR_HEADER, { data }),
};

const INITIAL_STATE: HeaderState = {
  data: {
    icon: 'default',
  },
};

const reducer: Reducer<HeaderState> = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case HeaderTypes.SET_COLOR_HEADER:
      return { data: actions.payload.data };
    default:
      return state;
  }
};

export default reducer;
