import classNames from 'classnames';
import React, { useEffect } from 'react';
import Style from './OverlaySlide.module.scss';

interface IOwnProps {
  show: any;
  children: any;
  isChangeMobileLayout?: boolean;
  controlState?(value: any): any;
  backStep?(): any;
}

const OverlaySlide: React.FC<IOwnProps> = ({
  show,
  children,
  isChangeMobileLayout = false,
  controlState = () => {
    return;
  },
}) => {
  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [show]);

  return (
    show && (
      <section className={Style.overlay}>
        <div
          onClick={() => controlState(false)}
          className={classNames([{ [`${Style.actionClose}`]: show }])}
        />
        <div className={classNames([Style.box, { [`${Style.mobile}`]: isChangeMobileLayout }])}>
          {children}
        </div>
      </section>
    )
  );
};

export default OverlaySlide;
