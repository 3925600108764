import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadRewardsMonthlyFeeDiscountDetails(action) {
  try {
    const { data } = yield call(
      Api.get,
      `v1/api/reward/monthlyfeediscount/detail/${action.payload.id}?deeplink=${action.payload.isDeeplink}`,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response, action.payload.isDeeplink));
  }
}
