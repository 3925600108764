import React from 'react';
import Style from './Spinner.module.scss';
import Rolling from 'assets/images/rolling.svg';

const Spinner = () => {
  return (
    <div className={Style.container}>
      <img src={Rolling} alt="spinner" />
    </div>
  );
};

export default Spinner;
