import CPF from 'cpf';
import Api from '_services/_API/Api';
import Participant from '../participant/participant';
import ISession from './ISession';
class Session {
  setSession({ token, cpf }) {
    const participant = new Participant(token);
    const { ParticipantId, State, StateId } = participant;

    localStorage.setItem(
      btoa(cpf),
      btoa(
        JSON.stringify({
          idParticipant: ParticipantId,
          state: State,
          stateId: StateId,
          dateSession: Date.now(),
        }),
      ),
    );
  }

  getSession(cpf: string | undefined): ISession | undefined {
    if (cpf && CPF.isValid(cpf)) {
      const storage = localStorage.getItem(btoa(cpf));

      if (storage) {
        const session = JSON.parse(atob(storage));
        return {
          idParticipant: session.idParticipant,
          state: session.state,
          stateId: session.stateId,
          dateSession: new Date(),
        };
      }
    }

    return undefined;
  }

  // clearSessionOfTheCPF(cpf: string | undefined) {
  //   if (cpf) {
  //     localStorage.removeItem(`${btoa(cpf)}`);
  //   }
  // }

  // async callEndpointFlush(): Promise<{ data: boolean }> {
  //   const data = await Api.get(`${process.env.REACT_APP_URL_APP_API}/v1/api/flush/app`);
  //   return data;
  // }

  // async clearLocalstorage(value: boolean) {
  //   if (value) {
  //     localStorage.clear();
  //   }
  // }

  // async clearSession() {
  //   const data = await this.callEndpointFlush();
  //   this.clearLocalstorage(data.data);
  // }
}

export default new Session();
