import Axios from 'axios';
import { push } from 'connected-react-router';
import { call, put, select } from 'redux-saga/effects';
import Session from '_models/Session/Session';
import Authenticate from '_models/authenticate/authenticate';
import { loadSuccess, loadError } from './actions';

const authenticate = new Authenticate();

export function* loadWhiteList() {
  if (process.env.REACT_APP_ACTIVATE_WHITE_LIST !== 'true') {
    return;
  }

  try {
    const { data } = yield call(
      Axios.get,
      `${process.env.REACT_APP_URL_APP_API}/v1/api/whitelist`,
      {
        headers: {
          'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_APP_KEY}`,
        },
      },
    );

    yield checkWhiteList(data.data);
  } catch (error) {
    yield put(push('/manutencao'));
    yield put(loadError());
  }
}

function* checkWhiteList(cpfs) {
  const cpf: string = authenticate.getTokenDecode().Identity || '';

  const isCPFValid = cpfs.includes(cpf);

  yield put(loadSuccess({ data: cpfs, isCPFValid }));

  if (!isCPFValid) {
    return yield put(push('/manutencao'));
  }
}
