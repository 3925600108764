import { Reducer } from 'redux';
import Participant from '_models/participant/participant';
import { AppEnvironment } from '_utils/AppEnviroment';
import { AuthenticateTypes, IAuthenticateState } from './types';

const INITIAL_STATE: IAuthenticateState = {
  data: {
    accessToken: '',
    tokenType: '',
    tokenCreate: null,
    expiresIn: null,
    data: '',
  },
  isConfiguredAppEnvironment: false,
  environment: {
    appEnvironment: 0,
    isOauthAuthentication: false,
    cookie: '',
  },
  participant: {},
  participantLogged: false,
  deepLink: {
    benefitId: null,
    categoryId: null,
  },
  loading: true,
};

const reducer: Reducer<IAuthenticateState> = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case AuthenticateTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case AuthenticateTypes.LOAD_OAUTH_AUTHENTICATION:
      return {
        ...state,
        isConfiguredAppEnvironment: true,
        environment: {
          appEnvironment: actions.payload.oauth,
          isOauthAuthentication: actions.payload.oauth === AppEnvironment.FIXO,
          cookie: actions.payload.cookie,
        },
      };
    case AuthenticateTypes.LOAD_VALID_TOKEN:
      return { ...state, participant: new Participant(), loading: false };
    case AuthenticateTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        participant: new Participant(),
      };
    case AuthenticateTypes.PARTICIPANT_LOGGED:
      return {
        ...state,
        participantLogged: true,
      };
    case AuthenticateTypes.PARTICIPANT_LOGOUT:
      return {
        ...state,
        participantLogged: false,
      };
    case AuthenticateTypes.RESET_AUTHENTICATE:
      return {
        ...state,
        data: INITIAL_STATE.data,
        loading: true,
        participant: {},
        participantLogged: false,
      };
    default:
      return state;
  }
};

export default reducer;
