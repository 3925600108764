import Banner from 'components/Banner/Banner';
import TextWithAction from 'components/TextWithAction/TextWithAction';
import CategoryMenu from 'containers/CategoryMenu/CategoryMenu';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import { IBanners } from 'store/ducks/banners/types';
import * as BenefitsStoreActions from 'store/ducks/benefits_store/actions';
import { categoryThematic } from 'store/ducks/category/types';
import Style from './BannerList.module.scss';
import * as HomePositionAction from 'store/ducks/home_position/actions';
import TitleSectionHome from '../../components/TitleSectionHome/TitleSection'
import { 
  ComponentType, 
  InteractionType, 
  PushTagInteraction, 
  PushViewItemList, 
  PushClickItemList,
  normalizeTitle,
} from '_utils/TagManager';

interface IStateProps {
  banners: IBanners[];
  bannerListTitle: string;
  bannerListDescription: string;
}

interface IDispatchProps {
  setSelected(category: number): void;
  setTagSelected(tag: number): void;
  setLastPositionItemHome(lastPositionItemHome: string): void;
}

type props = IStateProps & IDispatchProps & RouteComponentProps;

const BannerList: React.FC<props> = ({
  banners,
  setSelected,
  setTagSelected,
  history,
  bannerListTitle,
  bannerListDescription,
  setLastPositionItemHome, 
}) => {
  const linkForPageSeeAll = (): void => {
    setSelected(categoryThematic.categoryID);
    setTagSelected(0);
    history.push(`/beneficios/loja/${categoryThematic.categoryID}`);
  };

  useEffect(() => {
    if(banners.length > 0) {
      const itemList: any  = []

      banners.map((item, index) => {
        let partnerId  = item.partnerID > 0 ? item.partnerID : item.locationID // idDoParceiro

        let objItem: any  = {
          'item_name': normalizeTitle(item.benefitName), // nome-do-produto
          'item_id': item.benefitID, // id-do-produto
          'price': '0', // preco-do-produto
          'item_brand': `vivo-valoriza&${normalizeTitle(item.partnerName)}&${partnerId}`, // marca-do-produto
          'item_category': item.mainCategoryID, // categoria-do-produto
          'item_variant': '', //variacao-do-produto
          'item_list_name': 'carrossel&banner-destaque', // nome-da-lista
          'index': index + 1, // posição-do-produto
          'discount': '' //desconto-do-produto
        }
    
        itemList.push(objItem)
      })

      PushViewItemList(itemList, banners.length, 'carrossel&banner-destaque')
    }
  }, [banners]);

  const pushInteraction = (
    name: string, // nomeDoProduto
    id: number,// idDoProduto
    nomeDoParceiro: string,// nomeDoParceiro
    idDoParceiro: number, // idDoParceiro
    category: string, // nomeDaCategoria
    position: number, // posição
    anotherPage: boolean,
    link: string
  ) => {
    let exitUrl = ''
    if(anotherPage && link) {
      exitUrl = atob(link)
    }

    PushTagInteraction(
      ComponentType.HOME_BENEFICIOS, 
      InteractionType.CLICK_CARD, 
      `${position}:${normalizeTitle(name)}`, 
      'interaction', 
      '', 
      0, 
      `${idDoParceiro}:${normalizeTitle(nomeDoParceiro)}`, 
      `${id}:${normalizeTitle(name)}`,
      exitUrl
    );

    PushClickItemList(
      {'list':'carrossel&banner-destaque'},
      'productClick',
      normalizeTitle(name),
      id,
      0,
      normalizeTitle(nomeDoParceiro),
      idDoParceiro,
      category,
      position
    );

    setLastPositionItemHome('banners-list')
  }

  return (
    <section id="section-benefit" className={Style.container}>
      {banners.length > 0 &&
        <section id="banners-list">
          <div className={Style.list}>
            <TitleSectionHome title={bannerListTitle} />
            {banners.map((banner, index) => (
              <Banner
                pushInteraction={() => {
                  pushInteraction (
                    banner.benefitName, // nomeDoProduto
                    banner.benefitID, // idDoProduto
                    banner.partnerName, // nomeDoParceiro
                    banner.partnerID > 0 ? banner.partnerID : banner.locationID, // idDoParceiro
                    banner.mainCategoryID, // nomeDaCategoria
                    index + 1, // posição
                    banner.anotherPage,
                    banner.link
                  )
                }}
                key={`${banner.bannerID}`}
                banner={banner}
              />
            ))}
          </div>
        </section>
      }
      <CategoryMenu />
    </section>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  banners: state.banners.data,
  bannerListTitle: state.textParam.data.bannerListTitle,
  bannerListDescription: state.textParam.data.bannerListDescription,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ 
    ...BenefitsStoreActions,
    setLastPositionItemHome: HomePositionAction.setLastPositionItemHome,
  }, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerList));
