import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import store from 'store';
import { setError } from 'store/ducks/error';
import { addLoading, removeLoading } from 'store/ducks/Loading/actions';
import API from '_services/_API/Api';
import Helpers from '_utils/helpers';
import {
  PushTagNavigationRewardError,
  PushTagNonInteractionError,
  RewardCategory,
} from '_utils/TagManager';
import { loadSuccess } from './actions';

export function* orderReward(action) {


  try {
    const { data } = yield call(API.post, 'v1/api/reward/order/event/season', action.payload.data);
    Helpers.saveSuccessRewardBenefits(data.data);
    yield put(loadSuccess(data.data));
    yield put(push(`/rewards/evento/temporada/${data.data.orderRewardID}/sucesso`));
  } catch (error) {
    const isExistDescription = error.response.data.errorMessages.length > 0;

    PushTagNavigationRewardError(store.getState().withSeason.data.title, RewardCategory.EVENTO);
    if (isExistDescription) {
      PushTagNonInteractionError(error.response.data.errorMessages[0].description);
    }

    yield put(setError(error.response));
  }
}
