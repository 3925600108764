import React from 'react';
import { Redirect, Route } from 'react-router';
import Helpers from '_utils/helpers';
import Authenticate from './../../_models/authenticate/authenticate';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = new Authenticate();

  React.useEffect(() => {
    if (rest.resolve) {
      rest.resolve();
    }

    return () => Helpers.setAppBodyColor('');
  }, []);

  return (
    <Route
      {...rest}
      render={props =>
        auth.isTokenExpired() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/unauthorized', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
