import { action } from 'typesafe-actions';
import { BenefitWithSeasonDetailsTypes, IBenefitWithSeasonDetails } from './types';

export const getDetailsBenefitWithSeason = (id: number, isfirstCount: boolean) =>
  action(BenefitWithSeasonDetailsTypes.LOAD_REQUEST, { id, isfirstCount });

export const loadSuccess = (data: IBenefitWithSeasonDetails) =>
  action(BenefitWithSeasonDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(BenefitWithSeasonDetailsTypes.LOAD_RESET);
