import { action } from 'typesafe-actions';
import {
  IRewardMonthlyFeeDiscountOrderRequest,
  IRewardMonthlyFeeDiscountOrderResponse,
  RewardMonthlyFeeDiscountOrderTypes,
} from './types';

export const saveRewardMonthlyFeeDiscount = (data: IRewardMonthlyFeeDiscountOrderRequest) =>
  action(RewardMonthlyFeeDiscountOrderTypes.LOAD_REQUEST, { data });

export const loadSuccess = (data: IRewardMonthlyFeeDiscountOrderResponse) =>
  action(RewardMonthlyFeeDiscountOrderTypes.LOAD_SUCCESS, { data });
