export enum HistoryTypes {
  LOAD_REQUEST = '@history/LOAD_REQUEST',
  LOAD_SUCCESS = '@history/LOAD_SUCCESS',
}

export interface IHistory {
  rewardID: number;
  tag: string;
  title: string;
  name: string;
  endDate: string;
  startDate: string;
  presenceStatus: string;
}

export interface IHistoryState {
  data: IHistory[];
  loading: boolean;
  error: boolean;
}
