import classNames from 'classnames';
import React, { ReactNode } from 'react';
import PostMessageIframe from '_utils/PostMessageIframe';
import { pushInteractionLinkPartner } from '_utils/TagManager';
import Helpers from '../../_utils/helpers';

// const createUrlManagerUrlRedirect = (link): string => {
//   const urlCurrent = window.location.origin;
//   return `${urlCurrent}/UrlManagerRedirect?externalUrl=${Helpers.formatUrlManagerRedirect(link)}`;
// };

// const changePage = (link: string | undefined, pageTarget: number = 0) => {
//   const url = `${createUrlManagerUrlRedirect(link)}&open_internal=${pageTarget}`;
//   PostMessageIframe.sendPostMessage(url);
// };

interface IOwnProps {
  openExternal?: boolean;
  classCss?: string[];
  children?: ReactNode;
  link?: string;
  title?: string;
}

const LinkToPartner: React.FC<IOwnProps> = ({
  classCss = [],
  children,
  link,
  openExternal = true,
  title,
}) => {
  const handlePushInteraction = () => {
    if (title && title !== '') {
      let exitUrl = link ? atob(link) : ''
      pushInteractionLinkPartner(title, exitUrl);
    }
  };

  if (Helpers.isPlatformVivoFixo()) {
    return (
      <a onClick={() => Helpers.sendPostMessage(link)} className={classNames([...classCss])}>
        {children}
      </a>
    );
  }

  return (
    <a
      onClick={handlePushInteraction}
      href={Helpers.createUrlManagerRedirect(link)}
      target={openExternal ? '_blank' : '_self'}
      className={classNames([...classCss])}
    >
      {children}
    </a>
  );
};

export default LinkToPartner;
