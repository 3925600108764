import { ICategory } from 'store/ducks/category/types';

export enum BenefitWithSeasonDetailsTypes {
  LOAD_REQUEST = '@benefit_with_season_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@benefit_with_season_details/LOAD_SUCCESS',
  LOAD_RESET = '@benefit_with_season_details/LOAD_RESET',
}

export interface IBenefitWithSeasonSessionSegment {
  amountAvailable: number;
  amountSold: number;
  segment: {
    name: string;
    maxAvailableTickets: number;
  };
}

export interface IBenefitWithSeasonSessionTicketsOrderItem {
  ticketOrderItemAmount: number;
  rewadSessionTicketsOrderItemID: number;
}

export interface IBenefitWithSeasonSession {
  rewardSessionID: number;
  available: boolean;
  ticketInformation: string;
  sessionDateTime: string;
  startShowDate: string;
  sessionTicketsAvailable: number;
  sessionTicketsSold: number;
  ticketPointValue: null;
  hideSession: boolean;
  deleted: boolean;
  rewardSessionSegment: IBenefitWithSeasonSessionSegment[];
  rewadSessionTicketsOrderItem: IBenefitWithSeasonSessionTicketsOrderItem[];
  sessionName: string;
}

export interface IBenefitWithSeasonDetails {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  endDate: string;
  terms: string;
  address: string;
  ticketPickUpPlace: string;
  genre: string;
  targetAudience: string;
  ageRating: string;
  eventType: string;
  securityTokenProtected: boolean;
  category: ICategory[];
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  rewardSessions: IBenefitWithSeasonSession[];
  success?: boolean;
  errorMessages?: boolean;
  isSponsored: boolean;
  partnerId: number;
  locationID: number;

  maxCancellationDate: number;
  tagHome: number;
  averageNote: number;
  enableAverage: boolean;
  timesClicked: number;
  initialCount: number;
  showCount: boolean;
  alertMessage: string;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  alertConfirmRedemption: string;
}

export interface IBenefitWithSeasonDetailsState {
  readonly data: IBenefitWithSeasonDetails;
  readonly loading: boolean;
}
