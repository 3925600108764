import { Reducer } from 'redux';
import { UseRewardOfferDetailsTypes, IUseRewardOfferDetailsState } from './types';

const INITIAL_STATE: IUseRewardOfferDetailsState = {
  data: {
    rewardVoucherTypeID: 0,
    orderRewardID: 0,
    rewardID: 0,
    partner: '',
    description: '',
    title: '',
    redemptionDate: '',
    expirationDate: '',
    voucherCode: '',
    qrCode: '',
    qrCodeUrlImage: '',
    partnerLink: '',
    howToUse: '',
    terms: '',
    name: '',
    date: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
  },
  loading: false,
};

const reducer: Reducer<IUseRewardOfferDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UseRewardOfferDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UseRewardOfferDetailsTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
