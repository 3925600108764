import { Reducer } from 'redux';
import { TextparamTypes, ITextparam } from './types';

const INITIAL_STATE: ITextparam = {
  data: {
    categoryMenuTitle: 'Mais opções de Resgate',
    bannerListTitle: 'Benefícios',
    bannerListDescription:
      'Selecionamos abaixo as melhores opções para você resgatar quantos benefícios quiser.',
  },
};

const reducer: Reducer<ITextparam> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TextparamTypes.LOAD_REQUEST:
      return { ...state };
    case TextparamTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: {
          categoryMenuTitle: action.payload.data[0].newText,
          bannerListTitle: action.payload.data[1].newText,
          bannerListDescription: action.payload.data[2].newText,
        },
      };
    default:
      return state;
  }
};

export default reducer;
