import { action } from 'typesafe-actions';
import {
  IRewardInternetPackageOrderResponse,
  RewardInternetPackageOrderTypes,
  IRewardInternetPackageOrderRequest,
} from './types';

export const saveRewardInternetPackage = (data: IRewardInternetPackageOrderRequest) =>
  action(RewardInternetPackageOrderTypes.LOAD_REQUEST, { data });
export const loadSuccess = (data: IRewardInternetPackageOrderResponse) =>
  action(RewardInternetPackageOrderTypes.LOAD_SUCCESS, { data });
