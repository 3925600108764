import classNames from 'classnames';
import React from 'react';
import { CategoryEnum, ICategory } from 'store/ducks/category/types';
import Style from './CategoryButton.module.scss';

interface IOwnProps {
  category: ICategory;
  onClick(): void;
}

const setIconCategory = (categoryTypeID: number): any => {
  switch (categoryTypeID) {
    case CategoryEnum.CULTURA:
      return 'https://cdn.programadepontosvivo.com.br/photos/cultura-category.svg';
    case CategoryEnum.GASTRONOMIA:
      return 'https://cdn.programadepontosvivo.com.br/photos/gastronomia-category.svg';
    case CategoryEnum.VIAGEM:
      return 'https://cdn.programadepontosvivo.com.br/photos/viagem-category.svg';
    case CategoryEnum.TECNOLOGIA:
      return 'https://cdn.programadepontosvivo.com.br/photos/tech-category.svg';
    case CategoryEnum.SUSTENTABILIDADE:
      return 'https://cdn.programadepontosvivo.com.br/photos/sustentabilidade-category.svg';
    case CategoryEnum.ESPORT:
      return 'https://cdn.programadepontosvivo.com.br/photos/game-category.svg';
    case CategoryEnum.BRINQUEDOEJOGOS:
      return 'https://cdn.programadepontosvivo.com.br/photos/brinquedos-category.svg';
    case CategoryEnum.PETS:
      return 'https://cdn.programadepontosvivo.com.br/photos/80c23587-8718-4e9c-a780-ddd858c5789b.svg';
    case CategoryEnum.VIDASAUDAVEL:
      return 'https://cdn.programadepontosvivo.com.br/photos/bem-estar-category.svg';
    case CategoryEnum.SHOPPING:
      return 'https://cdn.programadepontosvivo.com.br/photos/shopping-category.svg';
    case CategoryEnum.SERVICOSVIVO:
      return 'https://cdn.programadepontosvivo.com.br/photos/servicos-vivo-category.svg';
    default:
      return '';
  }
};

const CategoryButton = ({ category, onClick }: IOwnProps) => {
  const { name, categoryTypeID, iconUrl } = category;
  return (
    <div className={Style.button} onClick={onClick}>
      {iconUrl ? (
        <img className={Style.custonIcon} src={iconUrl} />
      ) : (
        <img className={Style.custonIcon} src={setIconCategory(categoryTypeID)} />
      )}
      <span className={Style.label}>{name}</span>
      <i className={classNames([Style.icon, Style.iconArrow])} />
    </div>
  );
};

export default CategoryButton;
