import jwt_decode from 'jwt-decode';
import { StatusParticipant } from '_utils/StatusParticipant';
import Authenticate from '../authenticate/authenticate';
import IParticipant from './iparticipant';

export default class Participant implements IParticipant {
  private readonly authenticate = new Authenticate();
  private readonly jwt = this.authenticate.getToken();
  private decodeToken = (this.jwt && jwt_decode(String(this.jwt))) as IParticipant;

  constructor(decodeToken?) {
    if (decodeToken) {
      this.decodeToken = decodeToken;
    }
  }

  Name = this.decodeToken.Name;
  OperatorLogin = this.decodeToken.OperatorLogin;
  Segment = this.decodeToken.Segment;
  SegmentId = this.decodeToken.SegmentId;
  Situation = Number(this.decodeToken.Situation);
  SourceSystem = this.decodeToken.SourceSystem;
  State = this.decodeToken.State;
  StateId = this.decodeToken.StateId;
  TargetId = this.decodeToken.TargetId;
  Identity = this.decodeToken.Identity;
  Email = this.decodeToken.Email;
  ParticipantId = this.decodeToken.ParticipantId;

  public get firstName(): string {
    if (this.Name) {
      return this.Name.split(' ')[0].toLowerCase();
    }
    return '';
  }

  isParticipantValid(cpf: string): boolean {
    if (this.authenticate.isExistToken()) {
      const { Identity, Situation } = this.decodeToken;
      const status = Number(Situation);

      return (
        this.authenticate.isTokenExpired() &&
        Identity === cpf &&
        status === StatusParticipant.ACTIVE
      );
    }

    return false;
  }
}
