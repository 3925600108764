class ScrollPage {
  getPositionList(nameElementID: string, sizeList?: number, padding = 10) {
    const lastPosition = sizeList;
    const lastElement: HTMLDivElement | null = document.querySelector(
      `#${nameElementID}${lastPosition}`,
    );
    const posY = lastElement ? lastElement.offsetTop - padding : 0;
    return posY;
  }

  getPositionHome(nameElementID: string) {
    if (nameElementID) {
      const header: HTMLDivElement | null = document.querySelector(
        `#header-beneficios-loja`,
      );
      let headerSize: number = header ? header.clientHeight : 0;
      const lastElement: HTMLDivElement | null = document.querySelector(
        `#${nameElementID}`,
      );
      const posY = lastElement ? lastElement.offsetTop - headerSize : 0;
      return posY;
    } else {
      return 0;
    }
  }

  scrollBottom(positionCard) {
    window.scrollTo(0, positionCard);
  }
}

export default new ScrollPage();
