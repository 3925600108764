export enum LandingpageTypes {
  LOAD_REQUEST = '@landingpage/LOAD_REQUEST',
  LOAD_SUCCESS = '@landingpage/LOAD_SUCCESS',
  LOAD_FAILURE = '@landingpage/LOAD_FAILURE',
  LOAD_RESET = '@landingpage/LOAD_RESET',
}

export interface ILandingpage {
  landingPageID: number,
  title: string,
  message: string,
  rule: string,
  linkTypeEnum: number,
  titleButton: string,
  linkButton: string,
  contentImageId: string,
  startDate: string,
  endDate: string,
  createUser: string,
  createdDate: string,
  updatedDate: string,
  current: boolean,
  urlDownload210: string,
  urlDownload384: string,
  urlDownload480: string,
  urlDownload690: string,
}

export interface ILandingpageState {
  readonly data: ILandingpage;
  readonly loading: boolean;
  readonly success: boolean;
  readonly error: boolean;
}
