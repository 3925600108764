import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess, loadFailure } from './actions';

function imageEncode(arrayBuffer) {
  const b64encoded = btoa(
    [].reduce.call(
      new Uint8Array(arrayBuffer),
      (p, c) => {
        return p + String.fromCharCode(c);
      },
      '',
    ) as any,
  );
  return `data:image/jpeg;base64,${b64encoded}`;
}

export function* loadQrCode(action) {
  try {
    const res = yield call(
      API.get,
      `v1/api/barcode/qrcode/generate?qrCode=${action.payload.code}`,
      {
        responseType: 'arraybuffer',
      },
    );

    yield put(loadSuccess(imageEncode(res.data)));
  } catch (error) {
    yield put(setError(error.response));
    yield put(loadFailure());
  }
}
