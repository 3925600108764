export enum OurPartnersTypes {
  LOAD_REQUEST = '@our_partners/LOAD_REQUEST',
  LOAD_REQUEST_WITH_FILTER_STATE_ID = '@our_partners/LOAD_REQUEST_WITH_FILTER_STATE_ID',
  LOAD_SUCCESS = '@our_partners/LOAD_SUCCESS',
  SET_INDEX_WORD_SELECTED = '@our_partners/SET_INDEX_WORD_SELECTED',
}

export interface IPartners {
  partnerID: number;
  partnerName: string;
  partnerLogo: string;
  link: string;
  benefitId: number;
  benefitName: string;
  locationId: number;
}

export interface IPartnersList {
  indexWord: string;
  partners: IPartners[];
}

export interface IOurPartnersState {
  readonly data: IPartnersList[];
  readonly loading: boolean;
  readonly indexWordSelected: string;
}
