import classNames from 'classnames';
import Reward from 'components/Reward/Reward';
import RewardItem from 'components/RewardItem/RewardItem';
import RewardGift from 'components/RewardGift/RewardGift';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Slider from 'react-slick';
import { ApplicationState } from 'store';
import { IRewardDeeplink, IRewards } from 'store/ducks/rewards/rewards/types';
import { resetRewardDeepLink } from 'store/ducks/rewards/rewards/actions';
import Breakpoint from '_utils/breakpoints';
import Helpers from '_utils/helpers';
import Style from './Rewardlist.module.scss';
import {
  ComponentType,
  InteractionType,
  PushTagInteraction,
  PushViewItemList,
  PushClickItemList,
  normalizeTitle,
} from '_utils/TagManager';
import * as HomePositionAction from 'store/ducks/home_position/actions';
import TitleSectionHome from '../../components/TitleSectionHome/TitleSection'




interface IStateProps {
  rewards: IRewards;
  loading: boolean;
  rewardDeeplink: IRewardDeeplink;
}
interface IDispachProps {
  resetRewardDeepLink(): void;
  setLastPositionItemHome(lastPositionItemHome: string): void;
}

type props = IStateProps & IDispachProps;

const RewardList: React.FC<props> = ({
  rewards,
  loading,
  rewardDeeplink,
  resetRewardDeepLink,
  setLastPositionItemHome,
}) => {
  const {
    rewardHighlightcoItems,
    rewardHighlightDescription,
    alreadyRedeemedThisMonth,
    hasSimpleRewardsToRedeem,
  } = rewards;

  const PushViewItemListCarrouselRewards = (slide) => {    
    const itemList: any  = []
    rewards.rewardHighlightcoItems.map((item, index) => {

      if (slide === index) {
        let partnerId  = item.partnerID > 0 ? item.partnerID : item.locationID // idDoParceiro
      
        let objItem: any  = {
          'item_name': normalizeTitle(item.description), // nome-do-produto
          'item_id': item.rewardID, // id-do-produto
          'price': '0', // preco-do-produto
          'item_brand': `vivo-valoriza&${normalizeTitle(item.title)}&${partnerId}`, // marca-do-produto
          'item_category': item.mainCategoryID, // categoria-do-produto
          'item_variant': '', //variacao-do-produto
          'item_list_name': 'selecionados-para-voce', // nome-da-lista
          'index': index + 1, // posição-do-produto
          'discount': '' //desconto-do-produto
        }
        
        itemList.push(objItem)
      }
    })
    
    if (itemList.length > 0) {
      PushViewItemList(itemList, rewards.rewardHighlightcoItems.length, 'selecionados-para-voce')
    }
  };

  const addClassActive = (current, next) => {
    PushViewItemListCarrouselRewards(next)
    const dotActive: any = document.querySelector(`#_${next}_`);
    const dotInactive: any = document.querySelector(`#_${current}_`);
  
    if (!dotActive) {
      return;
    }
  
    dotActive.classList.add(`${Style.dotsPersonalizedActive}`);
    dotInactive.classList.remove(`${Style.dotsPersonalizedActive}`);
  };

  const positionInitialSlide = Helpers.getPositionOfObjectsList(
    'rewardID',
    rewards.rewardHighlightcoItems,
    rewardDeeplink.rewardID,
  );

  const isApplyDots = (rewardsList: IRewards): boolean => {
    return rewardsList.rewardHighlightcoItems.length > 1;
  };

  const dotActive = (index: number) =>
    index === positionInitialSlide ? Style.dotsPersonalizedActive : '';

  const isLoading = loading || rewardHighlightcoItems.length === 0;

  useEffect(() => {
    if(rewards.rewardHighlightcoItems.length > 0) {
      PushViewItemListCarrouselRewards(0)
    }
  }, [rewards]);

  const configRewardList = () => {
    return {
      slidesToShow: rewardHighlightcoItems.length > 1 ? 1.005 : 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: rewardDeeplink.autoPlay,
      autoplaySpeed: 5000,
      infinite: false,
      initialSlide: positionInitialSlide,
      centerPadding: '12px',
      centerMode: true,
      appendDots: dots => (
        <div>
          <ul className={Style.dots}> {dots} </ul>
        </div>
      ),
      customPaging(i) {
        return <div id={`_${i}_`} className={classNames([Style.dotsPersonalized, dotActive(i)])} />;
      },
      beforeChange: addClassActive,
      className: `${Style.styleList}`,
      cssEase: 'linear',
      dots: isApplyDots(rewards),
      variableWidth: false,
    };
  };

  const isDisabled = alreadyRedeemedThisMonth || !hasSimpleRewardsToRedeem ? true : false;

  const pushInteraction = (
    name: string, // nomeDoProduto
    id: number,// idDoProduto
    nomeDoParceiro: string,// nomeDoParceiro
    idDoParceiro: number, // idDoParceiro
    category: string, // nomeDaCategoria
    position: number, // posição
  ) => {
    PushTagInteraction(
      ComponentType.HOME_PREMIOS,
      InteractionType.CLICK_CARD,
      `${position}:${normalizeTitle(name)}`,
      'interaction',
      '',
      0,
      `${idDoParceiro}:${normalizeTitle(nomeDoParceiro)}`,
      `${id}:${normalizeTitle(name)}`,
    );

    PushClickItemList(
      {'list':'carrossel&especial-para-voce'},
      'productClick',
      normalizeTitle(name),
      id,
      0,
      normalizeTitle(nomeDoParceiro),
      idDoParceiro,
      category,
      position
    );

    setLastPositionItemHome('carrousel-rewards')
  }

  useEffect(() => {
    return () => {
      resetRewardDeepLink();
    };
  }, [resetRewardDeepLink]);

  return isLoading ? null : (
    <section id="carrousel-rewards">
      <div className={`${Style.container} ${rewardHighlightcoItems.length == 1 ? Style.single : '' }`}>
        {!!rewards && (
          <>
            <div className={Style.title}>
              <TitleSectionHome title={rewardHighlightDescription} />
            </div>
            <Slider {...configRewardList()}>
              {rewardHighlightcoItems.map((reward, index) => {
                return <RewardItem
                      pushInteraction={() => {
                        pushInteraction (
                          `${reward.benefitName}`, // nomeDoProduto
                          reward.rewardID, // idDoProduto
                          `${reward.partnerName}`, // nomeDoParceiro
                          reward.partnerID > 0 ? reward.partnerID : reward.locationID, // idDoParceiro
                          `${reward.mainCategoryID}`, // nomeDaCategoria
                          index + 1, // posição
                        )
                      }}
                      key={reward.rewardID}
                      reward={reward}
                      disabled={isDisabled}
                    />
              })}
            </Slider>
          </>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  rewards: state.rewards.data,
  loading: state.rewards.loading,
  rewardDeeplink: state.rewards.rewardDeeplink,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    resetRewardDeepLink,
    setLastPositionItemHome: HomePositionAction.setLastPositionItemHome,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RewardList);
