import React, { lazy } from 'react';
import PrivateRoutesWithSubRoutes from '../../components/PrivateRoutesWithSubRoutes/PrivateRoutesWithSubRoutes';

const ErrorService = lazy(() => import('../../pages/Errors/ErrorService/ErrorService'));

const RedemptionUnavailable = lazy(() =>
  import('../../pages/Errors/RedemptionUnavailable/RedemptionUnavailable'),
);

const TokenBlocked = lazy(() => import('../../pages/Errors/TokenBlocked/TokenBlocked'));
const Error = lazy(() => import('../../pages/Errors/Error/Error'));

export enum RoutesForScreenError {
  RESGATE_INSDISPONIVEL = '/resgate-indisponivel',
  PARTICIPANTE_BLOQUEADO = '/participante-bloqueado',
  SERVICO_INDISPONIVEL = '/servico-indisponivel',
  TELA_BLOQUEIO = '/block',
  NAO_AUTORIZADO = '/unauthorized',
  TELA_ERRO_OAM = '/error-oam',
  AUTENTICAR = '/',
  ERRO_NAO_MAPEADO = '/erro'
}

const routes = [
  // {
  //   path: RoutesForScreenError.RESGATE_INSDISPONIVEL,
  //   component: RedemptionUnavailable,
  // },
  // {
  //   path: RoutesForScreenError.SERVICO_INDISPONIVEL,
  //   component: ErrorService,
  // },
  // {
  //   path: RoutesForScreenError.PARTICIPANTE_BLOQUEADO,
  //   component: TokenBlocked,
  // },
  {
    path: RoutesForScreenError.RESGATE_INSDISPONIVEL,
    component: Error,
  },
  {
    path: RoutesForScreenError.SERVICO_INDISPONIVEL,
    component: Error,
  },
  {
    path: RoutesForScreenError.PARTICIPANTE_BLOQUEADO,
    component: Error,
  },
  {
    path: RoutesForScreenError.ERRO_NAO_MAPEADO,
    component: Error,
  },
];

const ErrorRoutes = () => {
  return <PrivateRoutesWithSubRoutes routes={routes} />;
};

export default ErrorRoutes;
