import { action } from 'typesafe-actions';
import { UseBenefitOfferDetailsTypes, IUseBenefitOfferDetails, IUseBenefitOfferDetailsCancel } from './types';

export const getUseBenefitOfferDetails = (id: number) =>
  action(UseBenefitOfferDetailsTypes.LOAD_REQUEST, { id });
export const cancelBenefitOffer = (id: number) => 
  action(UseBenefitOfferDetailsTypes.LOAD_CANCEL_REQUEST, { id });
export const loadSuccess = (data: IUseBenefitOfferDetails) =>
  action(UseBenefitOfferDetailsTypes.LOAD_SUCCESS, { data });
export const loadCancelSuccess = (data: IUseBenefitOfferDetailsCancel) =>
  action(UseBenefitOfferDetailsTypes.LOAD_CANCEL_SUCCESS, { data });
export const loadCancelError = (data: IUseBenefitOfferDetailsCancel) =>
  action(UseBenefitOfferDetailsTypes.LOAD_CANCEL_ERROR, { data });