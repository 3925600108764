import { Reducer } from 'redux';
import { IRewardWithoutSeasonDetailsState, RewardWithoutSeasonDetailsTypes } from './types';

const INITIAL_STATE: IRewardWithoutSeasonDetailsState = {
  data: {
    rewardID: 0,
    tag: '',
    title: '',
    description: '',
    startDate: '',
    maxPurchaseDate: '',
    terms: '',
    address: '',
    ticketPickUpPlace: '',
    genre: '',
    targetAudience: '',
    ageRating: '',
    securityTokenProtected: false,
    eventType: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    rewadSessionTicketsOrderItem: [],
    success: false,
    errorMessages: false,
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    isSponsored: false,
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
    locationID: 0,

    endDate: '',
    alertMessage: '',
    maxCancellationDate: 0,
    tagHome: 0,
    averageNote: 0,
    enableAverage: false,
    timesClicked: 0,
    initialCount: 0,
    showCount: false,
    descriptionBenefit: '',
    termsOfUseBenefit: '',
    descriptionRedeeem: '',
    useBenefit: '',
    ready: '',
    evaluationDescription: '',
    termsOfUseSponsorship: '',
    alertConfirmRedemption: '',
    aboutEvent: '',
    showVoucherLimitNotification: false,
  },
  loading: true,
};

const reducer: Reducer<IRewardWithoutSeasonDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardWithoutSeasonDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardWithoutSeasonDetailsTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardWithoutSeasonDetailsTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
