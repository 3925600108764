import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadTravel(action) {
  let isDeeplink = action.payload.isDeeplink || false
  try {
    const { data } = yield call(
      Api.get,
      `v1/api/reward/travel/detail/${action.payload.id}?orderRewardID=${action.payload.idOrder}&deeplink=${isDeeplink}`,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response, isDeeplink));
  }
}
