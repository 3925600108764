import { useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { bindActionCreators, Dispatch } from 'redux';
import * as WhiteListActions from '../../store/ducks/white_list/actions';
import { useHistory } from 'react-router-dom';
interface IStateToProps {
  loading: boolean;
}
interface IDispatchToProps {
  loadWhiteList(): void;
}

type props = IDispatchToProps & IStateToProps;

const WhiteList = ({ loading, loadWhiteList }: props) => {
  const history = useHistory();

  useEffect(() => {
    if (!loading && history.location.pathname !== '/manutencao') {
      loadWhiteList();
    }

    return history.listen(location => {
      if (location.pathname !== '/manutencao') {
        loadWhiteList();
      }
    });
  }, [loading, history]);

  return null;
};

const mapStateToProps = (state: ApplicationState) => ({
  loading: state.authenticate.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ ...WhiteListActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(WhiteList);
