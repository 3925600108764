import { Reducer } from 'redux';
import { IOrderBenefitOfferState, OrderBenefitOfferTypes } from './types';

const INITIAL_STATE: IOrderBenefitOfferState = {
  req: {
    RewardID: 0,
    StateId: '',
    ActivationChannel: '',
  },
  data: {
    orderRewardID: 0,
    title: '',
    tag: '',
    name: '',
    // location: '',
    date: '',
    textDateValidity: '',
    // time: '',
    qrCode: '',
    qrCodeUrlImage: '',
    howToUse: '',
    howToUseLabel: '',
    partnerLinkLabel: '',
    website: '',
    notSendTransactionalEmail: false,
    petFriendly: false,
    rewardID: 0,
    partnerID: 0,
    enabledCTA: false,

    locationID: 0,
    description: '',
    voucherCode: '',
    rewardVoucherTypeID: 0,
    emailParticipant: '',
    descriptionBenefit: '',
    termsOfUseBenefit: '',
    descriptionRedeeem: '',
    useBenefit: '',
    ready: '',
    evaluationDescription: '',
    defaultText: '',
    averageNote: 0,
    enableEvaluation:false,
    timesClicked: 0,
    initialCount: 0,
    showCount:false,
    validateDate: '',
    tagHome: 0,
    enableAverage:false,
  },
  loading: true,
};

const reducer: Reducer<IOrderBenefitOfferState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderBenefitOfferTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case OrderBenefitOfferTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true };
    case OrderBenefitOfferTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
