import { action } from 'typesafe-actions';
import { ISurveyQuestion, ISurveySuccess, SurveyTypes } from './types';

export const getSurvey = (id: number) => action(SurveyTypes.LOAD_REQUEST, { id });

export const loadSuccess = (data: ISurveyQuestion) => action(SurveyTypes.LOAD_SUCCESS, { data });

export const getAnswersQuestions = (data: ISurveyQuestion[]) =>
  action(SurveyTypes.GET_ANSWERS_QUESTIONS, { data });

export const saveSurvey = (campaignId: number, data: ISurveyQuestion[]) =>
  action(SurveyTypes.SAVE_SURVEY, { data, campaignId });

export const saveSurveySuccess = (success: ISurveySuccess) =>
  action(SurveyTypes.SAVE_SURVEY_SUCCESS, { success });

export const getButtonShouldContinue = (active: boolean) =>
  action(SurveyTypes.BUTTON_CONTINUE_SHOULD_DISABLED, { active });

export const completedSurvey = (positionOfQuestion: number) =>
  action(SurveyTypes.COMPLETED_SURVEY, { positionOfQuestion });

export const getQuestionType = (questionType: number) =>
  action(SurveyTypes.GET_QUESTION_TYPE, { questionType });

export const resetSurvey = () => action(SurveyTypes.RESET_SURVEY);
