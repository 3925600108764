import React from 'react';
import Style from './BannerLarge.module.scss';
import LinkTo from '../../components/LinkTo/LinkTo';
import { LinkTargetEnum } from '_utils/LinkTarget.enum';
import TitleSectionHome from '../../components/TitleSectionHome/TitleSection';
import ButtonDefault from 'components/ButtonDefault/ButtonDefault';
import BoxImagesSingleSize from 'components/BoxImagesSingleSize/BoxImagesSingleSize';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface IOwnProps extends RouteComponentProps{
  title: string | null;
  description: string;
  link: string;
  linkTitle: string;
  linkTargetId?: LinkTargetEnum;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  urlDownload430: string;
  pushInteraction(): void;
}

const BannerLarge: React.FC<IOwnProps> = ({
  title,
  description,
  link,
  linkTitle,
  linkTargetId,
  urlDownload210,
  urlDownload384,
  urlDownload480,
  urlDownload690,
  urlDownload430,
  pushInteraction,
  history, 
}) => {
  return (
    <div id="unforgettable-banner" className={Style.card}>
      <TitleSectionHome title={title} /> 
      <div className={Style.WrapBanner}>
      <BoxImagesSingleSize classCss={Style.image} title={title} urlDownload430={urlDownload430} urlDownload480={urlDownload480} />
        <div className={Style.description}>
          <p className={Style.text}>{description}</p>
          {link && (
            <div className={Style.wrapLink}>
              <ButtonDefault 
            func={() => {
                pushInteraction();
              history.push('/'); 
              }}
                colorButton={'Primary'}
                label={linkTitle}
                route={link}
                isSmall={true}
                isLoading={false}
                isInverse={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(BannerLarge);
