import { IFilter } from './types';
import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadFilters() {
  try {
    const eventTypes = yield call(API.get, 'v1/api/eventtype');
    const genres = yield call(API.get, 'v1/api/genre');
    const regions = yield call(API.get, 'v1/api/region/states');

    const res: IFilter = {
      genre: genres.data.data,
      eventTypes: eventTypes.data.data,
      regions: regions.data.data,
    };
    yield put(loadSuccess(res));
  } catch (error) {
    yield put(setError(error.response));
  }
}
