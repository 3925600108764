import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadError, loadSuccess } from './actions';

export function* loadRewards() {
  try {
    const response = yield call(Api.get, 'v1/api/reward/highlight/me');
    yield put(loadSuccess(response.data.data));
  } catch (error) {
    yield put(setError(error.response));
    yield put(loadError());
  }
}
