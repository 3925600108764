import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import ErrorBoundaryNewLayout from 'components/ErrorBoundaryNewLayout/ErrorBoundaryNewLayout';
import Loader from 'components/Loaders/Loader/Loader';
import Loading from 'components/Loaders/loading/Loading';
import WaitingFor from 'containers/WaitingFor/WaitingFor';
import WhiteList from 'containers/WhiteList/WhiteList';
import { ConnectedRouter } from 'connected-react-router';
import Header from 'containers/Header/Header';
import Inactive from 'pages/Inactive/Inactive';
// import Unauthorized from 'pages/Unauthorized/Unauthorized';
import Unauthorized from 'pages/Errors/Unauthorized/Unauthorized';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ApplicationState, history } from 'store';
import Authenticate from '_models/authenticate/authenticate';
import Helpers from '_utils/helpers';
import App from '../App';
import BenefitsRoutes from './Benefits/BenefitsRoutes';
import ErrorRoutes, { RoutesForScreenError } from './Error/ErrorRoutes';
import MyValorizaRoutes from './MyValoriza/MyValorizaRoutes';
import RewardsRoutes from './Rewards/RewardsRoutes';
import { RoutesPath } from './RoutesPath';
import UseRoutes from './Use/UseRoutes';

const History = lazy(() => import('pages/Use/History/History'));
const Onboard = lazy(() => import('pages/Onboard/Onboard'));
const Maintenance = lazy(() => import('pages/Errors/Maintenance/Maintenance'));
const ScreenBlock = lazy(() => import('pages/Errors/ScreenBlock/ScreenBlock'));
const ResearchRoutes = lazy(() => import('./Survey/SurveyRoutes'));
const LandingpageRoutes = lazy(() => import('./Landingpage/LandingpageRoutes'));
const RegulationpageRoutes = lazy(() => import('./Regulationpage/RegulationpageRoutes'));
const SearchRoutes = lazy(() => import('./Search/SearchRoutes'));
const OurPartners = lazy(() => import('pages/OurPartners/OurPartners'));
const ErrorAuthenticate = lazy(() =>
  import('pages/Errors/AuthenticateVivoOAM/AuthenticateVivoOAM'),
);
const Error = lazy(() => import('pages/Errors/Error/Error'));
const Deeplink = lazy(() => import('../containers/Deeplink/Deeplink'));

const PrivateRoute = ({ Component, ...rest }) => {
  const auth = new Authenticate();

  return (
    <Route
      {...rest}
      render={props =>
        auth.isTokenExpired() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/unauthorized',
              search: '?autenticaStatus=1',
            }}
          />
        )
      }
    />
  );
};

history.listen(async (location, action) => {
  window.scrollTo(0, 0);
});

const Routes = ({ search }) => {
  Helpers.setCookiePlatform(search);

  return (
    <ConnectedRouter history={history}>
      <ErrorBoundaryNewLayout>
        <LastLocationProvider>
          <Suspense fallback={<Loader />}>
            <Loading />
            {/* <WaitingFor /> */}
            <WhiteList />
            <Header />
            <Route path={RoutesPath.ROOT} exact={true} component={App} />

            {/*
            <Route path={RoutesPath.MANUTENCAO} exact component={Maintenance} />            
            <Route path={RoutesForScreenError.NAO_AUTORIZADO} component={Unauthorized} />
            <Route path={RoutesForScreenError.TELA_BLOQUEIO} component={ScreenBlock} />
            <Route path={RoutesForScreenError.TELA_ERRO_OAM} component={ErrorAuthenticate} />
            <Route path={RoutesPath.INACTIVE} component={Inactive} /> 
            */}

            <Route path={RoutesForScreenError.NAO_AUTORIZADO} component={Unauthorized} />
            <Route path={RoutesForScreenError.TELA_ERRO_OAM} component={ErrorAuthenticate} />
            <Route path={RoutesPath.MANUTENCAO} exact component={Error} />            
            <Route path={RoutesForScreenError.TELA_BLOQUEIO} component={Error} />
            <Route path={RoutesPath.INACTIVE} component={Error} />
            <ErrorRoutes />

            <Route path={RoutesPath.DEEP_LINK} component={Deeplink} />
            <MyValorizaRoutes />
            <RewardsRoutes />
            <BenefitsRoutes />
            <UseRoutes />
            <ResearchRoutes />
            <LandingpageRoutes />
            <RegulationpageRoutes />
            <SearchRoutes />
            <PrivateRoute path={RoutesPath.ONBOARD} Component={Onboard} />
            <PrivateRoute path={RoutesPath.HISTORICO} Component={History} />
            <PrivateRoute path={RoutesPath.NOSSOS_PARCEIROS} Component={OurPartners} />
          </Suspense>
        </LastLocationProvider>
      </ErrorBoundaryNewLayout>
    </ConnectedRouter>
  );
};
const mapStateToProps = (state: ApplicationState) => ({
  search: state.router.location.search,
});

export default connect(mapStateToProps)(Routes);
