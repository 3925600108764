export enum QrCodeTypes {
  LOAD_REQUEST = '@qrcode/LOAD_REQUEST',
  LOAD_SUCCESS = '@qrcode/LOAD_SUCCESS',
  LOAD_FAILURE = '@qrcode/LOAD_FAILURE',
  RESET_QRCODE = '@qrcode/RESET_QRCODE',
}

export interface IQrCode {
  qrCodeImageUrl: string;
}

export interface IQrCodeState {
  readonly data: IQrCode;
  readonly loading: boolean;
  readonly error: boolean;
}
