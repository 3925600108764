import { action } from 'typesafe-actions';
import { IRewardOfferDetails, RewardOfferDetailsTypes } from './types';

export const getRewardOfferDetails = (id: number, isDeeplink: boolean) =>
  action(RewardOfferDetailsTypes.LOAD_REQUEST, { id, isDeeplink });

export const loadSuccess = (data: IRewardOfferDetails) =>
  action(RewardOfferDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardOfferDetailsTypes.LOAD_RESET);
