import { ICategory } from 'store/ducks/category/types';

export enum BenefitWithoutSeasonDetailsTypes {
  LOAD_REQUEST = '@benefit_without_season/LOAD_REQUEST',
  LOAD_SUCCESS = '@benefit_without_season/LOAD_SUCCESS',
  LOAD_RESET = '@benefit_without_season/LOAD_RESET',
}

export interface IBenefitWithoutSeasonTicketsOrderItem {
  ticketOrderItemAmount: number;
  rewadTicketsOrderItemID: number;
}

export interface IBenefitWithoutSeasonDetails {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  startDate: string;
  maxPurchaseDate: string;
  terms: string;
  address: string;
  ticketPickUpPlace: string;
  genre: string;
  targetAudience: string;
  ageRating: string;
  securityTokenProtected: boolean;
  eventType: string;
  category: ICategory[];
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  rewadSessionTicketsOrderItem: IBenefitWithoutSeasonTicketsOrderItem[];
  success?: boolean;
  errorMessages?: boolean;
  isSponsored: boolean;
  partnerId: number;
  locationID: number;

  endDate: string;
  alertMessage: string;
  maxCancellationDate: number;
  tagHome: number;
  averageNote: number;
  enableAverage: boolean;
  timesClicked: number;
  initialCount: number;
  showCount: boolean;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  termsOfUseSponsorship: string;
  alertConfirmRedemption: string;
}

export interface IBenefitWithoutSeasonDetailsState {
  readonly data: IBenefitWithoutSeasonDetails;
  readonly loading: boolean;
}
