import { IRewardJourney } from '_models/RewardJourney/IRewardJourney';

export enum RewardOfferOrderTypes {
  LOAD_REQUEST = '@reward_offer_order/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_offer_order/LOAD_SUCCESS',
  LOAD_RESET = '@reward_offer_order/LOAD_RESET',
}

export interface IRewardOfferOrderRequest {
  rewardId: number;
  stateId: number | string | undefined;
  activationChannel: string;
}

export interface IRewardOfferOrderResponse {
  orderRewardID: number;
  tag: string;
  title: string;
  emailParticipant: string;
  description: string;
  rewardVoucherTypeID: number;
  name: string;
  voucherCode: string;
  website: string;
  qrCode: string;
  qrCodeUrlImage: string;
  date: string;
  howToUse: string;
  rewardJourney?: IRewardJourney;
  rewardId: number;
  partnerId: number;

  rewardID: number;
  endDate: string;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  securityTokenProtected: boolean;
  success?: boolean;
  errorMessages?: boolean;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;
  alertMessage: string;
  descriptionBenefit: string;
  descriptionRedeeem: string;
  evaluationDescription: string;
  mainCategoryID: string;
  ready: string;
  showVoucherLimitNotification: boolean;
  termsOfUseBenefit: string;
  useBenefit: string;
  defaultText: string;
}

export interface IRewardOfferOrderState {
  readonly data: IRewardOfferOrderResponse;
  readonly loading: boolean;
}

export enum IOrderRewardOfferOrderStatus {
  CREATE = 1,
  PAYMENTAUTHORIZED = 2,
  UNCONFIRMEDPAYMENT = 3,
  AUTHENTICATIONREQUIRED = 4,
  LIVECANCELLATION = 5,
  CONFIRMED = 6,
  ERROR = 7,
  UNDEFINED = 8,
  CANCELLED = 9,
}