import jwt_decode from 'jwt-decode';
import IParticipant from '_models/participant/iparticipant';
import Itoken from './itoken';
import Helpers from '_utils/helpers';
export default class Authenticate {
  set auth(auth: Itoken | any) {
    if (this.isAuthenticateBase64(auth)) {
      this.setAuth(auth);
      return;
    }

    this.setAuth(this.encode(auth));
  }

  private getAuth(): Itoken | any {
    const auth: any = sessionStorage.getItem('vv57430thkl00pe45');

    if (this.isAuthenticateBase64(auth) && auth) {
      return JSON.parse(atob(auth));
    }

    return null;
  }

  private encode(auth: Itoken): string {
    return btoa(JSON.stringify(auth));
  }

  private isAuthenticateBase64(auth: any): boolean {
    const regexBase64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    const isBase64 = regexBase64.test(`${auth}`);
    return isBase64;
  }

  private setAuth(auth: string) {
    sessionStorage.setItem('vv57430thkl00pe45', auth);
  }

  getToken(): string {
    const token: Itoken | any = this.getAuth();

    return token && token.accessToken ? token.accessToken : '';
  }

  getJWE(): string {
    return Helpers.getCookie('65djiwu289283jka8') + Helpers.getCookie('789rw87ra654das45');
  }

  isExistToken(): boolean {
    return this.getAuth();
  }

  getTokenDecode(): IParticipant {
    return jwt_decode(String(this.getToken()));
  }

  isTokenExpired(): boolean {
    const dateOfExp = this.getTokenDecode().exp;

    if (!dateOfExp) {
      return false;
    }

    const currentDate = Date.now() / 1000;

    return currentDate < dateOfExp;
  }
}
