import { push } from 'connected-react-router';
import { call, put, delay } from 'redux-saga/effects';
import store from 'store';
import { setError } from 'store/ducks/error';
import {
  waitingForLoadRequest,
  waitingForLoadError,
  waitingForLoadSuccess,
} from 'store/ducks/waiting_for/actions';
import Api from '_services/_API/Api';
import Helpers from '_utils/helpers';
import {
  PushTagNavigationRewardError,
  PushTagNonInteractionError,
  RewardCategory,
} from '_utils/TagManager';
import { loadSuccess } from './actions';
import { IRewardInternetPackageOrderStatus } from './types';

function* waitingCheck(data) {
  // em espera
  if (data.orderStatus === IRewardInternetPackageOrderStatus.CREATE) {
    yield put(
      waitingForLoadRequest({
        title: 'Estamos quase lá!',
      }),
    );
  }

  // deu certo
  if (
    data.orderStatus === IRewardInternetPackageOrderStatus.PAYMENTAUTHORIZED ||
    data.orderStatus === IRewardInternetPackageOrderStatus.CONFIRMED
  ) {
    yield put(loadSuccess(data));
    Helpers.saveSuccessRewardBenefits(data);
    yield put(push('sucesso'));
    yield put(waitingForLoadSuccess());
    return false;
  }

  // timeout ou erros no pedido
  if (
    data.orderStatus !== IRewardInternetPackageOrderStatus.CREATE &&
    data.orderStatus !== IRewardInternetPackageOrderStatus.PAYMENTAUTHORIZED &&
    data.orderStatus !== IRewardInternetPackageOrderStatus.CONFIRMED
  ) {
    yield put(
      setError({
        status: 422,
        data: {
          status: 422,
          success: false,
          errorMessages: [
            {
              description: 'Erro no pedido do pacote de internet.',
            },
          ],
        },
      }),
    );
    yield put(waitingForLoadError());

    return false;
  }

  return true;
}

export function* orderRewardInternetPackage(action) {
  try {
    yield put(waitingForLoadRequest());

    const { data: orderData } = yield call(
      Api.post,
      'v1/api/reward/order/internetpackage',
      action.payload.data,
    );
    const { rewardOrderID } = orderData.data;

    let waiting = true;

    while (waiting) {
      yield delay(5000);

      const { data } = yield call(
        Api.post,
        'v1/api/reward/order/internetpackage/status?orderRewardId=' +
          rewardOrderID +
          '&rewardId=' +
          action.payload.data.rewardId,
      );
      waiting = yield waitingCheck(data.data);
    }
  } catch (error) {
    const isExistDescription = error.response.data.errorMessages.length > 0;
    PushTagNavigationRewardError(
      store.getState().rewardInternetPackageDetails.data.title,
      RewardCategory.PACOTE_INTERNET,
    );

    if (isExistDescription) {
      PushTagNonInteractionError(error.response.data.errorMessages[0].description);
    }

    yield put(waitingForLoadError());
    yield put(setError(error.response));
  }
}
