import { Reducer } from 'redux';
import {
  IRewardCellphoneAndGadgetsDetailsState,
  RewardCellphoneAndGadgetsDetailsTypes,
} from './types';

const INITIAL_STATE: IRewardCellphoneAndGadgetsDetailsState = {
  data: {
    rewardID: 0,
    tag: '',
    title: '',
    description: '',
    terms: '',
    endDate: '',
    discountVoucherTypeDescription: '',
    securityTokenProtected: false,
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
  },
  loading: true,
};

const reducer: Reducer<IRewardCellphoneAndGadgetsDetailsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case RewardCellphoneAndGadgetsDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardCellphoneAndGadgetsDetailsTypes.LOAD_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
      };
    case RewardCellphoneAndGadgetsDetailsTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
