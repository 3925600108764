// Action types
export enum ValidateTokenSMSTypes {
  LOAD_INITIAL = '@validate_token_sms/LOAD_INITIAL',
  LOAD_REQUEST = '@validate_token_sms/LOAD_REQUEST',
  LOAD_SUCCESS = '@validate_token_sms/LOAD_SUCCESS',
}

// Data types
export interface IValidateTokenSMS {
  success: boolean;
  errorMessages: [{ code: string; description: string }];
  loading?: boolean;
}
// State type
export interface IValidateTokenSMState {
  readonly data: IValidateTokenSMS;
  readonly isActivateOrder: boolean;
  readonly loading: boolean;
}
