import { action } from 'typesafe-actions';
import { CategoryTypes, ICategory } from './types';

export const getCategories = (stateID: number, selectedCategory?: number) =>
  action(CategoryTypes.LOAD_REQUEST, { stateID, selectedCategory });

export const loadSuccess = (data: ICategory[]) => action(CategoryTypes.LOAD_SUCCESS, { data });

export const loadCategoryThematicName = (data: string) =>
  action(CategoryTypes.LOAD_CATEGORY_THEMATIC_NAME, { data });

export const loadReset = () => action(CategoryTypes.LOAD_RESET);

export const loadResetCache = () => action(CategoryTypes.LOAD_RESET_CACHE);
