import { Reducer } from 'redux';
import ErrorHandler from '_models/error-handler/ErrorHandler';
import Session from '_models/Session/Session';
import { ErrorState, ErrorTypes } from './types';

const INITIAL_STATE: ErrorState = {
  data: {
    errorMessages: [
      {
        code: '',
        description: '',
        internalCode: 0,
        internalDescription: ''
      },
    ],
  },
  success: false,
};

export const setError = (response, isDeeplink?: boolean) => {

  ErrorHandler.exception(response, undefined, isDeeplink);
  const { status } = response.data;
  if (status === 404) {
    return { type: ErrorTypes.SET, payload: { data: INITIAL_STATE.data } };
  }

  if (status === 401) {
    // Session.clearSession();
    console.log(`*****************************************************************`)
    console.log(`*****************************************************************`)
    console.log(`ENTROU NO ERRO 401`)
    console.log(response.data)
    console.log(`*****************************************************************`)
    console.log(`*****************************************************************`)
    return { type: ErrorTypes.SET, payload: { data: response.data } };
  }

  return { type: ErrorTypes.SET, payload: { data: response.data } };
};

const reducer: Reducer<ErrorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ErrorTypes.SET:
      return { ...state, data: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
