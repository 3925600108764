export enum OrderBenefitWithoutSeasonTypes {
  LOAD_REQUEST = '@order_benefit_without_season/LOAD_REQUEST',
  LOAD_SUCCESS = '@order_benefit_without_season/LOAD_SUCCESS',
}

export interface IOrderBenefitWithoutSeasonRequest {
  RewardID: number;
  TicketsItemID: number;
  StateId: number;
  ActivationChannel: string;
}

export interface IOrderBenefitWithoutSeasonResponse {
  orderRewardID: number;
  ticketItemAmount: number;
  name: string;
  location: string;
  date: string;
  qrCode: string;
  qrCodeUrlImage: string;
  howToUse: string;
  isSponsored: boolean;
  ticketPickUpPlace: string;
  rewardID: number;
  locationID: number;
  enabledCTA: boolean;

  partnerID: number;
  defaultText: string;
  ownTerms: string;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  validateDate: string;
  tagHome: number;
  averageNote: number;
  enableAverage: boolean;
  timesClicked: number;
  initialCount: number;
  showCount: boolean;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  alertConfirmRedemption: string;
  aboutEvent: string;
  addressLocation: string;
}

export interface IOrderBenefitWithoutSeasonState {
  readonly req: IOrderBenefitWithoutSeasonRequest;
  readonly data: IOrderBenefitWithoutSeasonResponse;
  readonly loading: boolean;
}
