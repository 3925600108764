import { Reducer } from 'redux';
import { IRewardInternetPackageState, RewardInternetPackageTypes } from './types';

const INITIAL_STATE: IRewardInternetPackageState = {
  data: {
    rewardID: 0,
    tag: '',
    title: '',
    description: '',
    maxPurchaseDate: '',
    terms: '',
    endPeriod: '',
    startPeriod: '',
    product: '',
    productSystemAtlysID: 0,
    productSystemAmdocsID: 0,
    productSystemNginID: 0,
    securityTokenProtected: false,
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    success: false,
    errorMessages: false,
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
    
    alertMessage: '',
    descriptionBenefit: '',
    descriptionRedeeem: '',
    evaluationDescription: '',
    locationID:  0,
    mainCategoryID:  0,
    ready: '',
    termsOfUseBenefit: '',
    useBenefit: '',
  },
  loading: true,
  error: false,
};

const reducer: Reducer<IRewardInternetPackageState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardInternetPackageTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardInternetPackageTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardInternetPackageTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    case RewardInternetPackageTypes.SELECT_ACCOUNT:
      return {
        ...state,
        data: {
          ...state.data,
          account: action.payload.phone,
          selectedId: +action.payload.phone.accountId,
        },
      };
    case RewardInternetPackageTypes.SET_REWARD_INTERNET_PACKAGE:
      return {
        ...state,
        data: {
          ...action.payload.reward,
        },
      };
    default:
      return state;
  }
};

export default reducer;
