import { action } from 'typesafe-actions';
import { EvaluationTypes, IEvaluation } from './types';

export const getEvaluation = (id: number) =>
  action(EvaluationTypes.LOAD_REQUEST, { id });

export const loadSuccess = (data: IEvaluation) =>
  action(EvaluationTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(EvaluationTypes.LOAD_RESET);
