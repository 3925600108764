export enum BannersTypes {
  LOAD_REQUEST = '@banners/LOAD_REQUEST',
  LOAD_SUCCESS = '@banners/LOAD_SUCCESS',
  LOAD_ERROR = '@banners/LOAD_ERROR',
}

export enum TagHomeEnum {
  SEMDADOS = 0,
  NOVIDADE = 1,
  EXPIRAEM = 2,
  ULTIMASUNIDADES = 3,
  QUANTIDADELIMITADA = 4,
  VALIDOATE = 5,
}
export interface IBanners {
  bannerID: number;
  name: string;
  caption: string;
  label: string;
  link: string;
  anotherPage: boolean;
  urlDownload364: string;
  urlDownload688: string;
  urlDownload137: string;
  partnerID: number;
  benefitID: number;
  mainCategoryID: string;
  partnerName: string;
  benefitName: string;
  locationID: number;
  tag: number;
  tagHome: TagHomeEnum.SEMDADOS;
  endShowDate: string;
  showCount: boolean;
  initialCount: number;
  timesClicked: number;
  enableAverage: boolean;
  averageNote: number;
}

export interface IBannersState {
  readonly data: IBanners[];
  readonly loading: boolean;
}
