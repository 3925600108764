import React from 'react';
import Style from './interectionBox.module.scss';

import { TagHomeEnum } from '../../store/ducks/banners/types';

import TagHome from 'components/TagHome/TagHome';
import CountView from 'components/CountView/CountView';
import AvaregeNote from 'components/AvaregeNote/AvaregeNote';

    interface IOwnProps {
        tagHome: number;
        enableAverage: boolean;
        showCount?: boolean;
        initialCount?: number;
        timesClicked?: number;
        endShowDate?: string;
        averageNote: number;
        isBannerDestaque?: boolean;
        margin?: string;
    }
  
    const interectionBox: React.FC<IOwnProps> = ({ 
        tagHome, 
        enableAverage, 
        showCount, 
        timesClicked, 
        initialCount,
        endShowDate,
        averageNote,
        isBannerDestaque,
        margin,
    }) => {
        return (
            <>
                {(tagHome !== TagHomeEnum.SEMDADOS || enableAverage || (
                    showCount &&
                    timesClicked &&
                    initialCount &&
                    timesClicked > initialCount
                )) ? (
                    <div className={Style.interectionBox} style={{margin: margin}}>
                        <div className={`tagHome ${Style.tagHome}`}>
                            {tagHome !== TagHomeEnum.SEMDADOS  && 
                                <TagHome tagHome={tagHome} endShowDate={endShowDate} />
                            }
                        </div>
                        <div className={`rating ${Style.rating}`}>
                            {enableAverage &&
                                <AvaregeNote
                                    enableAverage={enableAverage}
                                    averageNote={averageNote}
                                />
                            }
                        </div>
                        <div className={`views ${Style.views}`}>
                            {(showCount && initialCount) &&
                                <CountView 
                                    showCount={showCount || false}
                                    initialCount={initialCount || 0}
                                    timesClicked={timesClicked || 0}
                                />
                            }
                        </div>
                    </div>
                ) : (
                    <>
                        {isBannerDestaque &&
                            <div className={Style.withoutInterectionBox}></div>
                        }
                    </>
                )}
            </>
        );
    };
  
    export default interectionBox;

