import { IRegions } from './../regions/types';
export enum FiltersTypes {
  LOAD_REQUEST = '@filters/LOAD_REQUEST',
  LOAD_SUCCESS = '@filters/LOAD_SUCCESS',
  LOAD_FAILURE = '@filters/LOAD_FAILURE',
}

export interface IFilter {
  genre: IGenre[];
  eventTypes: IEventType[];
  regions: IRegions[];
}

export interface IGenre {
  genreID: number;
  name: string;
  selected: boolean;
}

export interface IEventType {
  eventTypeId: number;
  name: string;
  selected: boolean;
}

export interface IFilterRequest {
  categoryId: number;
  stateId: number;
  segmentId: number;
  page: number;
  pageSize: number;
}

export interface IFilterState {
  data: IFilter;
  error: boolean;
  loading: boolean;
}
