export enum RedemptionActionEnum {
  SUCCESS = 1,
  PARTNER = 2,
}

interface XP {
  canShare: boolean;
  redemptionButtonText: string;
  redemptionAction: RedemptionActionEnum;
  howToUseLabel: string;
  partnerLinkLabel: string;
}

export interface IRewardJourney extends XP {
  journeyIdentifierId: string;
}
