export enum ActiveRedemptionsTypes {
  LOAD_REQUEST = '@active_redemptions/LOAD_REQUEST',
  LOAD_SUCCESS = '@active_redemptions/LOAD_SUCCESS',
  CLEAR_DATA = '@active_redemptions/CLEAR_DATA',
  GET_LAST_POSITION = '@active_redemptions/GET_LAST_POSITION',
}

export const ActiveRedemptionsEnum = {
  BenefitEvent: 1,
  BenefitOffer: 2,
  RewardEvent: 3,
  RewardOffer: 4,
  RewardInternet: 5, // Rever nomenclatura
  RewardVivoTravel: 6,
  RewardMonthlyDiscount: 7,
  RewardPointsAndMiles: 8, // Rever nomenclatura
  RewardObjectDiscount: 9, // Rever nomenclatura
  RewardCinemark: 10,
  getClassByType: (t: number) => {
    switch (t) {
      case 0:
        return 'secondary';
      case 1:
      case 2:
        return 'benefit';
      default:
        return 'reward';
    }
  },
  getRouteByType: (t: number) => {
    switch (t) {
      case 1:
        return 'beneficio/evento';
      case 2:
        return 'beneficio/oferta';
      case 3:
        return 'rewards/evento';
      case 4:
        return 'rewards/oferta';
      case 5:
        return 'rewards/pacote-de-internet';
      case 6:
        return 'rewards/diaria-vivo-travel';
      case 7:
        return 'rewards/desconto-na-mensalidade';
      case 8:
        return 'rewards/pontos/milhas-aereas';
      case 9:
        return 'rewards/desconto-em-aparelhos-acessorios';
      case 10:
        return 'rewards/meia-entrada-cinemark';
      default:
        return '';
    }
  },
};

export interface IActiveRedemption {
  rewardID: number;
  rewardTypeID: number;
  tag: string;
  title: string;
  eventSeason: boolean;
  description: string;
  endDate: string;
  startDate: string;
  textDateValidity?: string;
  terms: string;
  link: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
}

interface VoucherRedeemed {
  voucherTypeID: number;
  name: string;
  hasVoucherRedeemed: boolean;
}

export interface IActiveRedemptionsPagination {
  data: IActiveRedemption[];
  numberPage: number;
  totalRows: number;
  pageRows: number;
  hasDeviceVoucherRedeemed: VoucherRedeemed;
  hasAcessoryVoucherRedeemed: VoucherRedeemed;
  hasMultipleVoucherRedeemed: VoucherRedeemed;
}

export interface IActiveRedemptionsState {
  readonly data: IActiveRedemptionsPagination;
  readonly lastPositionCard: number;
  readonly loading: boolean;
  readonly error: boolean;
}
