export enum CategoryTypes {
  LOAD_REQUEST = '@category/LOAD_REQUEST',
  LOAD_SUCCESS = '@category/LOAD_SUCCESS',
  LOAD_FAILURE = '@category/LOAD_FAILURE',
  LOAD_RESET = '@category/LOAD_RESET',
  LOAD_RESET_CACHE = '@category/LOAD_RESET_CACHE',
  LOAD_CATEGORY_THEMATIC_NAME = '@category/LOAD_CATEGORY_THEMATIC_NAME',
}

export interface ICategory {
  categoryID: number;
  categoryTypeID: number;
  name: string;
  iconUrl: string;
  startShowDate: string,
  endShowDate: string,
  position: number,
  thematicCategoryGroup:boolean,
  thematicCategory:boolean,
}

export interface ICategories {
  categories: ICategory[];
}

export interface ICategoryState {
  readonly data: ICategories;
  readonly loading: boolean;
  readonly error: boolean;
  readonly categoryThematicName: string;
}

export enum CategoryEnum {
  ESPECIAIS = 0,
  TECNOLOGIA = 1,
  SUSTENTABILIDADE = 2,
  CULTURA = 3,
  ESPORT = 4,
  BRINQUEDOEJOGOS = 5,
  GASTRONOMIA = 6,
  PETS = 7,
  VIAGEM = 8,
  VIDASAUDAVEL = 9,
  SHOPPING = 10,
  SERVICOSVIVO = 11,
}

export const categoryThematic: ICategory = {
  name: 'Destaques',
  categoryID: 0,
  categoryTypeID: CategoryEnum.ESPECIAIS,
  iconUrl: ''
};

export const initialCategories = [
  {
    categoryID: 24,
    name: 'Tecnologia',
    categoryTypeID: 1,
  },
  {
    categoryID: 25,
    name: 'Sustentabilidade',
    categoryTypeID: 2,
  },
  {
    categoryID: 2,
    name: 'Cultura',
    categoryTypeID: 3,
  },
  {
    categoryID: 26,
    name: 'E-Sports',
    categoryTypeID: 4,
  },
  {
    categoryID: 27,
    name: 'Brinquedos e Jogos',
    categoryTypeID: 5,
  },
  {
    categoryID: 3,
    name: 'Gastronomia',
    categoryTypeID: 6,
  },
  {
    categoryID: 28,
    name: 'Pets',
    categoryTypeID: 7,
  },
  {
    categoryID: 5,
    name: 'Viagem',
    categoryTypeID: 8,
  },
  {
    categoryID: 29,
    name: 'Bem-estar',
    categoryTypeID: 9,
  },
  {
    categoryID: 30,
    name: 'Shopping',
    categoryTypeID: 10,
  },
];

export const GetCategoryDescriptionById = (id: number): string => {
  switch (id) {
    case CategoryEnum.ESPECIAIS:
      return 'especiais';
    case CategoryEnum.CULTURA:
      return 'cultura';
    case CategoryEnum.GASTRONOMIA:
      return 'gastronomia';
    case CategoryEnum.VIAGEM:
      return 'viagem';
    case CategoryEnum.TECNOLOGIA:
      return 'tecnologia';
    case CategoryEnum.SUSTENTABILIDADE:
      return 'sustentabilidade';
    case CategoryEnum.ESPORT:
      return 'esport';
    case CategoryEnum.BRINQUEDOEJOGOS:
      return 'brinquedoejogos';
    case CategoryEnum.PETS:
      return 'pets';
    case CategoryEnum.VIDASAUDAVEL:
      return 'vidasaudavel';
    case CategoryEnum.SHOPPING:
      return 'shopping';
    case CategoryEnum.SERVICOSVIVO:
      return 'servicosvivo';
    default:
      return 'especiais';
  }
};
