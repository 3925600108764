export enum TagTypes {
  LOAD_REQUEST = '@tag/LOAD_REQUEST',
  LOAD_SUCCESS = '@tag/LOAD_SUCCESS',
  LOAD_FAILURE = '@tag/LOAD_FAILURE',
  LOAD_RESET = '@tag/LOAD_RESET',
}

export interface ITag {
  tagID: number,
  tagName: string,
  categoryID: number,
  categoryTypeID: number,
  status: boolean,
  numberPosition: number
}

export interface ITagState {
  readonly data: ITag[];
  readonly loading: boolean;
  readonly success: boolean;
  readonly error: boolean;
}
