import { push } from 'connected-react-router';
import jwtDecode from 'jwt-decode';
import { Reducer } from 'redux';
import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import { action } from 'typesafe-actions';
import Authenticate from '../../../../_models/authenticate/authenticate';
import Api from '../../../../_services/_API/Api';

export enum CancelParticipantTypes {
  LOAD_REQUEST = '@cancel_participant/LOAD_REQUEST',
  LOAD_SUCCESS = '@cancel_participant/LOAD_SUCCESS',
}

export interface ICancelParticipant {
  success: boolean;
  errorMessages: [];
}

export interface ICancelParticipantState {
  readonly data: ICancelParticipant;
}

export const Creators = {
  getCancelParticipant: () => action(CancelParticipantTypes.LOAD_REQUEST),
};

export function* cancelPaticipation() {
  const auth = new Authenticate();
  const { Identity } = jwtDecode(String(auth.getToken()));

  try {
    const { data } = yield call(Api.get, `v1/api/participant/cancel?identity=${Identity}`);
    if (data.data) {
      yield put(push('inactive'));
    }
  } catch (error) {
    yield put(setError(error.response));
  }
}

const INITIAL_STATE: ICancelParticipantState = {
  data: {
    success: false,
    errorMessages: [],
  },
};

const reducer: Reducer<ICancelParticipantState> = (state = INITIAL_STATE, actions) => {
  switch (actions.type) {
    case CancelParticipantTypes.LOAD_REQUEST:
      return { ...state };
    case CancelParticipantTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: actions.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
