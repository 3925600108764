import { call, put } from 'redux-saga/effects';
import { setError } from '../error';
import API from './../../../_services/_API/Api';
import { loadBannerSuccess, loadError } from './actions';

export function* getLoadBannerFooter() {
  try {
    const { data } = yield call(API.get, 'v1/api/banner/footerBanner');
    yield put(loadBannerSuccess(data.data));
  } catch (error) {
    yield put(loadError());
    yield put(setError(error.response));
  }
}
