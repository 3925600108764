import { Reducer } from 'redux';
import { IRewardUnfogettableState, RewardUnfogettableTypes } from './types';

const INITIAL_STATE: IRewardUnfogettableState = {
  data: {
    unforgettableRedeemId: 0,
    image: "",
    title: "",
    description: "",
    link: "",
    linkTitle: "",
    urlDownload210: "",
    urlDownload384: "",
    urlDownload480: "",
    urlDownload690: "",
    urlDownload430: "",
  },
  error: false,
  loading: true,
};

const reducer: Reducer<IRewardUnfogettableState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardUnfogettableTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardUnfogettableTypes.LOAD_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        error: false, 
        data: action.payload.data
      };
    case RewardUnfogettableTypes.LOAD_ERROR:
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
};

export default reducer;
