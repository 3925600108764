import { Reducer } from 'redux';
import { EvaluationTypes, IEvaluationState } from './types';

const INITIAL_STATE: IEvaluationState = {
  data: {    
    amountStar: 0,
    answerEvaluation: null,
    charactersAmount: '',
    commentDescription: '',
    evaluationID: 0,
    evaluationLinked: [],
    evaluationSatisfaction: [],
    isAvailable: false,
    mainQuestion: '',
    namePartner: '',
    scaleEvaluation: {},
    starMandatory: 0,
  },
  loading: true,
};

const reducer: Reducer<IEvaluationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EvaluationTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case EvaluationTypes.LOAD_SUCCESS:
      return { loading: false, data: action.payload.data.data };
    case EvaluationTypes.LOAD_RESET:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;