import { Reducer } from 'redux';
import { IRewardMonthlyFeeDiscountOrderState, RewardMonthlyFeeDiscountOrderTypes } from './types';

const INITIAL_STATE: IRewardMonthlyFeeDiscountOrderState = {
  data: {
    orderRewardID: 0,
    tag: '',
    name: '',
    rewardID: 0,
    partnerID: 0,
  },
  loading: false,
};

const reducer: Reducer<IRewardMonthlyFeeDiscountOrderState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardMonthlyFeeDiscountOrderTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardMonthlyFeeDiscountOrderTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
