import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import ReduxToastr from 'react-redux-toastr';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { IAuthenticateState } from './ducks/authenticate/types';
import { IBadgesInformativesState } from './ducks/badges-informatives/types';
import { IBadgesState } from './ducks/badges/types';
import { IBannersState } from './ducks/banners/types';
import { IBannerFooterState } from './ducks/banner_footer/types';
import { IBenefitOfferDetailsState } from './ducks/benefits/offer/types';
import { IOrderBenefitOfferState } from './ducks/benefits/offer_order/types';
import { IBenefitWithoutSeasonDetailsState } from './ducks/benefits/without_season/types';
import { IOrderBenefitWithoutSeasonState } from './ducks/benefits/without_season_order/types';
import { IBenefitWithSeasonDetailsState } from './ducks/benefits/with_season/types';
import { IOrderBenefitWithSeasonState } from './ducks/benefits/with_season_order/types';
import { IBenefitsStoreState } from './ducks/benefits_store/types';
import { IHomePositionState } from './ducks/home_position/types';
import { ICategoryState } from './ducks/category/types';
import { ITagState } from './ducks/tag/types';
import { ILandingpageState } from './ducks/landingpage/types';
import { IDeeplinkState } from './ducks/deeplink/types';
import { ErrorState } from './ducks/error/types';
import { IFilterState } from './ducks/filters/types';
import { HeaderState } from './ducks/header';
import { ILoadingState } from './ducks/Loading';
import { IOurPartnersState } from './ducks/our_partners/types';
import { IAccountsState } from './ducks/participant/accounts/types';
import { IActivateParticipantState } from './ducks/participant/activate_participant';
import { ICancelParticipantState } from './ducks/participant/cancel_participant';
import { IMobileAccountsState } from './ducks/participant/mobile_accounts/types';
import { IQrCodeState } from './ducks/qrcode/types';
import { IRegionsState } from './ducks/regions/types';
import { IRewardCellphoneAndGadgetsDetailsState } from './ducks/rewards/cellphone_and_gadgets/types';
import { IRewardCellphoneAndGadgetsOrderState } from './ducks/rewards/cellphone_and_gadgets_order/types';
import { IRewardCinemarkDetailsState } from './ducks/rewards/cinemark/types';
import { IRewardCinemarkOrderState } from './ducks/rewards/cinemark_order/types';
import { IRewardInternetPackageState } from './ducks/rewards/internet_package/types';
import { IRewardInternetPackageOrderState } from './ducks/rewards/internet_package_order/types';
import { IRewardMonthlyFeeDiscountDetailsState } from './ducks/rewards/monthly_fee_discount/types';
import { IRewardMonthlyFeeDiscountOrderState } from './ducks/rewards/monthly_fee_discount_order/types';
import { IRewardOfferDetailsState } from './ducks/rewards/offer/types';
import { IRewardOfferOrderState } from './ducks/rewards/offer_order/types';
import { IRewardsState } from './ducks/rewards/rewards/types';
import { IRewardTravelState } from './ducks/rewards/travel/types';
import { IRewardTravelOrderState } from './ducks/rewards/travel_order/types';
import { IRewardWithoutSeasonDetailsState } from './ducks/rewards/without_season/types';
import { IOrderRewardsWithoutSeasonState } from './ducks/rewards/without_season_order/types';
import { IRewardDetailsState } from './ducks/rewards/with_season/types';
import { IOrderRewardState } from './ducks/rewards/with_season_order/types';
import { IRewardUnfogettableState } from './ducks/rewards_unforgettable/types';
import { IRewardUnforgettableBannerState } from './ducks/rewards_unforgettable_banner/types';
import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';
import { ISearchState } from './ducks/search/types';
import { ISendEmailVoucherState } from './ducks/send_email_voucher/types';
import { ISurveyInterceptionState } from './ducks/survey/interception/types';
import { ISurveyListState } from './ducks/survey/list/types';
import { ISurveyState } from './ducks/survey/survey/types';
import { IStorePasswordState } from './ducks/token/store_password/types';
import { IValidateTokenSMState } from './ducks/token/validate_token_sms/types';
import { IActiveRedemptionsState } from './ducks/use/active_redemptions/types';
import { IUseBenefitOfferDetailsState } from './ducks/use/benefits/offer/types';
import { IUseBenefitWithoutSeasonDetailsState } from './ducks/use/benefits/without_season/types';
import { IUseBenefitWithSeasonDetailsState } from './ducks/use/benefits/with_season/types';
import { IHistoryState } from './ducks/use/history/types';
import { IUseRewardCellphoneGadgetsDetailsState } from './ducks/use/rewards/cellphone_gadgets/types';
import { IUseRewardCellphoneGadgetsItemState } from './ducks/use/rewards/cellphone_gadgets_item/types';
import { IUseRewardCinemarkDetailsState } from './ducks/use/rewards/cinemark/types';
import { IUseRewardOfferDetailsState } from './ducks/use/rewards/offer/types';
import { IUseRewardTravelDetailsState } from './ducks/use/rewards/travel/types';
import { IUseRewardWithoutSeasonDetailsState } from './ducks/use/rewards/without_season/types';
import { IUseRewardWithSeasonDetailsState } from './ducks/use/rewards/with_season/types';
import { IWhiteListState } from './ducks/white_list/types';
import { IWaitingForState } from './ducks/waiting_for';
import { ITextparam } from './ducks/text_param/types';
import { IEvaluationState } from './ducks/evaluation/types';
import { ISaveEvaluationAwnserState } from './ducks/evaluation_save/types';

export const history = createBrowserHistory();
declare var console: any;
export interface ApplicationState {
  router: any;
  authenticate: IAuthenticateState;
  accounts: IAccountsState;
  activeRedemptions: IActiveRedemptionsState;
  activateParticipant: IActivateParticipantState;
  banners: IBannersState;
  badges: IBadgesState;
  badgesInformatives: IBadgesInformativesState;
  benefitOfferDetails: IBenefitOfferDetailsState;
  benefitWithoutSeason: IBenefitWithoutSeasonDetailsState;
  benefitWithoutSeasonOrder: IOrderBenefitWithoutSeasonState;
  benefitWithSeason: IBenefitWithSeasonDetailsState;
  benefitWithSeasonOrder: IOrderBenefitWithSeasonState;
  benefitOfferOrder: IOrderBenefitOfferState;
  cancelParticipant: ICancelParticipantState;
  benefitsStore: IBenefitsStoreState;
  homePosition: IHomePositionState;
  category: ICategoryState;
  tag: ITagState;
  landingpage: ILandingpageState;
  deeplink: IDeeplinkState;
  errorHandler: ErrorState;
  header: HeaderState;
  mobileAccounts: IMobileAccountsState;
  regions: IRegionsState;
  rewardsUnforgettable: IRewardUnfogettableState;
  rewards: IRewardsState;
  rewardUnforgettableBanner: IRewardUnforgettableBannerState;
  rewardsWithoutSeasonDetails: IRewardWithoutSeasonDetailsState;
  rewardsWithoutSeasonOrder: IOrderRewardsWithoutSeasonState;
  rewardOfferDetails: IRewardOfferDetailsState;
  rewardCinemark: IRewardCinemarkDetailsState;
  rewardCinemarkOrder: IRewardCinemarkOrderState;
  storePassword: IStorePasswordState;
  sendEmailVoucher: ISendEmailVoucherState;
  rewardTravel: IRewardTravelState;
  rewardTravelOrder: IRewardTravelOrderState;
  validateTokenSMS: IValidateTokenSMState;
  withSeasonOrder: IOrderRewardState;
  withSeason: IRewardDetailsState;
  rewardOfferOrder: IRewardOfferOrderState;
  rewardMonthlyFeeDiscountDetails: IRewardMonthlyFeeDiscountDetailsState;
  rewardMonthlyFeeDiscountOrder: IRewardMonthlyFeeDiscountOrderState;
  rewardInternetPackageDetails: IRewardInternetPackageState;
  rewardInternetPackageOrder: IRewardInternetPackageOrderState;
  rewardCellphoneAndGadgets: IRewardCellphoneAndGadgetsDetailsState;
  rewardCellphoneAndGadgetsOrder: IRewardCellphoneAndGadgetsOrderState;
  toastr: ReduxToastr;
  userHistory: IHistoryState;
  useBenefitsOfferDetails: IUseBenefitOfferDetailsState;
  useRewardsOfferDetails: IUseRewardOfferDetailsState;
  useBenefitsWithoutSeasonDetails: IUseBenefitWithoutSeasonDetailsState;
  useBenefitsWithSeasonDetails: IUseBenefitWithSeasonDetailsState;
  useRewardsWithoutSeasonDetails: IUseRewardWithoutSeasonDetailsState;
  useRewardsWithSeasonDetails: IUseRewardWithSeasonDetailsState;
  useRewardsTravelDetails: IUseRewardTravelDetailsState;
  useRewardsCinemarkDetails: IUseRewardCinemarkDetailsState;
  useRewardsCellphoneGadgetsDetails: IUseRewardCellphoneGadgetsDetailsState;
  useRewardsCellphoneGadgetsItem: IUseRewardCellphoneGadgetsItemState;
  filters: IFilterState;
  whiteList: IWhiteListState;
  ourPartners: IOurPartnersState;
  qrCode: IQrCodeState;
  survey: ISurveyState;
  surveyInterception: ISurveyInterceptionState;
  surveyList: ISurveyListState;
  bannerFooter: IBannerFooterState;
  search: ISearchState;
  loading: ILoadingState;
  waitingFor: IWaitingForState;
  textParam: ITextparam;
  evaluation: IEvaluationState;
  evaluationSave: ISaveEvaluationAwnserState;
}
const middlewares: any = [routerMiddleware(history)];

const sagaMonitor =
  process.env.NODE_ENV === 'development' ? console.tron.createSagaMonitor() : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

middlewares.push(sagaMiddleware);

const composer: any =
  process.env.NODE_ENV === 'development'
    ? compose(applyMiddleware(...middlewares), console.tron.createEnhancer())
    : compose(applyMiddleware(...middlewares));

const store: Store<ApplicationState> = createStore(rootReducer(history), composer);
sagaMiddleware.run(rootSaga);

export default store;
