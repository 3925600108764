import { action } from 'typesafe-actions';
import { IRewardMonthlyFeeDiscount, RewardMonthlyFeeDiscountTypes } from './types';
import { IAccounts } from 'store/ducks/participant/accounts/types';

export const getRewardMonthlyFeeDiscountDetails = (id: number, isDeeplink: boolean) =>
  action(RewardMonthlyFeeDiscountTypes.LOAD_REQUEST, { id, isDeeplink });

export const selectRewardMonthlyFeeDiscountAcccount = (phone: IAccounts) =>
  action(RewardMonthlyFeeDiscountTypes.SELECT_ACCOUNT, { phone });

export const setRewardMonthlyFeeDiscountDetails = (reward: IRewardMonthlyFeeDiscount) =>
  action(RewardMonthlyFeeDiscountTypes.SET_REWARD_MONTHLY_FEE_DISCOUNT, { reward });

export const loadSuccess = (data: IRewardMonthlyFeeDiscount) =>
  action(RewardMonthlyFeeDiscountTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardMonthlyFeeDiscountTypes.LOAD_RESET);
