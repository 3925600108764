import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import ScrollPage from '_utils/ScrollPage';
import Style from './Navbar.module.scss';
import { 
  InteractionType, 
  PushTagInteraction,
  normalizeTitle,
} from '_utils/TagManager';
import { Link } from 'react-router-dom';

interface IStateProps {
  pathname: string;
}

type props = IStateProps;

const Navbar = ({
  pathname,
}: IStateProps) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const itemSliderRef = useRef<HTMLDivElement>(null);
  const [showSlide, setShowSlide] = useState(0);
  const items = [
    {
      menuId: 0,
      menuName: 'Destaques',
      menuLink: '/'
    },
    {
      menuId: 1,
      menuName: 'Resgatados',
      menuLink: '/use'
    },
    {
      menuId: 2,
      menuName: 'Mais',
      menuLink: '/meu-valoriza'
    },
  ]
  const pushTagInteraction = (name: string) => {
    PushTagInteraction('menu', InteractionType.CLICK_MENU, name);
  }

  const handleTagSelected = (id: number, name: string) => {
      pushTagInteraction(normalizeTitle(name))
      setShowSlide(id)
      ScrollPage.scrollBottom(0);
      aplyScrollSlider(id)        
  };

  const aplyScrollSlider = (id: number) => {
      let scrollBar = sliderRef?.current?.scrollWidth || 0;
      let screenVisible = sliderRef?.current?.getBoundingClientRect().width || 0;
      let hasScroll = scrollBar > screenVisible

      let screenWidth = document.documentElement.clientWidth;
      let hasPadding = pathname == '/'
      let padding = hasPadding ? (screenWidth * 4) / 100 : 0;

      const elementActive: HTMLDivElement | null = document.querySelector(
          `#item-${id}`,
      );
      let positionLeftElement = elementActive?.getBoundingClientRect()?.left || 0;
      let positionRightElement = elementActive?.getBoundingClientRect()?.right || 0;

      let elementIsVisible = positionLeftElement >= 0 && positionRightElement <= screenVisible

      if (hasScroll && !elementIsVisible) {
          if (positionLeftElement >= 0 && positionRightElement > screenVisible) {
              let contentHide = positionRightElement - screenVisible - padding
              let positionScroll = sliderRef?.current?.scrollLeft || 0 
              let gotoscroll = positionScroll + contentHide
              
              sliderRef?.current?.scroll(gotoscroll, 0);
          }
          if (positionLeftElement < 0 && positionRightElement <= screenVisible) {
              let contentHide = (- positionLeftElement) + padding
              let positionScroll = sliderRef?.current?.scrollLeft || 0 
              let gotoscroll = positionScroll - contentHide
              
              sliderRef?.current?.scroll(gotoscroll, 0);
          }
      }
  }

  useEffect(() => {
    if (pathname == '/use') {    
        setShowSlide(1)
        aplyScrollSlider(1)
    } else if (pathname == '/meu-valoriza') {    
        setShowSlide(2)
        aplyScrollSlider(2)
    } else {
      setShowSlide(0)
      aplyScrollSlider(0)
    }
  }, []);

  return (
      <>
          {items.length > 0 &&
                <div className={`${pathname == '/' ? Style.NavbarWrap : ''}`}>
                  <section ref={sliderRef} id='scroll' className={`sliderRef ${Style.Navbar}`}>
                      <div className={Style.wrap}>
                          {items.map((item, i) => (
                            <div key={i}>
                              <Link to={item.menuLink}>
                                <div
                                    id={`item-${item.menuId}`}
                                    ref={itemSliderRef}
                                    key={`tag-${item.menuId}`}
                                    className={`${Style.item} ${showSlide == item.menuId ? Style.itemActive : ''}`}
                                    onClick={() => {
                                        handleTagSelected(item.menuId, item.menuName)
                                    }}
                                >
                                    {item.menuName}
                                </div>
                              </Link>
                            </div>
                          ))}
                      </div>
                  </section>
                </div>
          }
      </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  pathname: state.router.location.pathname,
});

export default connect(mapStateToProps)(Navbar);