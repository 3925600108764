export enum RewardCinemarkDetailsTypes {
  LOAD_REQUEST = '@reward_cinemark/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_cinemark/LOAD_SUCCESS',
  LOAD_RESET = '@reward_cinemark/LOAD_RESET',
}

export interface IRewardCinemarkDetails {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  endDate: string;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  securityTokenProtected: boolean;
  success?: boolean;
  errorMessages?: boolean;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;
}

export interface IRewardCinemarkDetailsState {
  readonly data: IRewardCinemarkDetails;
  readonly loading: boolean;
}
