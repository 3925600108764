import { action } from 'typesafe-actions';
import {
  IOrderRewardTravelBodyRequest,
  IOrderRewardTravelResponse,
  RewardTravelOrderTypes,
} from './types';

export const saveOrderVivoTravel = (data: IOrderRewardTravelBodyRequest) =>
  action(RewardTravelOrderTypes.LOAD_REQUEST, { data });

export const loadSuccess = (data: IOrderRewardTravelResponse) =>
  action(RewardTravelOrderTypes.LOAD_SUCCESS, { data });

export const identifyScheduling = () => action(RewardTravelOrderTypes.SCHEDULLING);

export const identifyQuantityDays = (isOnlyOneDay: boolean) =>
  action(RewardTravelOrderTypes.ONLYONEDAY, { isOnlyOneDay });
