import { ICategory } from 'store/ducks/category/types';
import Participant from '_models/participant/participant';
import Bowser from 'bowser';

declare var window: any;
interface ITagUserState {
  id: string | undefined;
  name: string;
  cnpj: string;
  cpf: string | undefined;
  email: string | undefined;
  type: string;
  status: string | number;
  msisdn: string;
  plans: string[];
  address: {
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  fidelity: {
    points: string;
    memberSince: string;
    category: string | undefined;
  };
}

export enum ProfileOptionType {
  BENEFITS = 'conheca-as-categorias',
  REGULATION = 'veja-o-regulamento',
  REGULATION_CINEMARK = 'veja-o-regulamento-cinemark',
  CANCEL = 'cancelar-participacao',
  CONFIRM_CANCEL = 'confirmar-cancelamento',
}

export enum InteractionType {
  CLICK_CARD = 'click:card',
  CLICK_BUTTON = 'click:button',
  CLICK_MENU = 'click:menu',
  CLICK_BANNER = 'click:banner',
  CLICK_TEXT = 'click:text',
  BOX_CLICK = 'box:click',
  CONFIRMAR_RESGATE = 'confirmar-resgate',
  CANCELAR_RESGATE = 'cancelar-resgate',
  RESGATAR = 'resgatar',
  SUCESSO = 'view:sucesso',
  ERRO = 'view:erro',
  SCREEN_VIEW = 'screen:view',
  CANCELA_PARTICIPACAO_VIVO_VALORIZA = 'view:cancela-participacao',
}

export enum InteractionEvent {
  VIEW_MODAL = 'view:modal',
  VIEW_ERRO = 'view:erro',
  INTERACTION = 'interaction',
  NONINTERACTION = 'noninteraction',
  VIRTUAL_PAGE_VIEW = 'page_view',
  // VIRTUAL_PAGE_VIEW = 'virtualpageview',
}

export enum ComponentType {
  RESGATE = 'resgate',
  BENEFICIOS = 'beneficios',
  MENU = 'menu',
  HOME_INESQUECIVEIS = 'home-inesqueciveis',
  HOME_INESQUECIVEIS_DEFAULT = 'home-inesqueciveis',
  HOME_PREMIOS = 'home-premios',
  HOME_BENEFICIOS = 'home-beneficios',
  HOME_OUTROS_RESGATES = 'home-outros-resgates',
  PERFIL_CANCELAMENTO = 'perfil-cancelamento',
  PERFIL_OPCOES = 'perfil-opcoes',
  SCREEN = 'screen',
  MEUS_RESGATES = 'meus-resgates',
  PARCEIROS = 'parceiros',
  PESQUISA =  'pesquisa'
}

export enum RedemptionType {
  PREMIO = 'premios',
  BENEFICIOS = 'beneficios',
  INESQUECIVEIS = 'inesqueciveis',
}

export enum NavigationFlowStep {
  HOME = 'home',
  HISTORICO = 'historico',
  RESGATE_DETALHE = 'resgate-detalhe',
  RESGATE_REGULAMENTO = 'resgate-regulamento',
  SELECAO_DATA = 'selecao-data',
  SELECAO_HORA = 'selecao-hora',
  SELECAO_INGRESSOS = 'selecao-ingressos',
  TERMO_ACEITE = 'termo-aceite',
  SELECAO_LINHA = 'selecao-linha',
  RESGATE_CONFIRMACAO = 'resgate-confirmacao',
  RESGATE_SUCESSO = 'resgate-sucesso',
  RESGATE_ERRO = 'resgate-erro',
  TOKEN_LINHA = 'token-linha',
  TOKEN_CONFIRMACAO = 'token-confirmacao',
  CANCELAMENTO_PARTICIPANTE = 'cancelar-participacao',
  BUSCA = 'busca',
  PARCEIROS = 'parceiros'
}

export enum RewardCategory {
  EVENTO = 'evento',
  OFERTA = 'oferta',
  CINEMARK = 'cinemark',
  PACOTE_INTERNET = 'pacote-internet',
  DESCONTO_MENSALIDADE = 'desconto-mensalidade',
  VIVO_TRAVEL = 'vivo-travel',
  VOUCHER = 'voucher',
}

export enum BenefitCategory {
  ESPECIAIS = 'especiais',
  DIVERSAO = 'diversao',
  GASTRONOMIA = 'gastronomia',
  VIAGEM = 'viagem',
  BEMESTAR = 'bemestar',
  DESCONTOS = 'descontos',
  EVENTOS = 'eventos',
}

export enum SubCategory {
  ESCOLHA = 'escolha',
  ONBOARDING = 'onboarding',
  PERFIL = 'perfil',
  USE = 'use',
  FLUXO_RESGATE = 'fluxo-resgate',
}

interface IRootPath {
  ESCOLHA: string;
  ONBOARDING: string;
  PERFIL: string;
  USE: string;
}

export const rootPath: IRootPath = {
  ESCOLHA: '/vivovaloriza/escolha',
  ONBOARDING: '/vivovaloriza/onboarding',
  PERFIL: '/vivovaloriza/perfil',
  USE: '/vivovaloria/use',
};

export const normalizeTitle = (title: string = '') => {
  if (title == '' || title == null || title == undefined ) {
    return ''
  } else {
    let titleFormate = title.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w:\-]+/g, '-')
    .toLocaleLowerCase()
    .toLowerCase()
    .replace(/^\s+|\s+$/g, ''); // trim

    // remove accents, swap ñ for n, etc
    let from = "ãàáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    let to   = "aaaaaeeeeiiiioooouuuunc------";
    for (let i=0, l=from.length ; i<l ; i++) {
        titleFormate = titleFormate.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    
    titleFormate = titleFormate.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    if (titleFormate.indexOf('-') == 0) {
      titleFormate.replace('-', '')
    }

    titleFormate = titleFormate.substring(0, 99) // only 99 characters

    return titleFormate
  }
};

export const getKindOfPlataform = (): string => {
  const agent = Bowser.getParser(window.navigator.userAgent);
  const plataformType = agent.parsedResult.platform.type;
  return `${normalizeTitle(plataformType)}`;
};

export const getDetailAboutPlataform = (): string => {
  const agent = Bowser.getParser(window.navigator.userAgent);
  const name = agent.getBrowser().name;
  const version = agent.getBrowser().version;
  return `${normalizeTitle(name)}:${normalizeTitle(version)}`;
};

export const getPathRedemption = (
  reedemptionType: string,
  reedemptionCategory: string,
  reedemptionTitle: string,
  navigationFlow: string,
): string => {
  return `${reedemptionType}/${reedemptionCategory}/${normalizeTitle(
    reedemptionTitle,
  )}/${navigationFlow}`;
};

export function PushViewItemList(
  impressions: any,
  totalItens: number,
  itemListName: string,
  ): void {
  const interactionObject = {
    'event': 'view_item_list',
    'ecommerce': {
      'value': totalItens, // [valor-total-do-carrinho]
      'currency':'BRL',
      'item_list_name': itemListName, // ’nome-da-lista’, ex: destaque, em-oferta, etc
      'items': impressions
    }
  }
  setTimeout(() => {
    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
    window.dataLayer.push(interactionObject);
  }, 1);
}


export function PushClickItemList(
  actionField: any,
  event: string,
  nomeDoProduto: string = '',
  id: number = 0,
  price: number = 0,
  nomeDoParceiro: string,
  idDoParceiro: number,
  category: string = '',
  position: number = 0,
): void {
  const interactionObject = {
    event,
    ecommerce: {
      click: {
        actionField,
        products: [{
          name: normalizeTitle(nomeDoProduto),
          id,
          price,
          brand: `vivo-valoriza&${normalizeTitle(nomeDoParceiro)}&${idDoParceiro}`,
          category,
          position
        }]
      }
    },
  };
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({ ...interactionObject });
  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
}
export function PushTagInteraction(
  component: string,
  action: string,
  description: string,
  event: string = 'interaction',
  step: string = '',
  value: number = 0,
  parceiro: string = '',
  beneficio: string = '',
  exitUrl: string = '',
): void {
  const interactionObject = {
    event,
    interaction: {
      action,
      component,
      description: description,
      step,
      value,
      parceiro: parceiro,
      beneficio: beneficio,
      exitUrl: exitUrl,
    },
  };
  window.dataLayer.push({ ...interactionObject });
  window.dataLayer.push({ interaction: null });  // Clear the previous ecommerce object.
}

export async function PushTagNavigation(
  rootpath: string,
  pagePath: string,
  event: string,
  subCategory?: string,
  searchParam?: string,
  parceiro: string = '',
  beneficio: string = '',
  exitUrl: string = '',
): Promise<void> {
  const userValue = await getUserState();
  window.dataLayer.push({
    page: {
      area: 'b2c',
      segment: 'ecommerce',
      businessFront: 'fixa',
      category: 'vivovaloriza',
      subCategory,
      pagePath: `${rootpath}/${pagePath}`,
      environment: getKindOfPlataform(), //web|mobile
      platform: getDetailAboutPlataform(), // [nome da plataforma]:[versao] // web | android | ios]

      variation: '',
      geoRegion: '',
      pageID: '',
      searchParam: searchParam,
    },
    user: userValue,
    components: {},
    event,
    interaction: {
      parceiro: parceiro,
      beneficio: beneficio,
      exitUrl: exitUrl,
    },
  });
}

const salt = ',s)S.X-p;SdwsA2&lR.dIy|SCg}bZ1{&7y^%kpk2u9V+{mEO%n3HccBYJIKhFujb';

async function createHash(value) {
  const msgUint8 = new TextEncoder().encode(value);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

async function getUserState(): Promise<ITagUserState> {
  const participant = new Participant();
  const userName = await createHash(participant.firstName + salt);
  const userCpf = await createHash(participant.Identity + salt);
  const userEmail = await createHash(participant.Email + salt);
  return {
    id: participant.ParticipantId,
    name: userName,
    status: participant.Situation,
    cpf: userCpf,
    email: userEmail,
    cnpj: '',
    msisdn: '', // inicialmente não temos o numero de telefone do usuário que está navegando
    // isLoggedin: true // é preciso confirmar o que seria essa informação
    type: '',
    plans: [],
    fidelity: {
      category: participant.Segment ? participant.Segment.toLowerCase() : '',
      memberSince: participant.decodeToken && participant.decodeToken.RegisterDate ? participant.decodeToken.RegisterDate.toLowerCase() : '',
      points: '',
    },
    address: {
      city: '',
      country: '',
      postalCode: '',
      state: participant.State ? participant.State : '',
    },
  };
}

export function getConcatCategories(categories: ICategory[] | null) {
  if (!categories) {
    return null;
  }

  return categories.map(c => normalizeTitle(c.name)).join('-');
}

export const pushNavigationRedemptionFlowOffer = (
  redemptionType: string,
  title: string,
  navigationFlowStep: string,
  categories: ICategory[] | null,
  parceiro: number = 0,
  beneficio: number = 0,
  exitUrl: string = '',
) => {
  const concatCategories = getConcatCategories(categories);

  PushTagNavigation(
    rootPath.ESCOLHA,
    getPathRedemption(
      redemptionType,
      !concatCategories ? RewardCategory.OFERTA : concatCategories,
      title,
      navigationFlowStep,
    ),
    InteractionEvent.VIRTUAL_PAGE_VIEW,
    SubCategory.FLUXO_RESGATE,
    "",
    parceiro.toString(),
    beneficio.toString(),
    exitUrl,
  );
};

export const pushNavigationRedemptionFlowEvents = (
  redemptionType: string,
  title: string,
  navigationFlowStep: string,
  categories: ICategory[] | null,
  parceiro: number = 0,
  beneficio: number = 0,
  exitUrl: string = '',
) => () => {
  const concatCategories = getConcatCategories(categories);

  PushTagNavigation(
    rootPath.ESCOLHA,
    getPathRedemption(
      redemptionType,
      !concatCategories ? RewardCategory.EVENTO : concatCategories,
      title,
      navigationFlowStep,
    ),
    InteractionEvent.VIRTUAL_PAGE_VIEW,
    SubCategory.FLUXO_RESGATE,
    "",
    parceiro.toString(),
    beneficio.toString(),
    exitUrl,
  );
};

export const pushInteractionRedemptionDetail = (titleRedemption: string) =>
  PushTagInteraction(
    ComponentType.RESGATE,
    InteractionType.CLICK_BUTTON,
    `${InteractionType.RESGATAR}:${normalizeTitle(titleRedemption)}`,
    InteractionEvent.INTERACTION,
    '1',
  );

export const pushInteractionCancelDescription = (titleRedemption: string) =>
  PushTagInteraction(
    ComponentType.RESGATE,
    InteractionType.CLICK_BUTTON,
    `${InteractionType.CANCELAR_RESGATE}:${normalizeTitle(titleRedemption)}`,
    InteractionEvent.INTERACTION,
  );

export const pushInteractionToConfirmDescription = (titleRedemption: string) =>
  PushTagInteraction(
    ComponentType.RESGATE,
    InteractionType.CLICK_BUTTON,
    `${InteractionType.CONFIRMAR_RESGATE}:${normalizeTitle(titleRedemption)}`,
    InteractionEvent.INTERACTION,
    '2',
  );

export const pushNonInteractionSearchResult = (titleRedemption: string) => {
  PushTagInteraction(
    ComponentType.PARCEIROS,
    InteractionType.SCREEN_VIEW,
    titleRedemption,
    InteractionEvent.NONINTERACTION,
  );
}

export const pushInteractionExitUrl = (
    component,
    action,
    description: string,
    exitUrl: string
  ) => {
  PushTagInteraction(
    component,
    action,
    description,
    InteractionEvent.INTERACTION,
    '',
    0,
    '',
    '',
    exitUrl
  );
}

  export const pushNonInteractionSuccessRedemption = (titleRedemption: string) =>
  PushTagInteraction(
    ComponentType.RESGATE,
    InteractionType.SUCESSO,
    `sucesso:${normalizeTitle(titleRedemption)}`,
    InteractionEvent.NONINTERACTION,
  );

export const pushInteractionLinkPartner = (titleRedemption: string, exitUrl: string) =>
  PushTagInteraction(
    ComponentType.RESGATE,
    InteractionType.CLICK_BUTTON,
    `ir-parceiro:${normalizeTitle(titleRedemption)}`,
    InteractionEvent.INTERACTION,
    '',
    0,
    '',
    '',
    exitUrl
  );

// export const pushInteractionChoise = (titleRedemption: string) =>
//   PushTagInteraction(
//     ComponentType.MEUS_RESGATES,
//     InteractionType.CLICK_BUTTON,
//     `sem-resgate:${normalizeTitle(titleRedemption)}`,
//   );

export const PushTagNonInteractionError = (errorDescription: string) =>
  PushTagInteraction(
    ComponentType.RESGATE,
    InteractionType.ERRO,
    `erro:${normalizeTitle(errorDescription)}`,
    InteractionEvent.NONINTERACTION,
  );

export const PushTagNavigationBenefitOfferError = (
  titleBenefit: string,
  categories: ICategory[] | null,
) =>
  pushNavigationRedemptionFlowOffer(
    RedemptionType.BENEFICIOS,
    titleBenefit,
    NavigationFlowStep.RESGATE_ERRO,
    categories !== null ? categories : [],
  );

export const PushTagNavigationBenefitEventError = (titleBenefit: string, categories: ICategory[]) =>
  PushTagNavigation(
    rootPath.ESCOLHA,
    getPathRedemption(
      RedemptionType.BENEFICIOS,
      getConcatCategories(categories) as string,
      titleBenefit,
      NavigationFlowStep.RESGATE_ERRO,
    ),
    InteractionEvent.VIRTUAL_PAGE_VIEW,
    SubCategory.FLUXO_RESGATE,
  );

export const PushTagNavigationRewardError = (titleReward: string, rewardCategory: RewardCategory) =>
  PushTagNavigation(
    rootPath.ESCOLHA,
    getPathRedemption(
      RedemptionType.PREMIO,
      rewardCategory,
      titleReward,
      NavigationFlowStep.RESGATE_ERRO,
    ),
    InteractionEvent.VIRTUAL_PAGE_VIEW,
    SubCategory.FLUXO_RESGATE,
  );
