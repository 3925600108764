import { action } from 'typesafe-actions';
import { IFilterRequest } from './../filters/types';
import { BenefitsStoreTypes, IBenefitsStore } from './types';

export const getBenefits = (id: number, stateId: number, tagId?: any, body?: IFilterRequest) =>
  action(BenefitsStoreTypes.LOAD_REQUEST, { id, stateId, tagId, body });

export const setSelected = (item: number) => action(BenefitsStoreTypes.SET_SELECTED, { item });

export const setTagSelected = (item: number) => action(BenefitsStoreTypes.SET_TAG_SELECTED, { item });

export const setHeaderFixed = (isHeaderFixed: boolean) => action(BenefitsStoreTypes.SET_HEADER_FIXED, { isHeaderFixed });
  
export const setBenefitsStateIdSelected = (stateId: number) =>
  action(BenefitsStoreTypes.SET_STATEID_SELECTED, { stateId });

export const setPagination = (page: number) => action(BenefitsStoreTypes.SET_PAGINATION, { page });

export const clearActiveBenefits = () => action(BenefitsStoreTypes.CLEAR_DATA);

export const loadSuccess = (data: IBenefitsStore, page?: number) =>
  action(BenefitsStoreTypes.LOAD_SUCCESS, { data, page });

export const setLastPositionCard = (lastPositionCard: number) =>
  action(BenefitsStoreTypes.LAST_POSITION_CARD, { lastPositionCard });

export const loadReset = () => action(BenefitsStoreTypes.LOAD_RESET);
