import { action } from 'typesafe-actions';
import {
  IRewardCellphoneAndGadgetsOrderRequest,
  IRewardCellphoneAndGadgetsOrderResponse,
  RewardCellphoneAndGadgetsOrderTypes,
} from './types';

export const saveOrderCellphoneAndGadgets = (data: IRewardCellphoneAndGadgetsOrderRequest) =>
  action(RewardCellphoneAndGadgetsOrderTypes.LOAD_REQUEST, { data });

export const loadSuccess = (data: IRewardCellphoneAndGadgetsOrderResponse) =>
  action(RewardCellphoneAndGadgetsOrderTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardCellphoneAndGadgetsOrderTypes.LOAD_RESET);
