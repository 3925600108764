import React from 'react';
import Style from './ErrorDefault.module.scss';
import WarningRed from 'assets/images/warning-red.svg';
import WarningPurple from 'assets/images/warning-purple.svg';
interface IOwnProps {
  title: string;
  message: string;
  code: number;
  icone: string;
}

const ErrorDefault = ({ title, message, code , icone}: IOwnProps) => {
  return (
    <section className={Style.wrap}>
      <img className={Style.ico} src={icone === 'Purple' ? WarningPurple : WarningRed} alt="" />
      <h1 className={Style.title}>{title}</h1>
      <p className={Style.message}>{message}</p>
      {code > 0 &&
        <p className={Style.cod}>{code}</p>
      }
    </section>
  );
};

export default ErrorDefault;
