import { Reducer } from 'redux';
import { LastPositionItemHomeTypes, IHomePositionState } from './types';

const INITIAL_STATE: IHomePositionState = {
  lastPositionItemHome: '',
};

const reducer: Reducer<IHomePositionState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LastPositionItemHomeTypes.LAST_POSITION_ITEM_HOME:
      return {
        ...state,
        lastPositionItemHome: action.payload.lastPositionItemHome,
      };
    default:
      return state;
  }
};
  
export default reducer;
