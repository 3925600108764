declare var window: any;

export default class PostMessageIframe {
  private static postMessage = (msg: string) => window.ReactNativeWebView.postMessage(msg, '*');

  static sendPostMessage(message) {
    const data = JSON.stringify({ url: message });

    if (
      window.ReactNativeWebView &&
      navigator.userAgent === 'MeuVivoApp ipad iphone' &&
      navigator.platform.indexOf('Linux') === -1
    ) {
      console.log('post message meuvivoapp ipad iphone = true');
      this.postMessage(data);
    } else {
      console.log('post message meuvivoapp ipad iphdone = false');
      window.ReactNativeWebView.postMessage(data);
    }

    return false;
  }
}
