import React from 'react';
import Style from './ErrorContainer.module.scss';

interface OwnProps {
  children: any;
}

const ErrorContainer = ({ children }: OwnProps) => {
  return <div className={Style.container}>{children}</div>;
};

export default ErrorContainer;
