import { Reducer } from 'redux';
import { UseRewardCinemarkDetailsTypes, IUseRewardCinemarkDetailsState } from './types';

const INITIAL_STATE: IUseRewardCinemarkDetailsState = {
  data: {
    orderRewardID: 0,
    partner: '',
    tag: '',
    description: '',
    title: '',
    redemptionDate: '',
    redemptionValidity: 0,
    howToUse: '',
    terms: '',
    name: '',
    date: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
  },
  loading: false,
};

const reducer: Reducer<IUseRewardCinemarkDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UseRewardCinemarkDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UseRewardCinemarkDetailsTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
