import { Reducer } from 'redux';
import { IOrderRewardsWithoutSeasonState, OrderRewardsWithoutSeasonTypes } from './types';

const INITIAL_STATE: IOrderRewardsWithoutSeasonState = {
  req: {
    RewardID: 0,
    TicketsItemID: 0,
    StateId: 0,
    ActivationChannel: '',
  },
  data: {
    orderRewardID: 0,
    name: '',
    location: '',
    ticketPickUpPlace: '',
    date: '',
    time: '',
    qrCode: '',
    qrCodeUrlImage: '',
    howtoUse: '',
    ticketItemAmount: 0,
    isSponsored: false,
    partnerID: 0,
    locationID: 0,

    rewardID: 0,
    description: '',
    howToUs: '',
    ownTerms: false,
    terms: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload480: '',
    urlDownload690: '',
    descriptionBenefit: '',
    termsOfUseBenefit: '',
    descriptionRedeeem: '',
    useBenefit: '',
    ready: '',
    evaluationDescription: '',
    defaultText: '',
    alertConfirmRedemption: '',
    aboutEvent: '',
    termsOfUseSponsorship: '',
    addressLocation: '',
  },
  loading: false,
};

const reducer: Reducer<IOrderRewardsWithoutSeasonState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderRewardsWithoutSeasonTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case OrderRewardsWithoutSeasonTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
