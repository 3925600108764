export enum RewardsTypes {
  LOAD_REQUEST = '@rewards/LOAD_REQUEST',
  LOAD_SUCCESS = '@rewards/LOAD_SUCCESS',
  LOAD_ERROR = '@rewards/LOAD_ERROR',
  GET_REWARD_DEEPLINK = '@rewards/GET_REWARD_DEEPLINK',
  RESET_REWARD_DEEPLINK = '@rewards/RESET_REWARD_DEEPLINK',
}

export enum RewardSituation {
  ACTIVE = 0,
  SOLDOFF = 1,
  EXPIRED = 2,
  REDEEMED = 3,
}

export enum TagHomeEnum {
  SEMDADOS = 0,
  NOVIDADE = 1,
  EXPIRAEM = 2,
  ULTIMASUNIDADES = 3,
  QUANTIDADELIMITADA = 4,
}

export interface IRewardHighlightcoItems {
  partnerID: number;
  mainCategoryID: number;
  rewardID: number;
  title: string;
  description: string;
  fullDescription: any;
  link: string;
  situation: number;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  isBadgeDouble: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  isRewardExtra: boolean;
  benefitName: string;
  partnerName: string;
  locationID: number;
  tag: TagHomeEnum.SEMDADOS;
  endShowDate: string;
}

export interface IRewardDeeplink {
  autoPlay: boolean;
  rewardID: number;
}

export interface IRewards {
  rewardHighlightTooltip: string;
  rewardHighlightDescription: string;
  alreadyRedeemedThisMonth: boolean;
  hasSimpleRewardsToRedeem: boolean;
  rewardHighlightcoItems: IRewardHighlightcoItems[];
}

export interface IRewardsState {
  readonly data: IRewards;
  readonly loading: boolean;
  readonly rewardDeeplink: IRewardDeeplink;
}
