import { Reducer } from 'redux';
import { UseBenefitWithoutSeasonDetailsTypes, IUseBenefitWithoutSeasonDetailsState, IUseBenefitWithoutSeasonCancel } from './types';

const INITIAL_STATE: IUseBenefitWithoutSeasonDetailsState = {
  data: {
    orderRewardID: 0,
    location: '',
    title: '',
    tag: '',
    maxPurchaseDate: '',
    expirationDate: '',
    redemptionDate: '',
    qrCode: '',
    qrCodeUrlImage: '',
    howToUse: '',
    orderNumber: '',
    startDate: '',
    startHour: '',
    ticketNumber: '',
    genre: '',
    targetAudience: '',
    ageRating: '',
    address: '',
    ticketPickUpPlace: '',
    description: '',
    terms: '',
    name: '',
    date: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload480: '',
    urlDownload690: '',
    enabledCTA: false,

    rewardID: 0,
    isSponsored: false,
    averageNote: 0,
    enableAverage: false,
    timesClicked: 0,
    initialCount: 0,
    showCount: false,
    ownTerms: false,
    validateDate: '',
    tagHome: '',
    defaultImage: '',
    descriptionBenefit: '',
    termsOfUseBenefit: '',
    descriptionRedeeem: '',
    useBenefit: '',
    ready: '',
    evaluationDescription: '',
    defaultText: '',
    alertConfirmRedemption: '',
    aboutEvent: '',
    termsOfUseSponsorship: '',
    addressLocation: '',
  },
  loading: false,
  cancel: {
    loading: false,
    success: false,
    errorMessages: []
  }
};

const reducer: Reducer<IUseBenefitWithoutSeasonDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UseBenefitWithoutSeasonDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UseBenefitWithoutSeasonDetailsTypes.LOAD_SUCCESS:
      return { ...state, loading: false, error: false, data: action.payload.data };
    case UseBenefitWithoutSeasonDetailsTypes.LOAD_CANCEL_REQUEST:
      let cancelResponse:IUseBenefitWithoutSeasonCancel = {
        loading: true,
        success: false,
        errorMessages: []
      }
      return { ...state, cancel: cancelResponse };
    case UseBenefitWithoutSeasonDetailsTypes.LOAD_CANCEL_SUCCESS:
      return { ...state, cancel: action.payload.data };
    case UseBenefitWithoutSeasonDetailsTypes.LOAD_CANCEL_ERROR:
      let cancelError:IUseBenefitWithoutSeasonCancel = {
        loading: true,
        success: false,
        errorMessages: []
      }
      return { ...state, cancel: cancelError };
    default:
      return state;
  }
};

export default reducer;
