import { action } from 'typesafe-actions';
import { IRewardDetails, RewardDetailsTypes } from './types';

export const getRewardDetails = (id: number, isDeeplink: boolean) => 
  action(RewardDetailsTypes.LOAD_REQUEST, { id, isDeeplink });

export const loadSuccess = (data: IRewardDetails) =>
  action(RewardDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardDetailsTypes.LOAD_RESET);
