export enum WhiteListTypes {
  LOAD_REQUEST = '@white_list/LOAD_REQUEST',
  LOAD_SUCCESS = '@white_list/LOAD_SUCCESS',
  LOAD_FAILURE = '@white_list/LOAD_FAILURE',
}

export interface ICPF {
  data: string[];
  isCPFValid: boolean;
}

export interface IWhiteListState {
  readonly data: string[];
  readonly isCPFValid: boolean;
  readonly loading: boolean;
  readonly error: boolean;
}
