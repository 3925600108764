import { action } from 'typesafe-actions';
import { AuthenticateTypes, IPayloadAuthenticate, IToken } from './types';

export const getAuthentication = (payload: IPayloadAuthenticate) =>
  action(AuthenticateTypes.LOAD_REQUEST, { ...payload });

export const validToken = () => action(AuthenticateTypes.LOAD_VALID_TOKEN);

export const loadSuccess = (data: IToken) => action(AuthenticateTypes.LOAD_SUCCESS, { data });

export const resetAuthenticate = () => action(AuthenticateTypes.RESET_AUTHENTICATE);

export const redirectDeepLink = () => action(AuthenticateTypes.REDIRECT_DEEP_LINK);

export const setOauthAuthentication = (payload: IPayloadAuthenticate) =>
  action(AuthenticateTypes.LOAD_OAUTH_AUTHENTICATION, { ...payload });

export const participantLogged = () => action(AuthenticateTypes.PARTICIPANT_LOGGED);

export const participantLogout = () => action(AuthenticateTypes.PARTICIPANT_LOGOUT);
