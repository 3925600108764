export enum RewardCellphoneAndGadgetsDetailsTypes {
  LOAD_REQUEST = '@reward_cellphone_and_gadgets/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_cellphone_and_gadgets/LOAD_SUCCESS',
  LOAD_RESET = '@reward_cellphone_and_gadgets/LOAD_RESET',
}

export interface IRewardCellphoneAndGadgetsDetails {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  terms: string;
  endDate: string;
  discountVoucherTypeDescription: string;
  securityTokenProtected: boolean;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;
}

export interface IRewardCellphoneAndGadgetsDetailsState {
  readonly data: IRewardCellphoneAndGadgetsDetails;
  readonly loading: boolean;
}
