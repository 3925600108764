import { Reducer } from 'redux';
import {
  IUseRewardCellphoneGadgetsDetailsState,
  UseRewardCellphoneGadgetsDetailsTypes,
} from './types';

const INITIAL_STATE: IUseRewardCellphoneGadgetsDetailsState = {
  data: {
    rewardDiscountVouchers: [],
    total: 0,
  },
  loading: true,
};

const reducer: Reducer<IUseRewardCellphoneGadgetsDetailsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case UseRewardCellphoneGadgetsDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case UseRewardCellphoneGadgetsDetailsTypes.LOAD_SUCCESS:
      return { loading: false, data: action.payload.data };
    default:
      return state;
  }
};

export default reducer;
