import { Reducer } from 'redux';
import { FiltersTypes, IFilterState } from './types';

const INITIAL_STATE: IFilterState = {
  data: {
    genre: [],
    eventTypes: [],
    regions: [],
  },
  loading: false,
  error: false,
};

const reducer: Reducer<IFilterState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FiltersTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case FiltersTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: {
          ...action.payload.data,
        },
      };
    case FiltersTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true, data: { ...INITIAL_STATE.data } };
    default:
      return state;
  }
};

export default reducer;
