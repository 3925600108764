import { action } from 'typesafe-actions';
import { BadgesTypes, IBadgesState } from './types';

export const getBadges = (quantity: number) => action(BadgesTypes.LOAD_REQUEST, { quantity });

export const selectBadge = (id: number) => action(BadgesTypes.SELECT_BADGE, { id });

export const loadSuccess = (data: IBadgesState[]) => action(BadgesTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(BadgesTypes.LOAD_RESET);
