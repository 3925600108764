import { IRewardJourney } from '_models/RewardJourney/IRewardJourney';

export enum RewardOfferDetailsTypes {
  LOAD_REQUEST = '@reward_offer/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_offer/LOAD_SUCCESS',
  LOAD_RESET = '@reward_offer/LOAD_RESET',
}

export interface IRewardOfferDetails {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  endDate: string;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  securityTokenProtected: boolean;
  success?: boolean;
  errorMessages?: boolean;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  rewardJourney?: IRewardJourney;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;

  alertMessage: string;
  descriptionBenefit: string;
  descriptionRedeeem: string;
  evaluationDescription: string;
  mainCategoryID: string;
  ready: string;
  showVoucherLimitNotification: boolean;
  termsOfUseBenefit: string;
  useBenefit: string;
}

export interface IRewardOfferDetailsState {
  readonly data: IRewardOfferDetails;
  readonly loading: boolean;
}
