// Action types
export enum RegionsTypes {
  LOAD_REQUEST = '@regions/LOAD_REQUEST',
  LOAD_SUCCESS = '@regions/LOAD_SUCCESS',
}

// Data types
export interface IRegions {
  stateId: number;
  name: string;
  acronym: string;
}
// State type
export interface IRegionsState {
  readonly data: IRegions[];
  readonly loading: boolean;
  readonly error: boolean;
}
