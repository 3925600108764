export enum OrderBenefitOfferTypes {
  LOAD_REQUEST = '@order_offer_benefits/LOAD_REQUEST',
  LOAD_SUCCESS = '@order_offer_benefits/LOAD_SUCCESS',
  LOAD_FAILURE = '@order_offer_benefits/LOAD_FAILURE',
}

export enum TagHomeEnum {
  SEMDADOS = 0,
  NOVIDADE = 1,
  EXPIRAEM = 2,
  ULTIMASUNIDADES = 3,
  QUANTIDADELIMITADA = 4,
  VALIDOATE = 5,
}
export interface IOrderBenefitOfferRequest {
  RewardID: number;
  StateId: string;
  ActivationChannel: string;
}

export interface IOrderBenefitOfferResponse {
  orderRewardID: number;
  name: string;
  title: string;
  // location: string;
  tag: string;
  date: string;
  textDateValidity?: string;
  // time: string;
  qrCode: string;
  qrCodeUrlImage: string;
  website: string;
  howToUse: string;
  howToUseLabel?: string;
  partnerLinkLabel: string;
  notSendTransactionalEmail: boolean;
  petFriendly: boolean;
  rewardID: number;
  partnerID: number;
  enabledCTA: boolean;

  locationID:number,
  description:string,
  voucherCode:string,
  rewardVoucherTypeID:number,
  emailParticipant:string,
  descriptionBenefit:string,
  termsOfUseBenefit:string,
  descriptionRedeeem:string,
  useBenefit:string,
  ready:string,
  evaluationDescription:string,
  defaultText:string,
  averageNote:number,
  enableEvaluation:boolean,
  timesClicked:number,
  initialCount:number,
  showCount:boolean,
  validateDate:string,

  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  terms: string;
  tagHome: TagHomeEnum.SEMDADOS;
  enableAverage:boolean,
}

export interface IOrderBenefitOfferState {
  readonly req: IOrderBenefitOfferRequest;
  readonly data: IOrderBenefitOfferResponse;
  readonly loading: boolean;
}

export enum IOrderBenefitOfferOrderStatus {
  CREATE = 1,
  PAYMENTAUTHORIZED = 2,
  UNCONFIRMEDPAYMENT = 3,
  AUTHENTICATIONREQUIRED = 4,
  LIVECANCELLATION = 5,
  CONFIRMED = 6,
  ERROR = 7,
  UNDEFINED = 8,
  CANCELLED = 9,
}