import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { ComponentType, InteractionEvent } from '_utils/TagManager';
import { IErrorMessages } from '../../../store/ducks/error/types';
import { PushTagInteraction } from '../../../_utils/TagManager';
import Style from './Unauthorized.module.scss';
import WarningRed from 'assets/images/warning-red.svg';
import WarningPurple from 'assets/images/warning-purple.svg';
import ErrorDefault from 'components/ErrorDefault/ErrorDefault'
import ErrorContainer from 'components/ErrorContainer/ErrorContainer'
import ButtonDefault from 'components/ButtonDefault/ButtonDefault';

interface IOwnProps {
  error: IErrorMessages;
}

const Unauthorized: React.FC<IOwnProps> = ({ error }) => {
  const [code, setCode] = useState<number>(0);
  const [description, setDescription] = useState<string>('');
  let NewCode:any = 711;
  let NewDescription:string = 'Erro Genérico';

  useEffect(() => {

    if (error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].internalCode > 0) {
      NewCode = error.errorMessages[0].internalCode ? error.errorMessages[0].internalCode : 711;
      NewDescription = error.errorMessages[0].internalDescription ? error.errorMessages[0].internalDescription : '';

      setCode(NewCode > 0 ? NewCode : 711)
      setDescription(NewDescription)
    } else {
      if (error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].code) {
        NewCode = error.errorMessages[0].code;
        NewDescription = error.errorMessages[0].description;
      }

      setCode(parseInt(NewCode) > 0 ? NewCode : 711)
      setDescription(NewDescription)
    }

    PushTagInteraction(
      ComponentType.SCREEN,
      InteractionEvent.VIEW_ERRO,
      `erro-acesso: ${NewCode}-${NewDescription}`,
    );

    console.log('****************************************************')
    console.log('TELA DE ERROS NÃO AUTORIZADOS, ERROS RELACIONADOS PROBLEMAS NA NOSSA AUTENTICAÇÃO')
    console.log('ERRO: ' + JSON.stringify(error))
    console.log('COD ERRO INTERNO: ' + NewCode)
    console.log('MENSAGEM ERRO INTERNO: ' + NewDescription)
    console.log('COD ERRO: ' + error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].code ? error?.errorMessages[0]?.code : 0)
    console.log('MENSAGEM ERRO: ' + error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].description ? error?.errorMessages[0]?.description : '')
    console.log('****************************************************')

  }, [error]);

  return (
    <ErrorContainer>
      <ErrorDefault 
        title={'Algo deu errado por aqui'}
        message={'Você pode tentar de novo agora ou fazer isso mais tarde'}
        code={code}
        icone={WarningRed}
      />
      <div className={Style.wrapBtn}>
        <ButtonDefault 
          func={() => {}}
          colorButton={'Primary'}
          label={'Tentar de novo'}
          route={'https://web.vivo.com.br/_/account/redirect.php?target=valoriza'}
          isSmall={true}
          isLoading={false}
          isInverse={false}
          directionText={'Center'}
          parentClassCss={Style.btn}
          target={''}
        />
      </div>
    </ErrorContainer>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  error: state.errorHandler.data,
});

export default connect(mapStateToProps)(Unauthorized);
