import classNames from 'classnames';
import React from 'react';
import Style from './BoxImagesSingleSize.module.scss';

interface IOwnProps {
  classCss?: string;
  title?: string;
  urlDownload210?: string;
  urlDownload384?: string;
  urlDownload430?: string;
  urlDownload480: string;
  urlDownload690?: string;
}

const BoxImagesSingleSize: React.FC<IOwnProps> = ({
  urlDownload210,
  urlDownload384,
  urlDownload430,
  urlDownload480,
  urlDownload690,
  title,
  classCss,
}) => {

  let newImage = ''
  
  if (urlDownload210) {
    newImage = urlDownload210.replace('_210','')
  }

  if (urlDownload384) {
    newImage = urlDownload384.replace('_384','')
  }

  if (urlDownload690) {
    newImage = urlDownload690.replace('_690','')
  }

  if (urlDownload430) {
    newImage = urlDownload430.replace('_430','')
  }
  
  if (urlDownload480) {
    newImage = urlDownload480.replace('_480','')
  }

  return (
    <div className={classNames([Style.WrapImg, classCss])}>
      <img
        className={Style.image}
        src={newImage}
        alt={title}
      />
    </div>
  );
};

export default BoxImagesSingleSize;
