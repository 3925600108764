import React from 'react';
import Style from './AvaregeNote.module.scss';
import Star from 'assets/images/star_filled.svg';

export interface IOwnProps {
  enableAverage: boolean;
  averageNote: number;
}

const AvaregeNote: React.FC<IOwnProps> = ({ 
  enableAverage,
  averageNote,
 }) => {
  
  
  return (
    <>
      {enableAverage &&
        <div className={Style.countView}>
          <div className={Style.star}>
            <img src={Star} alt="" />
          </div>
          <div>
            {averageNote.toFixed(1)}
          </div>
        </div>
      }
    </>
  );
};

export default AvaregeNote;
