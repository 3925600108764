import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { surveyInterceptionUrl } from '../interception/types';
import { loadSuccess, saveSurveySuccess } from './actions';
import { addLoading, removeLoading } from 'store/ducks/Loading/actions';

export function* getSurveyQuestions(action) {
  try {
    const { data } = yield call(Api.get, `v1/api/campaign/${action.payload.id}/question`);
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}

export function* saveSurvey(action) {
  yield put(addLoading());
  try {
    const { data } = yield call(Api.post, `v1/api/campaign/survey/question`, action.payload.data);

    yield put(saveSurveySuccess(data.data));
    
    yield put(push(`${surveyInterceptionUrl}${action.payload.campaignId}/sucesso`));
  } catch (error) {
    yield put(setError(error.response));
  }
  yield put(removeLoading());
}
