export enum BadgesInformativesTypes {
  LOAD_REQUEST = '@badges_informatives/LOAD_REQUEST',
  LOAD_SUCCESS = '@badges_informatives/LOAD_SUCCESS',
}

export interface IBadgesInformatives {
  badgeID: number;
  title: string;
  description: string;
  badgeIconUrl: string;
}

export interface IBadgesInformativesState {
  readonly data: IBadgesInformatives[];
  readonly loading: boolean;
}
