import { Reducer } from 'redux';
import { IRewardOfferDetailsState, RewardOfferDetailsTypes } from './types';

const INITIAL_STATE: IRewardOfferDetailsState = {
  data: {
    rewardID: 0,
    tag: '',
    title: '',
    description: '',
    endDate: '',
    terms: '',
    securityTokenProtected: false,
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    success: false,
    errorMessages: false,
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
    alertMessage: '',
    descriptionBenefit: '',
    descriptionRedeeem: '',
    evaluationDescription: '',
    mainCategoryID: '',
    ready: '',
    showVoucherLimitNotification: false,
    termsOfUseBenefit: '',
    useBenefit: '',
  },
  loading: true,
};

const reducer: Reducer<IRewardOfferDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardOfferDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardOfferDetailsTypes.LOAD_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
      };
    case RewardOfferDetailsTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
