import PrivateRoutesWithSubRoutes from 'components/PrivateRoutesWithSubRoutes/PrivateRoutesWithSubRoutes';
import React, { lazy } from 'react';
import Helpers from '_utils/helpers';

const DetailsWithSeason = lazy(() => import('pages/Details/Rewards/WithSeason/WithSeason'));

const DetailsWithSeasonSelect = lazy(() =>
  import('pages/Details/Rewards/WithSeason/WithSeasonRedemptionFlow/WithSeasonRedemptionFlow'),
);

const DetailsWithoutSeason = lazy(() =>
  import('pages/Details/Rewards/WithoutSeason/WithoutSeason'),
);

const DetailsWithoutSeasonSelect = lazy(() =>
  import(
    'pages/Details/Rewards/WithoutSeason/WithoutSeasonRedemptionFlow/WithoutSeasonRedemptionFlow'
  ),
);

const WithSeasonSuccess = lazy(() => import('pages/Success/Rewards/WithSeason/WithSeason'));

const WithoutSeasonSuccess = lazy(() =>
  import('pages/Success/Rewards/WithoutSeason/WithoutSeason'),
);

const Offer = lazy(() => import('pages/Details/Rewards/Offer/Offer'));
const OfferRegulation = lazy(() => import('pages/Details/Rewards/Offer/Regulation'));
const InternetPackageRegulation = lazy(() => import('pages/Details/Rewards/InternetPackage/Regulation'));
const OfferSuccess = lazy(() => import('pages/Success/Rewards/Offer/Offer'));

const DetailsWithSeasonRegulation = lazy(() => import('../../pages/Details/Rewards/WithSeason/Regulation'));
const DetailsWithoutSeasonRegulation = lazy(() => import('../../pages/Details/Rewards/WithoutSeason/Regulation'));

const MonthlyFeeDiscount = lazy(() =>
  import('pages/Details/Rewards/MonthlyFeeDiscount/MonthlyFeeDiscount'),
);
const MonthlyFeeDiscountSelectPhone = lazy(() =>
  import('pages/Details/Rewards/MonthlyFeeDiscount/MonthlyFeeDiscountSelectPhone'),
);
const MonthlyFeeDiscountConfirmation = lazy(() =>
  import('pages/Details/Rewards/MonthlyFeeDiscount/MonthlyFeeDiscountConfirmation'),
);
const MonthlyFeeDiscountSuccess = lazy(() =>
  import('pages/Success/Rewards/MonthlyFeeDiscount/MonthlyFeeDiscount'),
);
const VivoTravel = lazy(() => import('pages/Details/Rewards/Travel/Travel'));

const DetailVivoTravelSelect = lazy(() =>
  import('pages/Details/Rewards/Travel/TravelRedemptionFlow/TravelRedemptionFlow'),
);

const VivoTravelSucesso = lazy(() => import('pages/Success/Rewards/Travel/Travel'));

const InternetPackage = lazy(() => import('pages/Details/Rewards/InternetPackage/InternetPackage'));

const InternetPackageSelectPhone = lazy(() =>
  import('pages/Details/Rewards/InternetPackage/InternetPackageSelectPhone'),
);

const InternetPackageConfirmation = lazy(() =>
  import('pages/Details/Rewards/InternetPackage/InternetPackageConfirmation'),
);

const InternetPackageSuccess = lazy(() =>
  import('pages/Success/Rewards/InternetPackage/InternetPackage'),
);

const Cinemark = lazy(() => import('pages/Details/Rewards/Cinemark/Cinemark'));
const CinemarkSuccess = lazy(() => import('pages/Success/Rewards/Cinemark/Cinemark'));

const CellphoneAndGadgets = lazy(() =>
  import('pages/Details/Rewards/CellphoneAndGadgets/CellphoneAndGadgets'),
);

const cellphoneAndGadgetsSuccess = lazy(() =>
  import('pages/Success/Rewards/CellphoneAndGadgets/CellphoneAndGadgets'),
);

const routes = [
  {
    path: '/rewards/evento/temporada/:id',
    component: DetailsWithSeason,
    exact: true,
  },
  {
    path: '/rewards/evento/temporada/:id/selecionar',
    component: DetailsWithSeasonSelect,
  },
  {
    path: '/rewards/evento/temporada/:id/sucesso',
    component: WithSeasonSuccess,
  },
  {
    path: '/rewards/evento/:id',
    component: DetailsWithoutSeason,
    exact: true,
  },
  {
    path: '/rewards/evento/:id/selecionar',
    component: DetailsWithoutSeasonSelect,
  },
  {
    path: '/rewards/evento/temporada/:id/regulamento',
    component: DetailsWithSeasonRegulation,
  },
  {
    path: '/rewards/evento/:id/regulamento',
    component: DetailsWithoutSeasonRegulation,
  },
  {
    path: '/rewards/evento/:id/sucesso',
    component: WithoutSeasonSuccess,
  },
  {
    path: '/rewards/oferta/sucesso',
    component: OfferSuccess,
    exact: true,
  },
  {
    path: '/rewards/oferta/:id/regulamento',
    component: OfferRegulation,
    exact: true,
  },
  {
    path: '/rewards/oferta/:id',
    component: Offer,
    exact: true,
  },
  {
    path: '/rewards/pacote-de-internet/:id',
    component: InternetPackage,
    exact: true,
  },
  {
    path: '/rewards/pacote-de-internet/:id/regulamento',
    component: InternetPackageRegulation,
    exact: true,
  },
  {
    path: '/rewards/pacote-de-internet/:id/linha',
    component: InternetPackageSelectPhone,
  },
  {
    path: '/rewards/pacote-de-internet/:id/confirmacao',
    component: InternetPackageConfirmation,
  },
  {
    path: '/rewards/pacote-de-internet/:id/sucesso',
    component: InternetPackageSuccess,
  },
  {
    path: '/rewards/diaria-vivo-travel/:id/selecionar/sucesso',
    component: VivoTravelSucesso,
  },
  {
    path: '/rewards/diaria-vivo-travel/:id',
    component: VivoTravel,
    exact: true,
  },
  {
    path: '/rewards/diaria-vivo-travel/:id/selecionar/:idOrder?',
    component: DetailVivoTravelSelect,
  },
  {
    path: '/rewards/diaria-vivo-travel/:id/sucesso',
    component: VivoTravelSucesso,
  },
  {
    path: '/rewards/desconto-na-mensalidade/:id',
    component: MonthlyFeeDiscount,
    exact: true,
    resolve: () => Helpers.setAppBodyColor('white'),
  },
  {
    path: '/rewards/desconto-na-mensalidade/:id/linha',
    component: MonthlyFeeDiscountSelectPhone,
    resolve: () => Helpers.setAppBodyColor('white'),
  },
  {
    path: '/rewards/desconto-na-mensalidade/:id/confirmacao',
    component: MonthlyFeeDiscountConfirmation,
    resolve: () => Helpers.setAppBodyColor('white'),
  },
  {
    path: '/rewards/desconto-na-mensalidade/:id/sucesso',
    component: MonthlyFeeDiscountSuccess,
    resolve: () => Helpers.setAppBodyColor('white'),
  },
  {
    path: '/rewards/pontos/milhas-aereas/:id',
    component: <div />,
  },
  {
    path: '/rewards/meia-entrada-cinemark/sucesso',
    component: CinemarkSuccess,
  },
  {
    path: '/rewards/meia-entrada-cinemark/:id',
    component: Cinemark,
    exact: true,
  },
  {
    path: '/rewards/desconto-em-aparelhos-acessorios/sucesso',
    component: cellphoneAndGadgetsSuccess,
  },
  {
    path: '/rewards/desconto-em-aparelhos-acessorios/:id',
    component: CellphoneAndGadgets,
    exact: true,
  },
];

const RewardsRoutes = () => {
  return <PrivateRoutesWithSubRoutes routes={routes} />;
};

export default RewardsRoutes;
