import { Reducer } from 'redux';

export const WaitingForTypes = {
  LOAD_REQUEST: '@waiting_for/LOAD_REQUEST',
  LOAD_SUCCESS: '@waiting_for/LOAD_SUCCESS',
  LOAD_ERROR: '@waiting_for/LOAD_ERROR',
};

export interface IWaitingForState {
  loading: boolean;
  title?: string;
  description?: string;
}

export interface IWaitingForRequest {
  title?: string;
  description?: string;
}

const INITIAL_STATE: IWaitingForState = {
  loading: false,
  title: '',
  description: '',
};

const reducer: Reducer<IWaitingForState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WaitingForTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        title: action.payload.data?.title,
        description: action.payload.data?.description,
      };
    case WaitingForTypes.LOAD_SUCCESS:
      return { loading: false };
    case WaitingForTypes.LOAD_ERROR:
      return { loading: false };
    default:
      return state;
  }
};

export default reducer;
