import 'config/ReactotronConfig';
import 'moment/locale/pt-br';
import React from 'react';
import 'react-app-polyfill/ie11';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Routes from 'routes/routes';
import store from 'store';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: '9ea1f017-5c43-4628-bde0-3bc0b6c85d97',
  clientToken: 'puba4c2d9ff7e1528d4d800d91f30b0cc28',
  site: 'appapipproj.com',
  service: 'vivo-valoriza',
  sampleRate: process.env.REACT_APP_DATADOG_SAMPLERATE ? parseInt(process.env.REACT_APP_DATADOG_SAMPLERATE) : 0,
  replaySampleRate: process.env.REACT_APP_DATADOG_REPLAYSAMPLERATE ? parseInt(process.env.REACT_APP_DATADOG_REPLAYSAMPLERATE) : 0,
  trackInteractions: true,
  env: process.env.REACT_APP_ENVIRONMENT,
  allowedTracingOrigins: [/https:\/\/.*\.pppro-app.programadepontosvivo\.com\.br/]
});
datadogRum.startSessionReplayRecording();

ReactDOM.render(
  <Provider store={store}>
    <main className="container">
      <Routes />
    </main>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
