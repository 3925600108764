import { VoucherTypeBeneficio, VoucherTypePremio } from '_utils/VoucherType';

export enum UseBenefitOfferDetailsTypes {
  LOAD_REQUEST = '@use_benefit_offer_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@use_benefit_offer_details/LOAD_SUCCESS',
  LOAD_CANCEL_REQUEST = '@use_benefit_offer_details/LOAD_CANCEL_REQUEST',
  LOAD_CANCEL_SUCCESS = '@use_benefit_offer_details/LOAD_CANCEL_SUCCESS',
  LOAD_CANCEL_ERROR = '@use_benefit_offer_details/LOAD_CANCEL_ERROR',
}

export interface IUseBenefitOfferDetails {
  rewardVoucherTypeID: VoucherTypePremio | VoucherTypeBeneficio;
  voucherCode: string;
  orderRewardID: number;
  partner: string;
  title: string;
  description: string;
  redemptionDate: string;
  expirationDate: string;
  qrCode: string;
  qrCodeUrlImage: string;
  partnerLink: string;
  howToUse: string;
  howToUseLabel?: string;
  terms: string;
  name: string;
  date: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  notSendTransactionalEmail: boolean;
  petFriendly: boolean;
  enabledCTA: boolean;
}

export interface IUseBenefitOfferDetailsCancel {
  loading: boolean;
  success: boolean;
  errorMessages: any;
}

export interface IUseBenefitOfferDetailsState {
  readonly data: IUseBenefitOfferDetails;
  readonly cancel: IUseBenefitOfferDetailsCancel;
  readonly loading: boolean;
}
