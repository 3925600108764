import { success } from 'components/Toastr/Toastr';
import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* sendEmailVoucher(action) {
  try {
    const { data } = yield call(Api.post, 'v1/api/use/confirmation', action.payload.body);
    success('Compartilhado com sucesso');
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}
