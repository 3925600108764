import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import store from 'store';
import { setError } from 'store/ducks/error';
import { addLoading, removeLoading } from 'store/ducks/Loading/actions';
import Api from '_services/_API/Api';
import Helpers from '_utils/helpers';
import {
  PushTagNavigationRewardError,
  PushTagNonInteractionError,
  RewardCategory,
} from '_utils/TagManager';
import { loadSuccess } from './actions';
import { RedemptionActionEnum } from '_models/RewardJourney/IRewardJourney';

export function* orderRewardOffer(action) {
  // yield put(addLoading());

  try {
    const { data } = yield call(Api.post, 'v1/api/reward/order/offer', action.payload.data);

    yield put(loadSuccess(data.data));
    Helpers.saveSuccessRewardBenefits(data.data);

    if (data.data.rewardJourney?.redemptionAction === RedemptionActionEnum.PARTNER) {
      // yield put(removeLoading());

      if (data.data.website) {
        if (Helpers.isPlatformVivoFixo()) {
          Helpers.sendPostMessage(data.data.website);
        } else {
          window.location.href = Helpers.createUrlManagerRedirect(data.data.website);
        }
      }
    } else {
      yield put(push('sucesso'));
    }
  } catch (error) {
    const isExistDescription = error.response.data.errorMessages.length > 0;

    PushTagNavigationRewardError(
      store.getState().rewardOfferDetails.data.title,
      RewardCategory.OFERTA,
    );
    if (isExistDescription) {
      PushTagNonInteractionError(error.response.data.errorMessages[0].description);
    }

    // yield put(removeLoading());
    yield put(setError(error.response));
  }
}
