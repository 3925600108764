import React from 'react';
import Style from './CountView.module.scss';
import { useViews } from './useViews';

export interface IOwnProps {
  showCount: boolean;
  initialCount: number;
  timesClicked: number;
}

const CountView: React.FC<IOwnProps> = ({ 
  showCount,
  initialCount,
  timesClicked,
 }) => {
  const views = useViews(timesClicked);
  const validShowCount = showCount && timesClicked > initialCount;

  return (
    <>
      {validShowCount &&
        <div className={Style.countView}>
          <i className={Style.icoEye} />{views}
        </div>
      }
    </>
  );
};

export default CountView;
