import Axios from 'axios';
import Authenticate from '_models/authenticate/authenticate';
const auth = new Authenticate();

const Api = Axios.create({
  baseURL: `${process.env.REACT_APP_URL_APP_API}`,
});

Api.interceptors.request.use(async config => {
  config.headers.Accept = 'application/json';
  config.headers.Authorization = `Bearer ${auth.getToken()}`;
  config.headers['Ocp-Apim-Subscription-Key'] = `${process.env.REACT_APP_APP_KEY}`;
  return config;
});

export default Api;
