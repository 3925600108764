import { Reducer } from 'redux';

export const LoadingTypes = {
  ADD_LOADING: 'loading/ADD_LOADING',
  REMOVE_LOADING: 'loading/REMOVE_LOADING',
};

export interface ILoadingState {
  loading: boolean;
}

const INITIAL_STATE: ILoadingState = {
  loading: false,
};

const reducer: Reducer<ILoadingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoadingTypes.ADD_LOADING:
      return { loading: true };
    case LoadingTypes.REMOVE_LOADING:
      return { loading: false };
    default:
      return state;
  }
};

export default reducer;
