export enum UseRewardCinemarkDetailsTypes {
  LOAD_REQUEST = '@use_reward_cinemark_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@use_reward_cinemark_details/LOAD_SUCCESS',
}

export interface IUseRewardCinemarkDetails {
  orderRewardID: number;
  partner: string;
  tag: string;
  title: string;
  description: string;
  redemptionDate: string;
  redemptionValidity: number;
  howToUse: string;
  terms: string;
  name: string;
  date: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
}

export interface IUseRewardCinemarkDetailsState {
  readonly data: IUseRewardCinemarkDetails;
  readonly loading: boolean;
}
