export enum RewardDetailsTypes {
  LOAD_REQUEST = '@reward_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_details/LOAD_SUCCESS',
  LOAD_RESET = '@reward_details/LOAD_RESET',
}

export interface IRewardsSessionSegment {
  amountAvailable: number;
  amountSold: number;
  segment: {
    name: string;
    maxAvailableTickets: number;
  };
}

export interface IRewadSessionTicketsOrderItem {
  ticketOrderItemAmount: number;
  rewadSessionTicketsOrderItemID: number;
}

export interface IRewardSession {
  rewardSessionID: number;
  available: boolean;
  ticketInformation: string;
  sessionDateTime: string;
  startShowDate: string;
  sessionTicketsAvailable: number;
  sessionTicketsSold: number;
  ticketPointValue: null;
  hideSession: boolean;
  deleted: boolean;
  rewardSessionSegment: IRewardsSessionSegment[];
  rewadSessionTicketsOrderItem: IRewadSessionTicketsOrderItem[];
  sessionName: string;
}

export interface IRewardDetails {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  endDate: string;
  terms: string;
  address: string;
  ticketPickUpPlace: string;
  genre: string;
  targetAudience: string;
  ageRating: string;
  eventType: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  rewardSessions: IRewardSession[];
  securityTokenProtected: boolean;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  isSponsored: boolean;
  nameCategory: string;
  locationID: number;
  
  mainCategoryID: number;
  partnerId: number;
  showVoucherLimitNotification: boolean;
  alertMessage: string;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  alertConfirmRedemption: string;
  aboutEvent: string;
  termsOfUseSponsorship: string;

  success?: boolean;
  errorMessages?: boolean;
}
export interface IRewardDetailsState {
  readonly data: IRewardDetails;
  readonly loading: boolean;
}

