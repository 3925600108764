import { Reducer } from 'redux';
import { IStorePasswordRes, StorePasswordTypes } from './types';

const INITIAL_STATE: IStorePasswordRes = {
  success: false,
  loading: false,
};

const reducer: Reducer<IStorePasswordRes> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StorePasswordTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case StorePasswordTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
