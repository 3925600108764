import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadActiveRedemptions(action) {
  try {
    const { data } = yield call(
      Api.get,
      `v1/api/use/order/actives?page=${action.payload.page}&pageSize=6`,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}
