import jwtDecode from 'jwt-decode';
import Moment from 'moment';
import Authenticate from '_models/authenticate/authenticate';
import { Platform } from './Platform';
import PostMessageIframe from '_utils/PostMessageIframe';

declare var window;
export default class Helpers {
  static getCookie(name: string): string | any {
    if (name === '65djiwu289283jka8' || name === '789rw87ra654das45') {
      const queryString = window.location.search.split("&")

      if (name === '65djiwu289283jka8') {
        let code = queryString.find((item) => {
          if(item.indexOf('65djiwu289283jka8') >= 0) {
            const part1 = item.split("65djiwu289283jka8=")[1]
            return part1
          }
        })
        code = code ? code.replace('65djiwu289283jka8=', '') : ''
        return code
      } else if (name === '789rw87ra654das45') {
        let code = queryString.find((item) => {
          if(item.indexOf('789rw87ra654das45') >= 0) {
            const part2 = item.split("789rw87ra654das45=")[1]
            return part2
          }
        })
        code = code ? code.replace('789rw87ra654das45=', '') : ''
        return code
      }
    } else {
      const v: any = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
      return v ? v[2] : '';
    }
  }

  static getPlatform(): string {
    return this.getCookie('platform');
  }

  static setCookiePlatform(queryString: string) {
    if (queryString) {
      const params = new URLSearchParams(queryString);
      document.cookie = `platform=${
        this.getPlatform() ? this.getPlatform() : params.get('platform')
      }`;
    }
  }
  static isPlatformVivoFixo(): boolean {
    const currentPlatform = this.getCookie('platform');
    return currentPlatform === Platform.FIXO_APP || currentPlatform === Platform.FIXO_MOBILE;
  }

  static formateDateBrazilizan(date: Date | undefined) {
    if (date) {
      return Moment(date).format('DD/MM/YYYY');
    }
  }

  static formateDateyearmonthday(date: Date | undefined) {
    if (date) {
      return Moment(date).format('YYYY-MM-DD');
    }
  }

  static saveSuccessRewardBenefits(rb: object | any) {
    const convertToLatin = unescape(encodeURIComponent(JSON.stringify(rb)));
    sessionStorage.setItem('rbsuccess', btoa(convertToLatin));
  }

  static getSaveSuccessRewardBenefits() {
    const rbSuccess = sessionStorage.getItem('rbsuccess');
    if (rbSuccess) {
      return JSON.parse(decodeURIComponent(escape(atob(rbSuccess))));
    }
    return false;
  }

  static deleteSaveSuccessRewardBenefits() {
    const rbSuccess = sessionStorage.getItem('rbsuccess');
    if (rbSuccess) {
      sessionStorage.removeItem('rbsuccess');
    }
  }

  static isValidDate(d: string): boolean {
    const date: any = new Date(d);
    return date !== 'Invalid Date' && !isNaN(date);
  }

  static handleScroll(propShow: boolean): void {
    document.body.style.overflow = propShow ? 'hidden' : 'auto';
  }

  static formatPhone(phone: string) {
    const prefix = phone.substring(0, 10);
    const suffix = phone.substring(10);

    return `${prefix}-${suffix}`;
  }

  static formatPhoneWithHyphen(phone: string) {
    if (phone.length > 8) {
      return `${phone.substr(0, 5)}-${phone.substr(5, 4)}`;
    } else {
      return `${phone.substr(0, 4)}-${phone.substr(4, 4)}`;
    }
  }

  static formatUrlManagerRedirect(url: string): string {
    if (url) {
      return url.split('&').join('@');
    }
    return '';
  }

  static setLocalStorageReward(reward: any): void {
    const convertToLatin = unescape(encodeURIComponent(JSON.stringify(reward)));
    localStorage.setItem('reward', btoa(convertToLatin));
  }

  static removeLocalStorageReward(): void {
    localStorage.removeItem('reward');
  }

  static getLocalStorageReward(): any {
    const reward = localStorage.getItem('reward');
    if (reward) {
      return JSON.parse(decodeURIComponent(escape(atob(reward))));
    }
    return false;
  }

  static getProductSystemIdByBillingSource(type: string = '', reward: any): number {
    if (type === 'ATLYS') {
      return +reward.productSystemAtlysID;
    } else if (type === 'AMDOCS') {
      return +reward.productSystemAmdocsID;
    } else if (type === 'NGIN') {
      return +reward.productSystemNginID;
    } else {
      return 0;
    }
  }

  static setAppBodyColor(color: string): void {
    document.body.style.background = color;
  }

  static convertDateForFormatJson(date: Date | undefined | string): string {
    if (date) {
      return Moment(date).format('L');
    }
    return '';
  }

  static getStateIdParticipant(): number {
    const auth: any = new Authenticate();
    const { StateId } = jwtDecode(auth.getToken());
    return StateId;
  }

  static sendEndLoading(): void {
    try {
      if (window.AndroidHandler) {
        window.AndroidHandler.sendEndLoading();
        console.log('sendEndLoading android');
      } else {
        if (window.webkit) {
          window.webkit.messageHandlers.sendEndLoading.postMessage('MVAPP TAG');
          console.log('sendEndLoadgin MVAPP');
        } else {
          console.log(`without window.webkit - ${window.webkit}`);
        }
      }
    } catch (e) {
      if (process.env.NODE_ENV !== 'development') {
        console.log('send end loading error', e);
      }
    }
  }

  static getParamsRedirectOauth = (search: string) => {
    const params = new URLSearchParams(search);
    return Number(params.get('oauth') || localStorage.getItem('oauth'));
  };

  static getPositionOfObjectsList(property: string, list: any[], value: number | string): number {
    const position = list.findIndex(obj => obj[property] === value);
    return position > 0 ? position : 0;
  }

  static createUrlManagerRedirect = (link): string => {
    const urlCurrent = window.location.origin;
    return `${urlCurrent}/UrlManagerRedirect?externalUrl=${Helpers.formatUrlManagerRedirect(link)}`;
  };

  static sendPostMessage = (link: string | undefined, pageTarget: number = 0) => {
    const url = `${Helpers.createUrlManagerRedirect(link)}&open_internal=${pageTarget}`;
    PostMessageIframe.sendPostMessage(url);
  };
}
