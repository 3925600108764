import { Reducer } from 'redux';
import { IWhiteListState, WhiteListTypes } from './types';

const INITIAL_STATE: IWhiteListState = {
  data: [],
  loading: false,
  isCPFValid: false,
  error: false,
};

const reducer: Reducer<IWhiteListState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WhiteListTypes.LOAD_REQUEST:
      return { ...state, loading: true, error: false };
    case WhiteListTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.data,
        error: false,
      };
    case WhiteListTypes.LOAD_FAILURE:
      return { ...state, loading: false, data: [], error: true };
    default:
      return state;
  }
};

export default reducer;
