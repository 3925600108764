import { Reducer } from 'redux';
import { IRewardTravelOrderState, RewardTravelOrderTypes } from './types';

const INITIAL_STATE: IRewardTravelOrderState = {
  data: {
    orderRewardID: 0,
    tag: '',
    name: '',
    startDate: undefined,
    endDate: undefined,
    partnerID: 0,
  },
  loading: true,
  schedulling: false,
  isOnlyOneDay: false,
};

const reducer: Reducer<IRewardTravelOrderState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardTravelOrderTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardTravelOrderTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardTravelOrderTypes.SCHEDULLING:
      return {
        ...state,
        schedulling: true,
      };
    case RewardTravelOrderTypes.ONLYONEDAY:
      return {
        ...state,
        isOnlyOneDay: action.payload.isOnlyOneDay,
      };
    default:
      return state;
  }
};

export default reducer;
