import { all, takeLatest } from 'redux-saga/effects';
import { loadAuthenticate } from './authenticate/sagas';
import { AuthenticateTypes } from './authenticate/types';
import { loadBadgesInformatives } from './badges-informatives/sagas';
import { BadgesInformativesTypes } from './badges-informatives/types';
import { loadBadges } from './badges/sagas';
import { BadgesTypes } from './badges/types';
import { loadBanners } from './banners/sagas';
import { BannersTypes } from './banners/types';
import { getLoadBannerFooter } from './banner_footer/sagas';
import { BannerFooterTypes } from './banner_footer/types';
import { loadBenefitOfferDetails } from './benefits/offer/sagas';
import { BenefitOfferDetailsTypes } from './benefits/offer/types';
import { orderBenefitOffer } from './benefits/offer_order/sagas';
import { OrderBenefitOfferTypes } from './benefits/offer_order/types';
import { loadBenefitWithoutSeasonDetails } from './benefits/without_season/sagas';
import { BenefitWithoutSeasonDetailsTypes } from './benefits/without_season/types';
import { orderBenefitWithoutSeason } from './benefits/without_season_order/sagas';
import { OrderBenefitWithoutSeasonTypes } from './benefits/without_season_order/types';
import { loadBenefitWithSeason } from './benefits/with_season/sagas';
import { BenefitWithSeasonDetailsTypes } from './benefits/with_season/types';
import { orderBenefitWithSeason } from './benefits/with_season_order/sagas';
import { OrderBenefitWithSeasonTypes } from './benefits/with_season_order/types';
import { loadBenefitsStore } from './benefits_store/sagas';
import { BenefitsStoreTypes } from './benefits_store/types';
import { loadCategory, loadResetCache } from './category/sagas';
import { CategoryTypes } from './category/types';
import { loadTag } from './tag/sagas';
import { TagTypes } from './tag/types';
import { loadLandingpage } from './landingpage/sagas';
import { LandingpageTypes } from './landingpage/types';
import { getBenefitTypeDeeplink, getRewardTypeDeeplink } from './deeplink/sagas';
import { DeeplinkTypes } from './deeplink/types';
import { loadFilters } from './filters/sagas';
import { FiltersTypes } from './filters/types';
import { loadOurPartners } from './our_partners/sagas';
import { OurPartnersTypes } from './our_partners/types';
import { loadAccounts } from './participant/accounts/sagas';
import { AccountsTypes } from './participant/accounts/types';
import {
  ActivateParticipantTypes,
  loadActiveParticipant,
} from './participant/activate_participant';
import { CancelParticipantTypes, cancelPaticipation } from './participant/cancel_participant';
import {
  loadMobileAccounts,
  loadMobileAccountsAtlysAmdocs,
} from './participant/mobile_accounts/sagas';
import { MobileAccountsTypes } from './participant/mobile_accounts/types';
import { loadQrCode } from './qrcode/sagas';
import { QrCodeTypes } from './qrcode/types';
import { loadRegions } from './regions/sagas';
import { RegionsTypes } from './regions/types';
import { loadCellphoneAcessoriesDetails } from './rewards/cellphone_and_gadgets/sagas';
import { RewardCellphoneAndGadgetsDetailsTypes } from './rewards/cellphone_and_gadgets/types';
import { loadCellphoneAcessoriesOrder } from './rewards/cellphone_and_gadgets_order/sagas';
import { RewardCellphoneAndGadgetsOrderTypes } from './rewards/cellphone_and_gadgets_order/types';
import { loadRewardCinemarkDetails } from './rewards/cinemark/sagas';
import { RewardCinemarkDetailsTypes } from './rewards/cinemark/types';
import { loadRewardCinemarkOrder } from './rewards/cinemark_order/sagas';
import { RewardCinemarkOrderTypes } from './rewards/cinemark_order/types';
import { loadRewardInternetPackageDetails } from './rewards/internet_package/sagas';
import { RewardInternetPackageTypes } from './rewards/internet_package/types';
import { orderRewardInternetPackage } from './rewards/internet_package_order/sagas';
import { RewardInternetPackageOrderTypes } from './rewards/internet_package_order/types';
import { loadRewardsMonthlyFeeDiscountDetails } from './rewards/monthly_fee_discount/sagas';
import { RewardMonthlyFeeDiscountTypes } from './rewards/monthly_fee_discount/types';
import { orderRewardMonthlyFeeDiscount } from './rewards/monthly_fee_discount_order/sagas';
import { RewardMonthlyFeeDiscountOrderTypes } from './rewards/monthly_fee_discount_order/types';
import { loadRewardsOfferDetails } from './rewards/offer/sagas';
import { RewardOfferDetailsTypes } from './rewards/offer/types';
import { orderRewardOffer } from './rewards/offer_order/sagas';
import { RewardOfferOrderTypes } from './rewards/offer_order/types';
import { loadRewards } from './rewards/rewards/sagas';
import { RewardsTypes } from './rewards/rewards/types';
import { loadTravel } from './rewards/travel/sagas';
import { RewardTravelTypes } from './rewards/travel/types';
import { orderRewardTravel } from './rewards/travel_order/sagas';
import { RewardTravelOrderTypes } from './rewards/travel_order/types';
import { loadRewardWithoutSeasonDetails } from './rewards/without_season/sagas';
import { RewardWithoutSeasonDetailsTypes } from './rewards/without_season/types';
import { orderRewardsWithoutSeason } from './rewards/without_season_order/sagas';
import { OrderRewardsWithoutSeasonTypes } from './rewards/without_season_order/types';
import { loadRewardDetails } from './rewards/with_season/sagas';
import { RewardDetailsTypes } from './rewards/with_season/types';
import { orderReward } from './rewards/with_season_order/sagas';
import { OrderRewardTypes } from './rewards/with_season_order/types';
import { loadRewardsUnforgettable } from './rewards_unforgettable/sagas';
import { RewardUnfogettableTypes } from './rewards_unforgettable/types';
import { loadRewardUnforgettableBanner } from './rewards_unforgettable_banner/sagas';
import { RewardUnforgettableBannerTypes } from './rewards_unforgettable_banner/types';
import { getSearch, setSearchValueAndRedirect } from './search/sagas';
import { SearchTypes } from './search/types';
import { sendEmailVoucher } from './send_email_voucher/sagas';
import { SendEmailVoucherTypes } from './send_email_voucher/types';
import {
  getInterceptionSurvey,
  sendSurveyGiveUp,
  updateTimesShowedByCampaign,
} from './survey/interception/sagas';
import { SurveyInterceptionTypes } from './survey/interception/types';
import { getSurveyList } from './survey/list/sagas';
import { SurveyListTypes } from './survey/list/types';
import { getSurveyQuestions, saveSurvey } from './survey/survey/sagas';
import { SurveyTypes } from './survey/survey/types';
import { sendTokenSMS } from './token/store_password/sagas';
import { StorePasswordTypes } from './token/store_password/types';
import { validateTokenSMS } from './token/validate_token_sms/sagas';
import { ValidateTokenSMSTypes } from './token/validate_token_sms/types';
import { loadActiveRedemptions } from './use/active_redemptions/sagas';
import { ActiveRedemptionsTypes } from './use/active_redemptions/types';
import { cancelBenefitOffer, loadUseBenefitOfferDetails } from './use/benefits/offer/sagas';
import { UseBenefitOfferDetailsTypes } from './use/benefits/offer/types';
import {
  cancelBenefitWithoutSeason,
  loadUseBenefitWithoutSeasonDetails,
} from './use/benefits/without_season/sagas';
import { UseBenefitWithoutSeasonDetailsTypes } from './use/benefits/without_season/types';
import {
  cancelBenefitWithSeason,
  loadUseBenefitWithSeasonDetails,
} from './use/benefits/with_season/sagas';
import { UseBenefitWithSeasonDetailsTypes } from './use/benefits/with_season/types';
import { loadHistory } from './use/history/sagas';
import { HistoryTypes } from './use/history/types';
import { loadUseRewardellphoneGadgetsDetails } from './use/rewards/cellphone_gadgets/sagas';
import { loadUseRewardCellphoneGadgetsItem } from './use/rewards/cellphone_gadgets_item/sagas';
import { UseRewardCellphoneGadgetsDetailsTypes } from './use/rewards/cellphone_gadgets/types';
import { UseRewardCellphoneGadgetsItemTypes } from './use/rewards/cellphone_gadgets_item/types';
import { loadUseRewardCinemarkDetails } from './use/rewards/cinemark/sagas';
import { UseRewardCinemarkDetailsTypes } from './use/rewards/cinemark/types';
import { loadUseRewardOfferDetails } from './use/rewards/offer/sagas';
import { UseRewardOfferDetailsTypes } from './use/rewards/offer/types';
import { loadUseRewardTravelDetails } from './use/rewards/travel/sagas';
import { UseRewardTravelDetailsTypes } from './use/rewards/travel/types';
import { loadUseRewardWithoutSeasonDetails } from './use/rewards/without_season/sagas';
import { UseRewardWithoutSeasonDetailsTypes } from './use/rewards/without_season/types';
import { loadUseRewardWithSeasonDetails } from './use/rewards/with_season/sagas';
import { UseRewardWithSeasonDetailsTypes } from './use/rewards/with_season/types';
import { loadWhiteList } from './white_list/sagas';
import { WhiteListTypes } from './white_list/types';
import { loadTextParam } from './text_param/sagas';
import { TextparamTypes } from './text_param/types';
import { EvaluationTypes } from './evaluation/types';
import { loadEvaluation } from './evaluation/sagas';
import { SaveEvaluationTypes } from './evaluation_save/types';
import { saveAnswerEvaluation } from './evaluation_save/sagas';

export default function* rootSaga() {
  return yield all([
    takeLatest(AuthenticateTypes.LOAD_REQUEST, loadAuthenticate),
    takeLatest(ActivateParticipantTypes.LOAD_REQUEST, loadActiveParticipant),
    takeLatest(AccountsTypes.LOAD_REQUEST, loadAccounts),
    takeLatest(AccountsTypes.LOAD_REQUEST, loadAccounts),
    takeLatest(ActiveRedemptionsTypes.LOAD_REQUEST, loadActiveRedemptions),
    takeLatest(BannersTypes.LOAD_REQUEST, loadBanners),
    takeLatest(BannerFooterTypes.LOAD_REQUEST, getLoadBannerFooter),
    takeLatest(BadgesTypes.LOAD_REQUEST, loadBadges),
    takeLatest(BadgesInformativesTypes.LOAD_REQUEST, loadBadgesInformatives),
    takeLatest(BenefitOfferDetailsTypes.LOAD_REQUEST, loadBenefitOfferDetails),
    takeLatest(BenefitWithSeasonDetailsTypes.LOAD_REQUEST, loadBenefitWithSeason),
    takeLatest(BenefitWithoutSeasonDetailsTypes.LOAD_REQUEST, loadBenefitWithoutSeasonDetails),
    takeLatest(BenefitsStoreTypes.LOAD_REQUEST, loadBenefitsStore),
    takeLatest(CategoryTypes.LOAD_REQUEST, loadCategory),
    takeLatest(CategoryTypes.LOAD_RESET_CACHE, loadResetCache),
    takeLatest(TagTypes.LOAD_REQUEST, loadTag),
    takeLatest(LandingpageTypes.LOAD_REQUEST, loadLandingpage),
    takeLatest(CancelParticipantTypes.LOAD_REQUEST, cancelPaticipation),
    takeLatest(FiltersTypes.LOAD_REQUEST, loadFilters),
    takeLatest(HistoryTypes.LOAD_REQUEST, loadHistory),
    takeLatest(MobileAccountsTypes.LOAD_REQUEST, loadMobileAccounts),
    takeLatest(MobileAccountsTypes.LOAD_REQUEST_ATLYS_AMDOCS, loadMobileAccountsAtlysAmdocs),
    takeLatest(OrderBenefitWithSeasonTypes.LOAD_REQUEST, orderBenefitWithSeason),
    takeLatest(OrderBenefitOfferTypes.LOAD_REQUEST, orderBenefitOffer),
    takeLatest(OrderRewardTypes.LOAD_REQUEST, orderReward),
    takeLatest(OrderBenefitWithoutSeasonTypes.LOAD_REQUEST, orderBenefitWithoutSeason),
    takeLatest(OrderRewardsWithoutSeasonTypes.LOAD_REQUEST, orderRewardsWithoutSeason),
    takeLatest(OurPartnersTypes.LOAD_REQUEST, loadOurPartners),
    takeLatest(RewardUnforgettableBannerTypes.LOAD_REQUEST, loadRewardUnforgettableBanner),
    takeLatest(RewardCinemarkDetailsTypes.LOAD_REQUEST, loadRewardCinemarkDetails),
    takeLatest(RewardCinemarkOrderTypes.LOAD_REQUEST, loadRewardCinemarkOrder),
    takeLatest(RewardWithoutSeasonDetailsTypes.LOAD_REQUEST, loadRewardWithoutSeasonDetails),
    takeLatest(RewardOfferDetailsTypes.LOAD_REQUEST, loadRewardsOfferDetails),
    takeLatest(RewardOfferOrderTypes.LOAD_REQUEST, orderRewardOffer),
    takeLatest(RewardDetailsTypes.LOAD_REQUEST, loadRewardDetails),
    takeLatest(RewardsTypes.LOAD_REQUEST, loadRewards),
    takeLatest(RewardUnfogettableTypes.LOAD_REQUEST, loadRewardsUnforgettable),
    takeLatest(RegionsTypes.LOAD_REQUEST, loadRegions),
    takeLatest(RewardMonthlyFeeDiscountTypes.LOAD_REQUEST, loadRewardsMonthlyFeeDiscountDetails),
    takeLatest(RewardMonthlyFeeDiscountOrderTypes.LOAD_REQUEST, orderRewardMonthlyFeeDiscount),
    takeLatest(RewardInternetPackageTypes.LOAD_REQUEST, loadRewardInternetPackageDetails),
    takeLatest(RewardInternetPackageOrderTypes.LOAD_REQUEST, orderRewardInternetPackage),
    takeLatest(RewardTravelOrderTypes.LOAD_REQUEST, orderRewardTravel),
    takeLatest(RewardTravelTypes.LOAD_REQUEST, loadTravel),
    takeLatest(StorePasswordTypes.LOAD_REQUEST, sendTokenSMS),
    takeLatest(SendEmailVoucherTypes.LOAD_REQUEST, sendEmailVoucher),
    takeLatest(ValidateTokenSMSTypes.LOAD_REQUEST, validateTokenSMS),
    takeLatest(UseBenefitOfferDetailsTypes.LOAD_REQUEST, loadUseBenefitOfferDetails),
    takeLatest(UseBenefitOfferDetailsTypes.LOAD_CANCEL_REQUEST, cancelBenefitOffer),
    takeLatest(UseRewardOfferDetailsTypes.LOAD_REQUEST, loadUseRewardOfferDetails),
    takeLatest(
      UseRewardCellphoneGadgetsDetailsTypes.LOAD_REQUEST,
      loadUseRewardellphoneGadgetsDetails,
    ),
    takeLatest(UseRewardCellphoneGadgetsItemTypes.LOAD_REQUEST, loadUseRewardCellphoneGadgetsItem),
    takeLatest(
      UseBenefitWithoutSeasonDetailsTypes.LOAD_REQUEST,
      loadUseBenefitWithoutSeasonDetails,
    ),
    takeLatest(UseBenefitWithoutSeasonDetailsTypes.LOAD_CANCEL_REQUEST, cancelBenefitWithoutSeason),
    takeLatest(UseBenefitWithSeasonDetailsTypes.LOAD_REQUEST, loadUseBenefitWithSeasonDetails),
    takeLatest(UseBenefitWithSeasonDetailsTypes.LOAD_CANCEL_REQUEST, cancelBenefitWithSeason),
    takeLatest(UseRewardWithoutSeasonDetailsTypes.LOAD_REQUEST, loadUseRewardWithoutSeasonDetails),
    takeLatest(UseRewardWithSeasonDetailsTypes.LOAD_REQUEST, loadUseRewardWithSeasonDetails),
    takeLatest(UseRewardTravelDetailsTypes.LOAD_REQUEST, loadUseRewardTravelDetails),
    takeLatest(UseRewardCinemarkDetailsTypes.LOAD_REQUEST, loadUseRewardCinemarkDetails),
    takeLatest(RewardCellphoneAndGadgetsDetailsTypes.LOAD_REQUEST, loadCellphoneAcessoriesDetails),
    takeLatest(RewardCellphoneAndGadgetsOrderTypes.LOAD_REQUEST, loadCellphoneAcessoriesOrder),
    takeLatest(SurveyTypes.LOAD_REQUEST, getSurveyQuestions),
    takeLatest(WhiteListTypes.LOAD_REQUEST, loadWhiteList),
    takeLatest(TextparamTypes.LOAD_REQUEST, loadTextParam),
    takeLatest(QrCodeTypes.LOAD_REQUEST, loadQrCode),
    takeLatest(SurveyInterceptionTypes.LOAD_REQUEST, getInterceptionSurvey),
    takeLatest(SurveyInterceptionTypes.UPDATE_TIMES_SHOWED, updateTimesShowedByCampaign),
    takeLatest(SurveyInterceptionTypes.SEND_GIVEUP, sendSurveyGiveUp),
    takeLatest(SurveyTypes.SAVE_SURVEY, saveSurvey),
    takeLatest(SurveyListTypes.LOAD_REQUEST, getSurveyList),
    takeLatest(SearchTypes.SET_SEARCH_VALUE_AND_REDIRECT, setSearchValueAndRedirect),
    takeLatest(SearchTypes.LOAD_REQUEST, getSearch),
    takeLatest(DeeplinkTypes.LOAD_REQUEST_BENEFIT_TYPES, getBenefitTypeDeeplink),
    takeLatest(DeeplinkTypes.LOAD_REQUEST_REWARD_TYPES, getRewardTypeDeeplink),
    takeLatest(EvaluationTypes.LOAD_REQUEST, loadEvaluation),
    takeLatest(SaveEvaluationTypes.LOAD_REQUEST, saveAnswerEvaluation),
  ]);
}
