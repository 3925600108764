import { Reducer } from 'redux';
import { ISendEmailVoucherState, SendEmailVoucherTypes } from './types';

const INITIAL_STATE: ISendEmailVoucherState = {
  body: {
    Email: '',
    OrderRewardID: 0,
  },
  data: {},
  loading: false,
};

const reducer: Reducer<ISendEmailVoucherState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SendEmailVoucherTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case SendEmailVoucherTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
