import { Reducer } from 'redux';
import { IMobileAccountsState, MobileAccountsTypes } from './types';

const INITIAL_STATE: IMobileAccountsState = {
  data: [],
  loading: true,
};

const reducer: Reducer<IMobileAccountsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MobileAccountsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case MobileAccountsTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;
