import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import store from 'store';
import { setError } from 'store/ducks/error';
import { addLoading, removeLoading } from 'store/ducks/Loading/actions';
import API from '_services/_API/Api';
import Helpers from '_utils/helpers';
import { PushTagNavigationBenefitOfferError, PushTagNonInteractionError } from '_utils/TagManager';
import { loadSuccess } from './actions';



export function* saveAnswerEvaluation(action) {
  // yield put(addLoading());

  try {
    const { data } = yield call(API.post, 'v1/api/evaluation/saveAnswer', action.payload.data);
    yield put(loadSuccess(data));
    yield put(push(`/beneficios/evaluation/${data.data.orderReward}/sucesso/${data.data.answerEvaluationID}`));
    // yield put(removeLoading());
  } catch (error) {
    yield put(setError(error.response));
    // yield put(removeLoading());
  }
}
