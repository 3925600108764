import { action } from 'typesafe-actions';
import { BannerFooterTypes, IBannerFooter } from './types';

export const loadBannerFooter = () => action(BannerFooterTypes.LOAD_REQUEST);

export const loadBannerSuccess = (data: IBannerFooter) =>
  action(BannerFooterTypes.LOAD_SUCCESS, { data });

export const loadBannerFailure = () => action(BannerFooterTypes.LOAD_ERROR);

export const loadError = () => action(BannerFooterTypes.LOAD_ERROR);
