import classNames from 'classnames';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Style from './Filters.module.scss';

interface IOwnProps {
  title: string;
  filters: any[];
  selecteds: number[];
  totalSelected?: number;
  controlState: Dispatch<SetStateAction<any>>;
  idProp: string;
  multiple?: boolean;
  openable?: boolean;
}

const Filters = ({
  title,
  filters,
  controlState,
  selecteds,
  idProp,
  multiple = true,
  openable = true,
  totalSelected,
}: IOwnProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const category = title.split(' ')[0];

  return (
    <div className={Style.container}>
      <div
        className={Style.titleWrap}
        onClick={() => (openable ? setOpen(!open) : controlState([]))}
      >
        <h2
          className={classNames(Style.title, {
            [Style.hasSelecteds]: multiple ? selecteds.length > 0 : selecteds[0] > 0,
          })}
        >
          {title}
          {totalSelected !== undefined && <span className={Style.counter}>{totalSelected}</span>}
        </h2>
        {openable && (
          <span
            className={classNames(Style.arrow, {
              [Style.open]: open,
            })}
          />
        )}
      </div>
      {open && (
        <div className={Style.box}>
          {filters.map(filter => {
            return (
              <span
                id={`${category}_${filter[idProp]}`}
                key={filter[idProp]}
                onClick={() => controlState(filter)}
                className={classNames(Style.tags, {
                  [Style.active]: selecteds.find(e => filter[idProp] === +e),
                })}
              >
                {filter.name}
              </span>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Filters;
