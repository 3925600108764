import React from 'react';
import { TagHomeEnum } from '../../store/ducks/banners/types';
import Style from './TagHome.module.scss';
import Helpers from '_utils/helpers';
import Countdown from 'components/Countdown/Countdown';

export interface IOwnProps {
  tagHome: TagHomeEnum;
  endShowDate?: string;
}

const TagHome: React.FC<IOwnProps> = ({ tagHome, endShowDate }) => {
  const isExpired = endShowDate ? new Date(endShowDate).getTime() < new Date().getTime() : false

  return (
    <>
      {(tagHome === TagHomeEnum.EXPIRAEM && !isExpired && endShowDate) &&
        <div className={Style.expiraem}>
          <i className={Style.icoExpiraem} /> 
          Expira em <span className={Style.time}><Countdown targetDate={endShowDate} /></span>
          {/* {isExpired ? (
            <>
              Expirado
            </>
          ) : (
            <>
              Expira em <span className={Style.time}><Countdown targetDate={endShowDate} /></span>
            </>
          )} */}
        </div>
      }
      {tagHome === TagHomeEnum.NOVIDADE &&
        <div className={Style.novidade}>
          <i className={Style.icoNovidade} />Novidade
        </div>
      }
      {tagHome === TagHomeEnum.ULTIMASUNIDADES &&
        <div className={Style.ultimasNovidades}>
          <i className={Style.icoUltimasNovidades} />Últimas unidades
        </div>
      }
      {tagHome === TagHomeEnum.QUANTIDADELIMITADA &&
        <div className={Style.quantidadeLimitada}>
          <i className={Style.icoQuantidadeLimitada} />Quantidade limitada
        </div>
      }
      {(tagHome === TagHomeEnum.SEMDADOS) &&
        <div className={Style.validoate}>
          {/* Valido até {Helpers.formateDateBrazilizan(new Date(endShowDate))} */}
        </div>
      }
      {(tagHome === TagHomeEnum.VALIDOATE && endShowDate) &&
        <div className={Style.validoate}>
          Validade {Helpers.formateDateBrazilizan(new Date(endShowDate))}
        </div>
      }
    </>
  );
};

export default TagHome;
