import { action } from 'typesafe-actions';
import { ISurveyInterception, SurveyInterceptionTypes } from './types';

export const getInterceptionSurvey = (pageId: number, rewardID?: number) =>
  action(SurveyInterceptionTypes.LOAD_REQUEST, { pageId, rewardID });

export const loadSurvey = (data: ISurveyInterception[]) =>
  action(SurveyInterceptionTypes.LOAD_SUCCESS, { data });

export const getRouteOfnavigation = (routeOfNavigation: string) =>
  action(SurveyInterceptionTypes.GET_ROUTE_OF_NAVIGATION, { routeOfNavigation });

export const getActionReward = (actionOfReward: any) =>
  action(SurveyInterceptionTypes.GET_ACTION_OF_REWARD, { actionOfReward });

export const resetInterception = () => action(SurveyInterceptionTypes.RESET_INTERCEPTION_SURVEY);

export const setShowedSurvey = id => action(SurveyInterceptionTypes.SET_SHOWED_SURVEY, { id });

export const updateTimesShowedByCampaign = campaignID =>
  action(SurveyInterceptionTypes.UPDATE_TIMES_SHOWED, { campaignID });

export const sendSurveyGiveUp = campaignID =>
  action(SurveyInterceptionTypes.SEND_GIVEUP, { campaignID });
