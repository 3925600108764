export enum RewardTravelTypes {
  LOAD_REQUEST = '@travel/LOAD_REQUEST',
  LOAD_SUCCESS = '@travel/LOAD_SUCCESS',
  LOAD_RESET = '@travel/LOAD_RESET',
}

export interface IOrderRewardTravel {
  orderRewardID: number;
  accountID: number;
  productSystemID: number;
  startDate: Date | undefined;
  endDate: Date | undefined;
}

export interface ITravel {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  dailyRateNumber: number;
  activationDeadlineLimit: number;
  startDate: string;
  maxPurchaseDate: string;
  endPeriod: string;
  productSystemAtlysID: number;
  productSystemAmdocsID: number;
  terms: string;
  securityTokenProtected: boolean;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  orderRewardTravel: IOrderRewardTravel | undefined;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;
}

export interface IRewardTravelState {
  readonly data: ITravel;
  readonly loading: boolean;
}
