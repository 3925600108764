import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Style from './ButtonDefault.module.scss';
import { LinkTargetEnum } from '_utils/LinkTarget.enum';
import Helpers from '../../_utils/helpers';

interface IOwnProps {
  isLoading:boolean;
  label: string;
  colorButton: 'Primary' | 'Secondary' | 'Link' | 'LinkGray';
  parentClassCss?: string;
  func?: any;
  route?: string;
  linkTargetId?: LinkTargetEnum;
  target?: string;
  isDisabled?: boolean;
  isSmall?: boolean;
  isBig?: boolean;
  isFullpage?: boolean;
  isInverse?: boolean;
  directionText?: 'Left' | 'Center' | 'Right';
  removePadding?: boolean;
}

const getClassCss = (cod: string) => {
  switch (cod) {
    case 'Primary':
      return `${Style.buttonPrimary}`;
    case 'Secondary':
      return `${Style.buttonSecondary}`;
    case 'Link':
      return `${Style.buttonLink}`;
    case 'LinkGray':
      return `${Style.buttonLinkGray}`;
  }
};

const getDirectionClassCss = (direction: string) => {
  switch (direction) {
    case 'Left':
      return `${Style.directionTextLeft}`;
    case 'Center':
      return `${Style.directionTextCenter}`;
    case 'Right':
      return `${Style.directionTextRight}`;
  }
};

const ButtonDefault: React.FC<IOwnProps> = ({
  isLoading,
  label,
  func,
  colorButton,
  parentClassCss = '',
  route = '',
  isDisabled,
  linkTargetId,
  target,
  isSmall,
  isBig,
  isFullpage,
  isInverse,
  directionText,
  removePadding,
}) => {
  const nameClassCss = getClassCss(colorButton);
  const setDirectionText = directionText ? getDirectionClassCss(directionText) : `${Style.directionTextCenter}`;
  const buttonDisabled = isDisabled ? `${Style.buttonDisabled}` : ``;
  const buttonType = isSmall ? `${Style.buttonSmall}` : isBig ? `${Style.buttonBig}` : ``;
  const buttonFullpage = isFullpage ? `${Style.buttonFullpage}` : ``;
  const activeLoading = isLoading ? `${Style.buttonLoading}` : ``;
  const activeInverse = isInverse ? `${Style.buttonInverse}` : ``;
  const activeRemovePadding = removePadding ? `${Style.buttonWithoutPadding}` : ``;
  
  const isURI = linkTo => {
    return linkTo.charAt(0) === '/' ? false : true;
  };
  const isEncrypted = linkTo => {
    return linkTo.indexOf('http') < 0
  };

  let hasLink:Boolean = Boolean(route && route.length > 0);
  let isExternalLink:Boolean = hasLink ? isURI(route) : false;
  let isEncryptedLink:Boolean = isExternalLink ? isEncrypted(route) : false

  return (
    <>
      {isLoading &&
        <div className={classNames(`${Style.button}`,activeLoading, activeInverse, activeRemovePadding, buttonDisabled, buttonType, buttonFullpage, nameClassCss, setDirectionText, parentClassCss)}>
          <div className={Style.wrapIcon}>
            <i className={Style.icoLoading} />
          </div>
          {label} 
        </div>
      }
      {(!isLoading && hasLink && isExternalLink && isEncryptedLink) && 
        <a
          href={Helpers.createUrlManagerRedirect(route)}
          onClick={() => {
            if(!isDisabled) {
              func();
            }
          }}
          target="_blan"
          className={classNames(`${Style.button}`, activeInverse, activeRemovePadding, buttonDisabled, buttonType, buttonFullpage, nameClassCss, setDirectionText, parentClassCss)}
        >
          {label} 
        </a> 
      }

      {(!isLoading && hasLink && isExternalLink && !isEncryptedLink) && 
        <>
          {target &&
            <>
              <a
                href={route}
                onClick={() => {
                  if(!isDisabled) {
                    func();
                  }
                }}
                target="_blan"
                className={classNames(`${Style.button}`, activeInverse, activeRemovePadding, buttonDisabled, buttonType, buttonFullpage, nameClassCss, setDirectionText, parentClassCss)}
              >
                {label}
              </a> 
            </>
          }
          {!target &&
            <>
              <a
                href={route}
                className={classNames(`${Style.button}`, 
                  activeInverse, 
                  activeRemovePadding, 
                  buttonDisabled, 
                  buttonType, 
                  buttonFullpage, 
                  nameClassCss, 
                  setDirectionText, 
                  parentClassCss
                )}
              >
                {label}
              </a>
            </>
          }
        </>
      }

      {(!isLoading && hasLink && !isExternalLink) &&
        <Link
          onClick={func}
          to={isDisabled ? '#' : route}
          className={classNames(`${Style.button}`, activeInverse, activeRemovePadding, buttonDisabled, buttonType, buttonFullpage, nameClassCss, setDirectionText, parentClassCss)}
        >
          {label}
        </Link>
      }

      {(!isLoading && !hasLink) &&
        <div
          onClick={() => {
            if(!isDisabled) {
              func();
            }
          }}
          className={classNames(`${Style.button}`, activeInverse, activeRemovePadding, buttonDisabled, buttonType, buttonFullpage, nameClassCss, setDirectionText, parentClassCss)}
        >
          {label}
        </div>
      }
    </>
  )
};

export default ButtonDefault;
