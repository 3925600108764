// Action types
export enum StorePasswordTypes {
  LOAD_REQUEST = '@store_password/LOAD_REQUEST',
  LOAD_SUCCESS = '@store_password/LOAD_SUCCESS',
}

// Data types
export interface IStorePasswordReq {
  AreaCode: string;
  LineNumber: string;
  SecurityTokenType: number;
}

export interface IStorePasswordRes {
  success: boolean;
  loading: boolean;
}
// State type
export interface IStorePasswordState {
  readonly req: IStorePasswordReq;
  readonly res: IStorePasswordRes;
  readonly loading: boolean;
}
