import { Reducer } from 'redux';
import { IOrderBenefitWithoutSeasonState, OrderBenefitWithoutSeasonTypes } from './types';

const INITIAL_STATE: IOrderBenefitWithoutSeasonState = {
  req: {
    RewardID: 0,
    TicketsItemID: 0,
    StateId: 0,
    ActivationChannel: '',
  },
  data: {
    orderRewardID: 0,
    name: '',
    location: '',
    date: '',
    qrCode: '',
    qrCodeUrlImage: '',
    howToUse: '',
    ticketItemAmount: 0,
    isSponsored: false,
    ticketPickUpPlace: '',
    rewardID: 0,
    locationID: 0,
    enabledCTA: false,
    partnerID: 0,
    defaultText: '',
    ownTerms: '',
    terms: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload480: '',
    urlDownload690: '',
    validateDate: '',
    tagHome: 0,
    averageNote: 0,
    enableAverage: false,
    timesClicked: 0,
    initialCount: 0,
    showCount: false,
    descriptionBenefit: '',
    termsOfUseBenefit: '',
    descriptionRedeeem: '',
    useBenefit: '',
    ready: '',
    evaluationDescription: '',
    alertConfirmRedemption: '',
    aboutEvent: '',
    addressLocation: '',
  },
  loading: false,
};

const reducer: Reducer<IOrderBenefitWithoutSeasonState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderBenefitWithoutSeasonTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case OrderBenefitWithoutSeasonTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
