import { call, put } from 'redux-saga/effects';
import API from '_services/_API/Api';
import Helpers from '_utils/helpers';
import { loadSuccessBenefitTypes, loadSuccessRewardTypes } from './actions';
import { setError } from 'store/ducks/error';

export function* getBenefitTypeDeeplink(action) {
  try {
    const { data } = yield call(
      API.get,
      `v1/api/benefits/getTypeBenefits/${action.payload.benefitId}`,
    );
  
    yield put(loadSuccessBenefitTypes(data.data));
  
    Helpers.sendEndLoading();
  } catch (error) {
    yield put(setError(error.response, true));
  }
}

export function* getRewardTypeDeeplink(action) {
  try {
    const { data } = yield call(
      API.get,
      `v1/api/benefits/getTypeBenefits/${action.payload.rewardId}`,
    );
  
    yield put(loadSuccessRewardTypes(data.data));
  
    Helpers.sendEndLoading();
  } catch (error) {
    yield put(setError(error.response, true));
  }
}
