import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import Navbar from 'components/Navbar/Navbar';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { ApplicationState } from 'store';
import * as BenefitsStoreActions from 'store/ducks/benefits_store/actions';
import Style from './HeaderFixed.module.scss';

type props = PropsWithChildren<any>;

const HeaderFixed: React.FC<props> = ({}) => {
  return (
    <div className={Style.wrapHeader}>      
      <header 
        id="header-beneficios-loja" 
        className={`
          ${Style.headerBeneficios}
        `}>
        <Navbar />
      </header>
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isHeaderFixed: state.benefitsStore.isHeaderFixed,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setHeaderFixed: BenefitsStoreActions.setHeaderFixed,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderFixed));