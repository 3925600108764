import { Reducer } from 'redux';
import { LandingpageTypes, ILandingpageState } from './types';

const INITIAL_STATE: ILandingpageState = {
  data: {
    landingPageID: 0,
    title: '',
    message: '',
    rule: '',
    linkTypeEnum: 0,
    titleButton: '',
    linkButton: '',
    contentImageId: '',
    startDate: '',
    endDate: '',
    createUser: '',
    createdDate: '',
    updatedDate: '',
    current: false,
    urlDownload210: "",
    urlDownload384: "",
    urlDownload480: "",
    urlDownload690: "",
  },
  loading: true,
  success: false,
  error: false,
};

const reducer: Reducer<ILandingpageState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LandingpageTypes.LOAD_REQUEST:
      return { ...state, 
        loading: true, 
        success: false,
        error: false,
        data: {
          landingPageID: 0,
          title: '',
          message: '',
          rule: '',
          linkTypeEnum: 0,
          titleButton: '',
          linkButton: '',
          contentImageId: '',
          startDate: '',
          endDate: '',
          createUser: '',
          createdDate: '',
          updatedDate: '',
          current: false,
          urlDownload210: "",
          urlDownload384: "",
          urlDownload480: "",
          urlDownload690: "",
        },
      };
    case LandingpageTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        data: action.payload.data,
      };
    case LandingpageTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        data: {
          landingPageID: 0,
          title: '',
          message: '',
          rule: '',
          linkTypeEnum: 0,
          titleButton: '',
          linkButton: '',
          contentImageId: '',
          startDate: '',
          endDate: '',
          createUser: '',
          createdDate: '',
          updatedDate: '',
          current: false,
          urlDownload210: "",
          urlDownload384: "",
          urlDownload480: "",
          urlDownload690: "",
        },
      };
    case LandingpageTypes.LOAD_RESET:
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
      };
    default:
      return state;
  }
};

export default reducer;
