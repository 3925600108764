import React from 'react';
import { Link } from 'react-router-dom';
import LinkToPartner from 'components/LinkToPartner/LinkToPartner';
import InterectionBox from 'components/interectionBox/interectionBox';
import { IBanners, TagHomeEnum } from '../../store/ducks/banners/types';
import Style from './Banner.module.scss';

export interface IOwnProps {
  banner: IBanners;
  pushInteraction(): void;
}

const Child: React.FC<IOwnProps> = ({ banner, pushInteraction }) => {
  const { 
    urlDownload137, 
    name, 
    caption, 
    tag, 
    endShowDate, 
    showCount, // Exibir contador
    initialCount, // Valor inicial da contagem
    timesClicked, // Número de visualizações
    enableAverage,
    averageNote,
  } = banner;
  const tagHome: TagHomeEnum = banner.tagHome;

  return (
    <div onClick={pushInteraction}>
      <div className={Style.card}>
        <div className={Style.cardContainer}>
          {tag && (
            <div className={Style.wrapTag}>
              <div className={Style.tag}>{tag}</div>
            </div>
          )}

          <span className={Style.title}>{name}</span>
          <p className={Style.description}>{caption}</p>
        </div>
        <div className={Style.WrapImg}>
          <img
            className={Style.image}
            src={urlDownload137}
            alt={caption}
          />
        </div>
      </div>
        <InterectionBox 
          tagHome={tagHome}
          enableAverage={enableAverage}
          showCount={showCount}
          timesClicked={timesClicked}
          initialCount={initialCount}
          endShowDate={endShowDate}
          averageNote={averageNote}
          isBannerDestaque={true}
          margin={'4px 0px 16px 0px'}
        />
    </div>
  );
};

const Banner: React.FC<IOwnProps> = ({ banner, pushInteraction }) => {
  const { link, anotherPage, bannerID } = banner;
  return anotherPage ? (
    <div className={Style.link} key={bannerID}>
      <LinkToPartner link={link}>
        <Child pushInteraction={pushInteraction} banner={banner} />
      </LinkToPartner>
    </div>
  ) : (
    <div className={Style.link} key={bannerID}>
      <Link to={`${link}`}>
        <Child pushInteraction={pushInteraction} banner={banner} />
      </Link>
    </div>
  );
};

export default Banner;