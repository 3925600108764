export enum BenefitsStoreTypes {
  LOAD_REQUEST = '@benefits_store/LOAD_REQUEST',
  LOAD_SUCCESS = '@benefits_store/LOAD_SUCCESS',
  LOAD_FAILURE = '@benefits_store/LOAD_FAILURE',
  LOAD_RESET = '@benefits_store/LOAD_RESET',
  SET_SELECTED = '@benefits_store/SET_SELECTED',
  SET_TAG_SELECTED = '@benefits_store/SET_TAG_SELECTED',
  SET_HEADER_FIXED = '@benefits_store/SET_HEADER_FIXED',
  CLEAR_DATA = '@benefits_store/CLEAR_DATA',
  SET_STATEID_SELECTED = '@benefits_store/SET_STATEID_SELECTED',
  SET_PAGINATION = '@benefits_store/SET_PAGINATION',
  LAST_POSITION_CARD = '@benefits_store/LAST_POSITION_CARD',
}

export enum TagHomeEnum {
  SEMDADOS = 0,
  NOVIDADE = 1,
  EXPIRAEM = 2,
  ULTIMASUNIDADES = 3,
  QUANTIDADELIMITADA = 4,
  VALIDOATE = 5,
}

export interface IBenefitsStoreItem {
  benefitID: number;
  tag: string;
  title: string;
  startDate?: string;
  endDate?: string;
  link: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  situation: number;
  partnerID: number;
  locationID: number;
  mainCategoryID: number;
  startShowDate: string;
  endShowDate: string;
  tagHome: TagHomeEnum.SEMDADOS;
  showCount: boolean;
  initialCount: number;
  timesClicked: number;
  enableAverage: boolean;
  averageNote: number;
  date: string;
}

export interface IBenefitsStoreSpecial {
  benefits: IBenefitsStoreItem[];
  categoryID: number;
  categoryName: string;
  iconUrl: string;
}

export interface IBenefitsStore {
  data: IBenefitsStoreItem[];
  specials: IBenefitsStoreSpecial[];
  numberPage: number;
  totalRows: number;
  pageRows: number;
}

export interface IBenefitsStoreState {
  data: IBenefitsStore;
  selected: number;
  tagSelected: number;
  stateIdSelected: number;
  currentPagination: number;
  loading: boolean;
  error: boolean;
  success: boolean;
  lastPositionCard: number;
  isHeaderFixed: boolean;
}
