import { Reducer } from 'redux';
import Helpers from '../../../_utils/helpers';
import { ISearchState, SearchLabelType, SearchTypes } from './types';

const INITIAL_STATE: ISearchState = {
  data: {
    items: [],
    numberPage: 0,
    totalRows: 0,
    pageRows: 0,
  },
  filter: {
    stateId: 0,
    partnerName: '',
  },
  isEmpty: false,
  emptyValue: '',
  loading: false,
  label: SearchLabelType.LABEL_SEARCH_COMMON,
};

const reducer: Reducer<ISearchState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SearchTypes.SET_SEARCH_VALUE:
      return {
        ...state,
        filter: {
          ...state.filter,
          partnerName: action.payload.value,
        },
      };
    case SearchTypes.SET_CATEGORY_ID:
      return {
        ...state,
        filter: {
          ...state.filter,
          categoryId: action.payload.categoryId,
        },
      };
    case SearchTypes.SET_REGION_ID:
      return {
        ...state,
        filter: {
          ...state.filter,
          stateId: action.payload.regionId,
        },
      };
    case SearchTypes.LOAD_REQUEST:
      return {
        ...state,
        data: {
          ...state.data,
          items: [],
        },
        emptyValue: '',
        loading: true,
      };
    case SearchTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.data,
          items: action.payload.data.data,
        },
        isEmpty: action.payload.data.data.length === 0,
        emptyValue: action.payload.data.data.length === 0 ? state.filter.partnerName : '',
        loading: false,
      };
    case SearchTypes.SET_LABEL_SEARCH_BENEFITS:
      return {
        ...state,
        label: SearchLabelType.LABEL_SEARCH_BENEFITS,
      };
    case SearchTypes.SET_LABEL_SEARCH_COMMON:
      return {
        ...state,
        label: SearchLabelType.LABEL_SEARCH_COMMON,
      };
    case SearchTypes.RESET_SEARCH_FILTER_ID:
      return {
        ...state,
        filter: {
          ...state.filter,
          stateId: Helpers.getStateIdParticipant(),
        },
      };
    case SearchTypes.LOAD_ERROR:
      return {
        ...state,
        data: {
          ...INITIAL_STATE.data,
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
