import BannerLargeFooter from 'components/BannerLargeFooter/BannerLargeFooter';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ApplicationState } from 'store';
import { IBannerFooter } from 'store/ducks/banner_footer/types';
import * as HomePositionAction from 'store/ducks/home_position/actions';
import { bindActionCreators, Dispatch } from 'redux';
import {
  ComponentType,
  InteractionEvent,
  InteractionType,
  pushInteractionExitUrl,
} from '_utils/TagManager';
interface IStateToProps {
  bannerFooter: IBannerFooter;
  loading: boolean;
  isBannerEmpty: boolean;
}
interface IDispatchToProps {
  setLastPositionItemHome(lastPositionItemHome: string): void;
}


// type props = IStateToProps;
type props = IStateToProps & IDispatchToProps;

const BannerFooter: React.FC<props> = ({ 
  loading, 
  bannerFooter, 
  isBannerEmpty, 
  setLastPositionItemHome, 
}) => {
  const {
    title,
    description,
    link,
    linkTitle,
    urlDownload210,
    urlDownload384,
    urlDownload480,
    urlDownload690,
    urlDownload430,
  } = bannerFooter;

  const pushInteraction = (link: string) => () => {
    let exitLink = ''

    if (link && link.indexOf('http') >= 0) {
      exitLink = link
    }

    pushInteractionExitUrl(
      ComponentType.PARCEIROS,
      InteractionType.CLICK_BUTTON,
      `cinemark:saiba-mais`,
      exitLink
    );
    setLastPositionItemHome('cinemark-banner')
  };

  return loading || isBannerEmpty ? null : (
    <BannerLargeFooter
      title={title}
      description={description}
      link={link}
      linkTitle={linkTitle}
      urlDownload210={urlDownload210}
      urlDownload384={urlDownload384}
      urlDownload480={urlDownload480}
      urlDownload690={urlDownload690}
      urlDownload430={urlDownload430}
      pushInteraction={pushInteraction(link)}
    />
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  bannerFooter: state.bannerFooter.data,
  loading: state.bannerFooter.loading,
  isBannerEmpty: state.bannerFooter.isBannerEmpty,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setLastPositionItemHome: HomePositionAction.setLastPositionItemHome,
    },
    dispatch,
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BannerFooter));