import Search from 'containers/Search/Search';
import React, { useEffect, useRef } from 'react';
import ScrollPage from '../../_utils/ScrollPage';
import { useLastLocation } from 'react-router-last-location';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { RoutesPath } from 'routes/RoutesPath';
import { ApplicationState } from 'store';
import * as BenefitsStoreActions from 'store/ducks/benefits_store/actions';
import * as CategoryActions from 'store/ducks/category/actions';
import { ICategory } from 'store/ducks/category/types';
import * as SearchActions from 'store/ducks/search/actions';
import Helpers from '_utils/helpers';
import { ComponentType, InteractionType, PushTagInteraction, normalizeTitle, } from '_utils/TagManager';
import CategoryButton from '../../components/CategoryButton/CategoryButton';
import Style from './CategoryMenu.module.scss';
import * as HomePositionAction from 'store/ducks/home_position/actions';
import TitleSectionHome from '../../components/TitleSectionHome/TitleSection'

interface IStateToProps {
  categories: ICategory[];
  categoryMenuTitle: string;
}

interface IDispatchToProps {
  getCategories(stateID: number): void;
  setSelected(category: number): void;
  setTagSelected(category: number): void;
  setRegionSelected(regionId: number): void;
  setCategorySelected(categoryId: number): void;
  loadReset(): void;
  setLastPositionItemHome(lastPositionItemHome: string): void;
}

type props = IStateToProps & IDispatchToProps & RouteComponentProps;

const CategoryMenu: React.FC<props> = ({
  categories,
  getCategories,
  setRegionSelected,
  setCategorySelected,
  setSelected,
  setTagSelected,
  history,
  loadReset,
  categoryMenuTitle,
  setLastPositionItemHome,
}) => {

  const indexWordSelected = 'partners'
  const section = useRef<HTMLElement>(null);
  const lastLocation = useLastLocation();

  useEffect(() => {
    setRegionSelected(Helpers.getStateIdParticipant());
    setCategorySelected(0);
    getCategories(Helpers.getStateIdParticipant());

    return () => loadReset();
  }, []);

  const pushInteraction = (title: string) => () =>
    PushTagInteraction(ComponentType.PARCEIROS, InteractionType.CLICK_TEXT, title,);

  const handleOnClick = (category: ICategory) => {
    setSelected(category.categoryID);
    setTagSelected(0)

    PushTagInteraction(
      ComponentType.HOME_OUTROS_RESGATES,
      InteractionType.CLICK_BUTTON,
      normalizeTitle(category.name),
    );

    setLastPositionItemHome('category-menu')

    history.push(`/beneficios/loja/${category.categoryID}`);
  };

  useEffect(() => {
    if (lastLocation && lastLocation.pathname.indexOf('/beneficios/loja') >= 0) {
      ScrollPage.scrollBottom(ScrollPage.getPositionList(indexWordSelected, 1, 0))
    }
  },[categories]);

  return (
    <section id="category-menu" ref={section} className={Style.menu}>
      <TitleSectionHome title={categoryMenuTitle} />
      <div className={Style.search}>
        <Search showFilter={false} />
      </div>
      <div className={Style.categories}>
        {categories.map((category, index) => (
          <CategoryButton onClick={() => handleOnClick(category)} category={category} key={index} />
        ))}
      </div>
    </section>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getCategories: CategoryActions.getCategories,
      loadReset: CategoryActions.loadReset,
      setSelected: BenefitsStoreActions.setSelected,
      setTagSelected: BenefitsStoreActions.setTagSelected,
      setRegionSelected: SearchActions.setRegionSelected,
      setCategorySelected: SearchActions.setCategorySelected,
      setLastPositionItemHome: HomePositionAction.setLastPositionItemHome,
    },
    dispatch,
  );

const mapStateToProps = (state: ApplicationState) => ({
  categories: state.category.data.categories,
  categoryMenuTitle: state.textParam.data.categoryMenuTitle
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryMenu));
