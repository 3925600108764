enum Breakpoint {
  screenXs = 320,
  screenSm = 480,
  screenMd = 777,
  screenLg = 1200,
  screenXl = 1366,
  screenXxl = 1600,
}

export default Breakpoint;
