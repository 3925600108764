import Axios from 'axios';
import { push } from 'connected-react-router';
import CPF from 'cpf';
import { call, put, select } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Authenticate from '_models/authenticate/authenticate';
import ErrorHandler from '_models/error-handler/ErrorHandler';
import Session from '_models/Session/Session';
import { AppEnvironment } from '_utils/AppEnviroment';
import { StatusParticipant } from '_utils/StatusParticipant';
import { getStateAuthenticate } from '../../selectors';
import Helpers from '../../../_utils/helpers';
import {
  loadSuccess,
  participantLogged,
  participantLogout,
  resetAuthenticate,
  validToken,
} from './actions';
import config from './config.json';
import { IAppEnvironment, IAuthenticateState, IPayloadActionAuthenticate } from './types';
import { datadogRum } from '@datadog/browser-rum';

const authenticate = new Authenticate();

const urlEndpointHubAPI = `${process.env.REACT_APP_URL_HUB_API}`;
const isEnvironmentDevelopment = () => process.env.NODE_ENV === 'development';

const headers = (cpf: string, appEnviroment: IAppEnvironment): object => {
  const session = Session.getSession(cpf);

  const fingerprint = btoa(
    JSON.stringify([
      {
        key: 'user_agent',
        value: navigator.userAgent,
      },
    ]),
  );

  const headerDefault = {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_HUB_KEY}`,
    '5685fceffbc17265d': btoa(cpf),
    '13e5rge4ru8kf46e4': config['13e5rge4ru8kf46e4'],
    '9y8o79q5s4f5ef4b6': fingerprint,
    '64er5tertgeewhgj3': config['64er5tertgeewhgj3'],
    t1jgerx38hrxdpyp1: session ? btoa(session.idParticipant.toString()) : '',
    fr8eol4dvotertgee: session ? btoa(session.stateId.toString()) : '',
    '3eyetg64jaw6l3apo': session ? btoa(session.state.toString()) : '',
    '19fdfbg234376gjd7': Helpers.getPlatform(),
    '65djiwu289283jka8': authenticate.getJWE(),
  };

  const headerOauthAuthentication = {
    ...headerDefault,
    '8wdkweuy282jsk5q4': process.env.REACT_APP_VIVO_PUBLICKEY_OAUTH,
    '5de5wyu5f4x2s9817': AppEnvironment.FIXO,
  };

  const headerEnviroment = appEnviroment.isOauthAuthentication
    ? headerOauthAuthentication
    : headerDefault;

  return {
    headers: {
      ...headerEnviroment,
    },
  };
};

const isParticipantValid = (cpf: string = ''): boolean => {
  if (authenticate.isExistToken()) {
    const { Identity, Situation } = authenticate.getTokenDecode();
    const status = Number(Situation);

    if (cpf) {
      return (
        authenticate.isTokenExpired() && Identity === cpf && status === StatusParticipant.ACTIVE
      );
    } else {
      const sessionCPFEqualTokenCPF = Session.getSession(Identity) !== undefined ? true : false;
      return (
        authenticate.isTokenExpired() &&
        sessionCPFEqualTokenCPF &&
        status === StatusParticipant.ACTIVE
      );
    }
  }

  return false;
};

async function callAutenticaVivo() {
  const conf: any = {
    withCredentials: true,
    crossDomain: true,
    responseType: 'json',
  };

  const response = await Axios.get(`${process.env.REACT_APP_AUTENTICA}`, conf);
  return response.data.CPF;
}

function* getCPFParticipant() {
  try {
    const CPF = yield callAutenticaVivo();
    return CPF;
  } catch (error) {
    const response = {
      status: 401,
      data: {
        status: 401,
        success: false,
        errorMessages: [
          {
            description: 'Erro ao validar o OAM_ID através do serviço ValidaAuthN',
          },
        ],
      },
    };

    // Session.clearSession();
    yield put(setError(error.response || response));
  }
}

function* callRequest(action: IPayloadActionAuthenticate, cpfParticipant: string = '') {
  const body = { targetId: 5, dest: '' };

  const auth: IAuthenticateState = yield select(getStateAuthenticate);
  const header = headers(cpfParticipant, auth.environment);
  const mock =
    process.env.REACT_APP_ENVIRONMENT !== 'PRD' && !auth.environment.isOauthAuthentication
      ? '/mock'
      : '';

  try {
    const response = yield call(
      Axios.post,
      `${urlEndpointHubAPI}/v1/api/oamauthentication${mock}`,
      body,
      header,
    );
    const { data } = response;

    authenticate.auth = data.data;

    const cpf: string | any = cpfParticipant || authenticate.getTokenDecode().Identity;

    localStorage.setItem('oauth', auth.environment.isOauthAuthentication ? '1' : '0');

    Session.setSession({ token: data.data, cpf });

    datadogRum.setUser({
      id: authenticate.getTokenDecode().ParticipantId,
      name: authenticate.getTokenDecode().Name,
      email: authenticate.getTokenDecode().Email,
    });

    yield put(participantLogged());
    yield put(loadSuccess(data.data));
  } catch (error) {
    console.log(`*****************************************************************`)
    console.log(`*****************************************************************`)
    console.log(`ENTROU NO ERRO DA CHAMADA DA API v1/api/oamauthentication`)
    console.log(error)
    console.log(`*****************************************************************`)
    console.log(`*****************************************************************`)


    yield put(setError(error.response));
    ErrorHandler.exception(error.response, action.payload.oauth);

    // Session.clearSession();
    // yield put(resetAuthenticate());
    yield put(participantLogout());
  }
}

export function* callOAM(action: IPayloadActionAuthenticate) {
  const cpfParticipant = isEnvironmentDevelopment()
    ? process.env.REACT_APP_CPF
    : yield getCPFParticipant();

  const isCPFValid: boolean = CPF.isValid(cpfParticipant);
  const isParticipantWithTokenInvalid: boolean = isCPFValid && !isParticipantValid(cpfParticipant);

  if (isParticipantWithTokenInvalid) {
    yield callRequest(action, cpfParticipant);
  } else {
    yield put(validToken());
    yield put(participantLogged());
  }
}

export function* call4P(action: IPayloadActionAuthenticate) {
  const isParticipantWithTokenInvalid: boolean = !isParticipantValid();

  if (isParticipantWithTokenInvalid) {
    yield callRequest(action);
  } else {
    yield put(validToken());
    yield put(participantLogged());
  }
}

export function* loadAuthenticate(action) {
  const auth: IAuthenticateState = yield select(getStateAuthenticate);
  const is4P = auth.environment.isOauthAuthentication;

  if (is4P) {
    yield call4P(action);
  } else {
    yield callOAM(action);
  }
}
