import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadLandingpage(action) {
  try {
    let landingPageID = action.payload.landingPageID || 0

    const { data } = yield call(API.get, `v1/api/LandingPage/${landingPageID}`);

    yield put(loadSuccess(data.data));
  } catch (error) {
    console.log(error);
    yield put(setError(error.response));
  }
}