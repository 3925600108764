import { action } from 'typesafe-actions';
import { IRewardCellphoneAndGadgetsDetails, RewardCellphoneAndGadgetsDetailsTypes } from './types';

export const getCellphoneAndGadgetsDetails = (id: number, isDeeplink: boolean) =>
  action(RewardCellphoneAndGadgetsDetailsTypes.LOAD_REQUEST, { id, isDeeplink });

export const loadSuccess = (data: IRewardCellphoneAndGadgetsDetails) =>
  action(RewardCellphoneAndGadgetsDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardCellphoneAndGadgetsDetailsTypes.LOAD_RESET);
