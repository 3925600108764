export enum RewardMonthlyFeeDiscountOrderTypes {
  LOAD_REQUEST = '@reward_montly_fee_discount_order/LOAD_REQUEST',
  LOAD_SUCCESS = '@reward_montly_fee_discount_order/LOAD_SUCCESS',
}

export interface IRewardMonthlyFeeDiscountOrderRequest {
  rewardId: number;
  accountID: number;
  productSystemID: number;
  activationChannel: string;
}

export interface IRewardMonthlyFeeDiscountOrderResponse {
  orderRewardID: number;
  tag: string;
  name: string;
  rewardID: number;
  partnerID: number;
}

export interface IRewardMonthlyFeeDiscountOrderState {
  readonly data: IRewardMonthlyFeeDiscountOrderResponse;
  readonly loading: boolean;
}
