import { LinkTargetEnum } from '_utils/LinkTarget.enum';

export enum RewardUnfogettableTypes {
  LOAD_REQUEST = '@rewards_unforgettable/LOAD_REQUEST',
  LOAD_SUCCESS = '@rewards_unforgettable/LOAD_SUCCESS',
  LOAD_ERROR = '@rewards_unforgettable/LOAD_ERROR',
}

export interface IRewardUnfogettable {
  unforgettableRedeemId: number;
  image: string;
  title: string;
  description: string;
  link: string;
  linkTitle: string;
  unforgettableRedeemTypeLinkId?: LinkTargetEnum;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  urlDownload430: string;
}

export interface IRewardUnfogettableState {
  readonly data: IRewardUnfogettable;
  readonly loading: boolean;
  readonly error: boolean;
}
