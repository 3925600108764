import { call, put } from 'redux-saga/effects';
import { setError } from '../error';
import API from './../../../_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadRegions() {
  try {
    const { data } = yield call(API.get, 'v1/api/region/states');
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response.data));
  }
}
