export enum OrderRewardTypes {
  LOAD_REQUEST = '@orderRewards/LOAD_REQUEST',
  LOAD_SUCCESS = '@orderRewards/LOAD_SUCCESS',
}

export interface IOrderRewardRequest {
  RewardID: string;
  SessionID: string;
  SessionTicketID: string;
  StateId: string;
  ActivationChannel: string;
  IsReadConfirm: boolean;
}

export interface IOrderRewardResponse {
  orderRewardID: number;
  ticketItemAmount: number;
  name: string;
  location: string;
  ticketPickUpPlace: string;
  date: string;
  qrCode: string;
  qrCodeUrlImage: string;
  isSponsored: boolean;
  sessionName: string;
  partnerID: number;
  locationID: number;
  
  time: string;
  // howtoUse: string;
  howToUse: string;

  rewardID: number;
  description: string;
  ownTerms: boolean;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  descriptionBenefit: string;
  termsOfUseBenefit: string;
  descriptionRedeeem: string;
  useBenefit: string;
  ready: string;
  evaluationDescription: string;
  defaultText: string;
  alertConfirmRedemption: string;
  aboutEvent: string;
  termsOfUseSponsorship: string;
  addressLocation: string;


}

export interface IOrderRewardState {
  readonly req: IOrderRewardRequest;
  readonly data: IOrderRewardResponse;
  readonly loading: boolean;
}
