import { push } from 'connected-react-router';
import { call, put } from 'redux-saga/effects';
import store from 'store';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import Helpers from '_utils/helpers';
import {
  PushTagNavigationRewardError,
  PushTagNonInteractionError,
  RewardCategory,
} from '_utils/TagManager';
import { addLoading, removeLoading } from '../../Loading/actions';
import { loadSuccess } from './actions';

export function* orderRewardMonthlyFeeDiscount(action) {
  yield put(addLoading());

  try {
    const { data } = yield call(
      Api.post,
      'v1/api/reward/order/monthlyfeediscount',
      action.payload.data,
    );
    Helpers.saveSuccessRewardBenefits(data.data);
    yield put(loadSuccess(data.data));
    yield put(push('sucesso'));
  } catch (error) {
    const isExistDescription = error.response.data.errorMessages.length > 0;

    PushTagNavigationRewardError(
      store.getState().rewardMonthlyFeeDiscountDetails.data.title,
      RewardCategory.DESCONTO_MENSALIDADE,
    );
    if (isExistDescription) {
      PushTagNonInteractionError(error.response.data.errorMessages[0].description);
    }

    yield put(removeLoading());
    yield put(setError(error.response));
  }
}
