import { call, put } from 'redux-saga/effects';
import API from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* validateTokenSMS(action) {
  try {
    const { data } = yield call(
      API.get,
      `v1/api/security/token/validate/sms/password2fa/${action.payload.token}`,
      action.payload.data,
    );

    yield put(loadSuccess(data));
  } catch (error) {
    yield put(loadSuccess(error.response.data));
  }
}
