import { Reducer } from 'redux';
import { IRewardInternetPackageOrderState, RewardInternetPackageOrderTypes } from './types';

const INITIAL_STATE: IRewardInternetPackageOrderState = {
  data: {
    tag: '',
    name: '',
    periodValidate: 0,
    rewardID: 0,
    partnerID: 0,

    descriptionBenefit: '',
    descriptionRedeeem: '',
    evaluationDescription: '',
    locationID: 0,
    ready: '',
    termsOfUseBenefit: '',
    useBenefit: '',
    defaultText: '',
    orderRewardID: 0,
    orderStatus: 0,
    ownTerms: false,
    terms: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
  },
  loading: false,
};

const reducer: Reducer<IRewardInternetPackageOrderState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardInternetPackageOrderTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardInternetPackageOrderTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
