export enum UseRewardTravelDetailsTypes {
  LOAD_REQUEST = '@use_reward_travel_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@use_reward_travel_details/LOAD_SUCCESS',
}

export interface IUseRewardTravelDetails {
  orderRewardID: number;
  rewardID: number;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  startDate: string;
  endDate: string;
  tag: string;
  title: string;
  terms: string;
  description: string;
  expired: boolean;
}

export interface IUseRewardTravelDetailsState {
  readonly data: IUseRewardTravelDetails;
  readonly loading: boolean;
}
