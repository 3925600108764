import { call, put } from 'redux-saga/effects';
import { setError } from '../error';
import API from './../../../_services/_API/Api';
import { loadError, loadSuccess } from './actions';

export function* loadRewardsUnforgettable() {
  try {
    const response = yield call(API.get, 'v1/api/unforgettableredeem/all');
    yield put(loadSuccess(response.data.data));
  } catch (error) {
    yield put(setError(error.response));
    yield put(loadError());
  }
}
