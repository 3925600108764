import { call, put } from 'redux-saga/effects';
import API from '_services/_API/Api';
import { setError } from '../../error';
import { loadSuccess } from './actions';

export function* loadMobileAccounts(action) {
  try {
    const params = action.payload.platform ? `?platform=${action.payload.platform}` : '';
    const { data } = yield call(API.get, `v1/api/participant/accounts/mobile${params}`);
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response.data));
  }
}

export function* loadMobileAccountsAtlysAmdocs(action) {
  try {
    const params = action.payload.addParams ? action.payload.addParams : '';
    const { data } = yield call(
      API.get,
      'v1/api/participant/accounts/mobile?billingSource=ATLYS&billingSource=AMDOCS' + params,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response.data));
  }
}
