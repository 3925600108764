import { Reducer } from 'redux';
import { BenefitOfferDetailsTypes, IBenefitOfferDetailsState } from './types';

const INITIAL_STATE: IBenefitOfferDetailsState = {
  data: {
    rewardID: 0,
    name: '',
    validDate: '',
    tag: '',
    title: '',
    description: '',
    endDate: '',
    terms: '',
    urlDownload210: '',
    urlDownload384: '',
    urlDownload480: '',
    urlDownload690: '',
    category: [],
    securityTokenProtected: false,
    success: false,
    errorMessages: false,
    btnRescue: '',
    partnerId: 0,
    alertMessage: '',
    descriptionBenefit: '',
    termsOfUseBenefit: '',
    descriptionRedeeem: '',
    useBenefit: '',
    ready: '',
    evaluationDescription: '',
    averageNote: 0,
    enableAverage: false,
    initialCount: 0,
    showCount: false,
    tagHome: 0,
    timesClicked: 0,
  },
  loading: true,
};

const reducer: Reducer<IBenefitOfferDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BenefitOfferDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case BenefitOfferDetailsTypes.LOAD_SUCCESS:
      return { loading: false, data: action.payload.data };
    case BenefitOfferDetailsTypes.LOAD_RESET:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default reducer;
