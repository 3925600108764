import { action } from 'typesafe-actions';
import { IMobileAccounts, MobileAccountsTypes } from './types';

export const getMobileAccounts = (platform?: string) =>
  action(MobileAccountsTypes.LOAD_REQUEST, { platform });

export const getMobileAccountsAtlysAmdocs = (addParams?: string) =>
  action(MobileAccountsTypes.LOAD_REQUEST_ATLYS_AMDOCS, { addParams });

export const loadSuccess = (data: IMobileAccounts[]) =>
  action(MobileAccountsTypes.LOAD_SUCCESS, { data });
