import classNames from 'classnames';
import React from 'react';
import { RewardSituation as RS } from 'store/ducks/rewards/rewards/types';
import Style from './RewardSituation.module.scss';

interface IOwnProps {
  status: number;
  parentClassCSS?: string;
}

const getStatus = (status: number) => {
  switch (status) {
    case RS.SOLDOFF:
      return 'Esgotado';
    case RS.EXPIRED:
      return 'Expirado';
    case RS.REDEEMED:
      return 'Resgatado';
  }
};

const setBackgrounColor = (status: number): boolean => {
  return status == 1 || status == 2;
};

const RewardSituation: React.FC<IOwnProps> = ({ status, parentClassCSS }) => {
  return status ? (
    <span
      className={classNames([
        Style.container,
        { [`${Style.backgroundRed}`]: setBackgrounColor(status) },
        { [`${Style.backgroundGreen}`]: !setBackgrounColor(status) },
        parentClassCSS,
      ])}
    >
      {getStatus(status)}
    </span>
  ) : null;
};

export default RewardSituation;
