export enum RewardInternetPackageOrderTypes {
  LOAD_REQUEST = '@internet_package_order/LOAD_REQUEST',
  LOAD_SUCCESS = '@internet_package_order/LOAD_SUCCESS',
}

export interface IRewardInternetPackageOrderRequest {
  rewardId: number;
  accountID: number;
  productSystemID: number;
  activationChannel: string;
}

export interface IRewardInternetPackageOrderResponse {
  tag: string;
  name: string;
  periodValidate: number;
  rewardID: number;
  partnerID: number;

  descriptionBenefit: string;
  descriptionRedeeem: string;
  evaluationDescription: string;
  locationID: number;
  ready: string;
  termsOfUseBenefit: string;
  useBenefit: string;
  defaultText: string;
  orderRewardID: number;
  orderStatus: number;
  ownTerms: boolean;
  terms: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
}

export interface IRewardInternetPackageOrderState {
  readonly data: IRewardInternetPackageOrderResponse;
  readonly loading: boolean;
}

export enum IRewardInternetPackageOrderStatus {
  CREATE = 1,
  PAYMENTAUTHORIZED = 2,
  UNCONFIRMEDPAYMENT = 3,
  AUTHENTICATIONREQUIRED = 4,
  LIVECANCELLATION = 5,
  CONFIRMED = 6,
  ERROR = 7,
  UNDEFINED = 8,
  CANCELLED = 9,
}
