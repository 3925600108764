import { Reducer } from 'redux';
import { IRewardCinemarkDetailsState, RewardCinemarkDetailsTypes } from './types';

const INITIAL_STATE: IRewardCinemarkDetailsState = {
  data: {
    rewardID: 0,
    tag: '',
    title: '',
    description: '',
    endDate: '',
    terms: '',
    securityTokenProtected: false,
    urlDownload210: '',
    urlDownload384: '',
    urlDownload430: '',
    urlDownload480: '',
    urlDownload690: '',
    success: false,
    errorMessages: false,
    isRewardExtra: false,
    isBadgeExtra: false,
    badgeIcon: '',
    mainCategoryId: 0,
    nameCategory: '',
    partnerID: 0,
  },
  loading: true,
};

const reducer: Reducer<IRewardCinemarkDetailsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardCinemarkDetailsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardCinemarkDetailsTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    case RewardCinemarkDetailsTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
