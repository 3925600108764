import { action } from 'typesafe-actions';
// tslint:disable-next-line: prettier
import {
  IOrderBenefitWithoutSeasonRequest,
  IOrderBenefitWithoutSeasonResponse,
  OrderBenefitWithoutSeasonTypes,
} from './types';

export const saveOrderBenefitWithoutSeason = (data: IOrderBenefitWithoutSeasonRequest) =>
  action(OrderBenefitWithoutSeasonTypes.LOAD_REQUEST, { data });

export const loadSuccess = (data: IOrderBenefitWithoutSeasonResponse) =>
  action(OrderBenefitWithoutSeasonTypes.LOAD_SUCCESS, { data });
