import { Reducer } from 'redux';
import { ActiveRedemptionsTypes, IActiveRedemptionsState } from './types';

const INITIAL_STATE: IActiveRedemptionsState = {
  data: {
    pageRows: 0,
    numberPage: 0,
    totalRows: 0,
    hasDeviceVoucherRedeemed: {
      voucherTypeID: 0,
      name: '',
      hasVoucherRedeemed: false,
    },
    hasAcessoryVoucherRedeemed: {
      voucherTypeID: 0,
      name: '',
      hasVoucherRedeemed: false,
    },
    hasMultipleVoucherRedeemed: {
      voucherTypeID: 0,
      name: '',
      hasVoucherRedeemed: false,
    },
    data: [],
  },
  lastPositionCard: 0,
  loading: true,
  error: false,
};

const reducer: Reducer<IActiveRedemptionsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActiveRedemptionsTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case ActiveRedemptionsTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: {
          ...action.payload.data,
          data: [...state.data.data, ...action.payload.data.data],
        },
        error: false,
        loading: false,
      };
    case ActiveRedemptionsTypes.CLEAR_DATA:
      return {
        ...state,
        data: {
          ...INITIAL_STATE.data,
        },
      };
    case ActiveRedemptionsTypes.GET_LAST_POSITION:
      return {
        ...state,
        lastPositionCard: action.payload.lastPositionCard,
      };
    default:
      return state;
  }
};

export default reducer;
