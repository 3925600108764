import { Reducer } from 'redux';
import { ISaveEvaluationAwnserState, SaveEvaluationTypes } from './types';

const INITIAL_STATE: ISaveEvaluationAwnserState = {
  req: {
  },
  data: {
    answerEvaluationID: 0,
    option: 0,
    orderReward: 0,
    participantID: ''
  },
  loading: false,
};

const reducer: Reducer<ISaveEvaluationAwnserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SaveEvaluationTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case SaveEvaluationTypes.LOAD_FAILURE:
      return { ...state, loading: false, error: true };
    case SaveEvaluationTypes.LOAD_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
