export enum SurveyListTypes {
  LOAD_REQUEST = '@survey_list/LOAD_REQUEST',
  LOAD_SUCCESS = '@survey_list/LOAD_SUCCESS',
  LOAD_ERROR = '@survey_list/LOAD_ERROR',
}

export interface ISurvey {
  campaignID: number;
  name: string;
  campaignTypeID: number;
  description: string;
  rewardID: string;
  externalURL: string;
  createDate: string;
  categoryID: null;
  startShowDate: string;
  newUserExclusive: boolean;
  server: null;
  directory: null;
  original: string;
  timesShowed: null;
  campaignTotalShow: number;
  identityExclusive: boolean;
  segments: null;
  states: null;
  callActionIntercept: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
}

export interface ISurveyListState {
  readonly data: ISurvey[];
  readonly loading: boolean;
  readonly isHasSurveyList: boolean;
}
