import { action } from 'typesafe-actions';
import { IRewardWithoutSeasonDetails, RewardWithoutSeasonDetailsTypes } from './types';

export const getRewardWithoutSeasonDetails = (id: number, isDeeplink: boolean) =>
  action(RewardWithoutSeasonDetailsTypes.LOAD_REQUEST, { id, isDeeplink });

export const loadSuccess = (data: IRewardWithoutSeasonDetails) =>
  action(RewardWithoutSeasonDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(RewardWithoutSeasonDetailsTypes.LOAD_RESET);
