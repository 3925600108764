import classNames from 'classnames';
import BoxImagesResponsives from 'components/BoxImagesResponsives/BoxImagesResponsives';
import RewardSituation from 'components/RewardSituation/RewardSituation';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IRewardHighlightcoItems, RewardSituation as RS } from 'store/ducks/rewards/rewards/types';
import Style from './RewardItem.module.scss';
import TagHome from 'components/TagHome/TagHome';

interface IOwnProps extends RouteComponentProps {
  reward: IRewardHighlightcoItems;
  disabled: boolean;
  key: any;
  pushInteraction(): void;
}

const RewardItem: React.FC<IOwnProps> = ({ 
  reward, 
  pushInteraction, 
  disabled, 
  history,
  key
}) => {
  const {
    link,
    urlDownload430,
    title,
    description,
    situation,
    isRewardExtra,
    tag,
    endShowDate,
  } = reward;
  const isUnavailable =
    situation == RS.EXPIRED ||
    situation == RS.SOLDOFF ||
    (situation == RS.ACTIVE && disabled && !isRewardExtra) ||
    !link;

  const isApplyColorGray =
    situation == RS.SOLDOFF ||
    situation == RS.EXPIRED ||
    (RS.ACTIVE == situation && disabled && !isRewardExtra);

  return (
      <div 
        key={key} 
        className={Style.WrapBanner}
        onClick={() => {
          if (isUnavailable) {
            return;
          }
          history.push(link);
          pushInteraction()
        }}
      >
        <RewardSituation status={situation} />
        <div className={Style.WrapImg}>
          <div className={Style.banner}>
            <img className={Style.image} src={urlDownload430} />
          </div>
        </div>
        <div className={Style.description}>
          <span className={Style.title}>{title}</span>
          <p className={Style.text}>{description}</p>
          {/* <div className={Style.interectionBox}>
            <div className='tagHome'>
              <TagHome tagHome={tag} endShowDate={endShowDate} />
            </div>
            <div className='views'></div>
          </div> */}
        </div>
      </div>
  );
};

export default withRouter(RewardItem);
