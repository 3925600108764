import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import Api from '_services/_API/Api';
import { loadSurvey } from './actions';

export function* getInterceptionSurvey(action) {
  try {
    const { data } = yield call(
      Api.get,
      `v1/api/campaign/intercept/show?lightBoxPageID=${action.payload.pageId}&rewardID=${action.payload.rewardID}`,
    );
    yield put(loadSurvey(data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}

export function* updateTimesShowedByCampaign(action) {
  try {
    yield call(Api.get, `v1/api/campaign/intercept/update/${action.payload.campaignID}`);
  } catch (error) {
    yield put(setError(error.response));
  }
}

export function* sendSurveyGiveUp(action) {
  try {
    yield call(Api.get, `v1/api/campaign/notnow/${action.payload.campaignID}`);
  } catch (error) {
    yield put(setError(error.response));
  }
}
