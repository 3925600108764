import { Reducer } from 'redux';
import { IRewardCellphoneAndGadgetsOrderState, RewardCellphoneAndGadgetsOrderTypes } from './types';

const INITIAL_STATE: IRewardCellphoneAndGadgetsOrderState = {
  data: {
    orderRewardID: 0,
    partnerID: 0,
    rewardID: 0,
    tag: '',
    title: '',
    endDate: '',
    description: '',
    howToUse: '',
  },
  loading: true,
};

const reducer: Reducer<IRewardCellphoneAndGadgetsOrderState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RewardCellphoneAndGadgetsOrderTypes.LOAD_REQUEST:
      return { ...state, loading: true };
    case RewardCellphoneAndGadgetsOrderTypes.LOAD_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
      };
    case RewardCellphoneAndGadgetsOrderTypes.LOAD_RESET:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default reducer;
