import { IAccounts } from 'store/ducks/participant/accounts/types';

export enum RewardInternetPackageTypes {
  LOAD_REQUEST = '@internet_package/LOAD_REQUEST',
  LOAD_SUCCESS = '@internet_package/LOAD_SUCCESS',
  LOAD_RESET = '@internet_package/LOAD_RESET',
  SELECT_ACCOUNT = '@internet_package/SELECT_ACCOUNT',
  SET_REWARD_INTERNET_PACKAGE = '@internet_package/SET_REWARD_INTERNET_PACKAGE',
}

export interface IRewardInternetPackage {
  rewardID: number;
  tag: string;
  title: string;
  description: string;
  maxPurchaseDate: string;
  terms: string;
  endPeriod: string;
  startPeriod: string;
  product: string;
  productSystemAtlysID: number;
  productSystemAmdocsID: number;
  productSystemNginID: number;
  securityTokenProtected: boolean;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload430: string;
  urlDownload480: string;
  urlDownload690: string;
  success?: boolean;
  errorMessages?: boolean;
  account?: IAccounts;
  selectedId?: number;
  isRewardExtra: boolean;
  isBadgeExtra: boolean;
  badgeIcon: string;
  mainCategoryId: number;
  nameCategory: string;
  partnerID: number;

  alertMessage: string;
  descriptionBenefit: string;
  descriptionRedeeem: string;
  evaluationDescription: string;
  locationID: number;
  mainCategoryID: number;
  ready: string;
  termsOfUseBenefit: string;
  useBenefit: string;
}

export interface IRewardInternetPackageState {
  data: IRewardInternetPackage;
  loading: boolean;
  error: boolean;
}
