import { action } from 'typesafe-actions';
import { BenefitOfferDetailsTypes, IBenefitOfferDetails } from './types';

export const getBenefitOfferDetails = (id: number, isfirstCount: boolean) =>
  action(BenefitOfferDetailsTypes.LOAD_REQUEST, { id, isfirstCount });

export const loadSuccess = (data: IBenefitOfferDetails) =>
  action(BenefitOfferDetailsTypes.LOAD_SUCCESS, { data });

export const loadReset = () => action(BenefitOfferDetailsTypes.LOAD_RESET);
