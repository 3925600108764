export enum RewardTravelOrderTypes {
  LOAD_REQUEST = '@travel_order/LOAD_REQUEST',
  LOAD_SUCCESS = '@travel_order/LOAD_SUCCESS',
  SCHEDULLING = '@travel_order_schedule/SCHEDULE',
  ONLYONEDAY = '@quantity_days/SCHEDULE',
}

export interface IOrderRewardTravelBodyRequest {
  RewardID: number;
  AccountID: number;
  OrderRewardID?: boolean;
  ProductSystemID: number;
  StartDate: string | undefined;
  EndDate: string | undefined;
  ActivationChannel: string;
}

export interface IOrderRewardTravelResponse {
  orderRewardID: number;
  tag: string;
  name: string;
  startDate: Date | undefined;
  endDate: Date | undefined;
  partnerID: number;
}

export interface IRewardTravelOrderState {
  readonly data: IOrderRewardTravelResponse;
  readonly loading: boolean;
  readonly schedulling: boolean;
  readonly isOnlyOneDay: boolean;
}
