import classNames from 'classnames';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Style from './BackButton.module.scss';

const BackButton = ({ history }) => {
  return <i onClick={history.goBack} className={classNames([Style.button])} />;
};

export default withRouter(BackButton);
