import { action } from 'typesafe-actions';
import { ActiveRedemptionsTypes, IActiveRedemptionsPagination } from './types';

export const getActiveRedemptions = (page: number) =>
  action(ActiveRedemptionsTypes.LOAD_REQUEST, { page });

export const loadSuccess = (data: IActiveRedemptionsPagination) =>
  action(ActiveRedemptionsTypes.LOAD_SUCCESS, { data });

export const clearActiveRedemptions = () => action(ActiveRedemptionsTypes.CLEAR_DATA);

export const getLastPositionCard = (lastPositionCard: number) =>
  action(ActiveRedemptionsTypes.GET_LAST_POSITION, { lastPositionCard });
