import classNames from 'classnames';
import React from 'react';
import Style from './TitleSectionHome.module.scss';
interface IOwnProps {
  title: string;
  parentClassCss?: string;
}

const TitleSectionHome = ({ title, parentClassCss = '' }: IOwnProps) => {
  return <h2 className={classNames([Style.title, parentClassCss])}>{title}</h2>;
};

export default TitleSectionHome