export enum OrderBenefitWithSeasonTypes {
  LOAD_REQUEST = '@order_benefit_with_season/LOAD_REQUEST',
  LOAD_SUCCESS = '@order_benefit_with_season/LOAD_SUCCESS',
}

export interface IOrderBenefitWithSeasonRequest {
  RewardID: string;
  SessionID: string;
  SessionTicketID: string;
  StateId: string;
  ActivationChannel: string;
}

export interface IRewadSessionTicketsOrderItem {
  rewadSessionTicketsOrderItemID: number;
  ticketOrderItemAmount: number;
}

export interface IOrderBenefitWithSeasonResponse {
  orderRewardID: number;
  ticketItemAmount: number;
  name: string;
  location: string;
  date: string;
  time: string;
  qrCode: string;
  qrCodeUrlImage: string;
  howtoUse: string;
  sessionName: string;
  ticketPickUpPlace: string;
  isSponsored: boolean;
  rewardID: number;
  partnerID: number;
  locationID: number;
  enabledCTA: boolean;
  rewadSessionTicketsOrderItem: IRewadSessionTicketsOrderItem[];
}

export interface IOrderBenefitWithSeasonState {
  readonly req: IOrderBenefitWithSeasonRequest;
  readonly data: IOrderBenefitWithSeasonResponse;
  readonly loading: boolean;
}
