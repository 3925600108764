import React, { Component } from 'react';
import Style from './ErrorBoundaryNewLayout.module.scss';

import { ComponentType, InteractionEvent, PushTagInteraction } from '_utils/TagManager';
import WarningRed from 'assets/images/warning-red.svg';
import WarningPurple from 'assets/images/warning-purple.svg';
import ErrorDefault from 'components/ErrorDefault/ErrorDefault'
import ErrorContainer from 'components/ErrorContainer/ErrorContainer'
import ButtonDefault from 'components/ButtonDefault/ButtonDefault';

export interface IErroBoundaryState {
  hasError: boolean;
  error: any;
  errorInfo: any;
  code: any;
}

class ErrorBoundaryNewLayout extends Component<any, IErroBoundaryState> {
  
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null, code: null };
  }
  
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    let NewCode:any = 711;
    let NewDescription:string = 'Erro Genérico';

    if (error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].internalCode > 0) {
      NewCode = error.errorMessages[0].internalCode ? error.errorMessages[0].internalCode : 711;
      NewDescription = error.errorMessages[0].internalDescription ? error.errorMessages[0].internalDescription : '';

      this.setState({
        hasError: true,
        error,
        errorInfo: NewDescription,
        code: NewCode > 0 ? NewCode : 711
      });
    } else {
      if (error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].code) {
        NewCode = error.errorMessages[0].code;
        NewDescription = error.errorMessages[0].description;
      }

      this.setState({
        hasError: true,
        error,
        errorInfo: NewDescription,
        code: NewCode > 0 ? NewCode : 711
      });
    }

    PushTagInteraction(
      ComponentType.SCREEN,
      InteractionEvent.VIEW_ERRO,
      `erro-acesso: ${NewCode}-${NewDescription}`,
    );
    
    console.log('****************************************************')
    console.log('TELA DE ERROS BOUNDARY, ERROS NÃO MAPEADOS')
    console.log('ERRO: ' + JSON.stringify(error))
    console.log('COD ERRO INTERNO: ' + NewCode)
    console.log('MENSAGEM ERRO INTERNO: ' + NewDescription)
    console.log('COD ERRO: ' + error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].code ? error?.errorMessages[0]?.code : 0)
    console.log('MENSAGEM ERRO: ' + error && error.errorMessages && error.errorMessages.length > 0 && error.errorMessages[0].description ? error?.errorMessages[0]?.description : '')
    console.log('ERRO ESTRUTURA ANTIGA: ' + error.message)
    console.log('INFO ESTRUTURA ANTIGA: ' + JSON.stringify(info))
    console.log('****************************************************')
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer>
          <ErrorDefault 
            title={'Algo deu errado por aqui'}
            message={'Você pode tentar de novo agora ou fazer isso mais tarde'}
            code={this.state.code}
            icone={WarningRed}
          />
          <div className={Style.wrapBtn}>
            <ButtonDefault 
              func={() => {}}
              colorButton={'Primary'}
              label={'Tentar de novo'}
              route={'https://web.vivo.com.br/_/account/redirect.php?target=valoriza'}
              isSmall={true}
              isLoading={false}
              isInverse={false}
              directionText={'Center'}
              parentClassCss={Style.btn}
              target={''}
            />
          </div>
        </ErrorContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryNewLayout;
