import { Reducer } from 'redux';
import { BadgesTypes, IBadge, IBadgesState } from './types';

const INITIAL_STATE: IBadgesState = {
  data: [],
  loading: false,
  badge: {
    badgeID: 0,
    rewardID: 0,
    clusterID: 0,
    title: '',
    descricao: '',
    badgeIcon: '',
    createDate: '',
    updateDate: '',
  },
};

const selectBadge = (badgeId: number, data: IBadge[]): IBadge => {
  const badge = data.find(badge => badge.badgeID == badgeId);
  return badge ? badge : INITIAL_STATE.badge;
};

const reducer: Reducer<IBadgesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BadgesTypes.LOAD_REQUEST:
      return { ...state, loading: false };
    case BadgesTypes.LOAD_SUCCESS:
      return { ...state, loading: true, data: action.payload.data };
    case BadgesTypes.SELECT_BADGE:
      return { ...state, badge: selectBadge(action.payload.id, state.data) };
    case BadgesTypes.LOAD_RESET:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default reducer;
