export enum UseRewardCellphoneGadgetsDetailsTypes {
  LOAD_REQUEST = '@use_reward_cellphone_gadgets_details/LOAD_REQUEST',
  LOAD_SUCCESS = '@use_reward_cellphone_gadgets_details/LOAD_SUCCESS',
}

export interface IUseRewardCellphoneGadgets {
  currencyValue: number;
  daysToExpire: number;
  statusDescription: string;
  status: number;
  transactionType: string;
  transactionDate: string;
  expirationDate: string;
  redemptionDate: string;
  transactionValue: number;
  redemptionValue: number;
  expirationValue: number;
}

export interface IUseRewardCellphoneGadgetsDetails {
  rewardDiscountVouchers: IUseRewardCellphoneGadgets[];
  total: number;
}

export interface IUseRewardCellphoneGadgetsDetailsState {
  readonly data: IUseRewardCellphoneGadgetsDetails;
  readonly loading: boolean;
}
