import React from 'react';
import BoxImagesResponsives from '../BoxImagesResponsives/BoxImagesResponsives';
import Style from './BannerLargeFooter.module.scss';
import LinkTo from '../../components/LinkTo/LinkTo';
import TitleSectionHome from '../../components/TitleSectionHome/TitleSection'
import { Link } from 'react-router-dom';
import { LinkTargetEnum } from '_utils/LinkTarget.enum';
import ButtonDefault from 'components/ButtonDefault/ButtonDefault';
import BoxImagesSingleSize from 'components/BoxImagesSingleSize/BoxImagesSingleSize';

interface IOwnProps {
  title: string | null;
  description: string;
  link: string;
  linkTitle: string;
  linkTargetId?: LinkTargetEnum;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  urlDownload430: string;
  pushInteraction(): void;
}

const BannerLargeFooter: React.FC<IOwnProps> = ({
  title,
  description,
  link,
  linkTitle,
  linkTargetId,
  urlDownload210,
  urlDownload384,
  urlDownload480,
  urlDownload690,
  urlDownload430,
  pushInteraction,
}) => {
  return (
    <>
      {title &&
        <div id="cinemark-banner" className={Style.card}>
          <TitleSectionHome title="Cinemark" />
          <div className={Style.WrapBanner}>
          <BoxImagesSingleSize classCss={Style.image} title={'Cinemark'} urlDownload430={urlDownload430} urlDownload480={urlDownload480} />
            <div className={Style.description}>
              <span className={Style.title}>{title}</span>
              <p className={Style.text}>{description}</p>
              {link &&
                <div className={Style.wrapLink}>
                  <ButtonDefault 
                    func={() => {
                      pushInteraction();
                    }}
                    colorButton={'Primary'}
                    label={linkTitle}
                    route={link}
                    isSmall={true}
                    isLoading={false}
                    isInverse={false}
                  />
                  <ButtonDefault 
                    func={() => {
                      pushInteraction();
                    }}
                    colorButton={'Secondary'}
                    label={'Regulamento Cinemark'}
                    route={'/regulationpage/cinemark'}
                    isSmall={true}
                    isLoading={false}
                    isInverse={false}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default BannerLargeFooter;
