export enum EvaluationTypes {
  LOAD_REQUEST = '@evaluation/LOAD_REQUEST',
  LOAD_SUCCESS = '@evaluation/LOAD_SUCCESS',
  LOAD_RESET = '@evaluation/LOAD_RESET',
}

export interface IEvaluation {
  amountStar: number; 
  answerEvaluation: null;
  charactersAmount: string;
  commentDescription: string;
  evaluationID: number;
  evaluationLinked: [];
  evaluationSatisfaction: [];
  isAvailable: boolean;
  mainQuestion: string;
  namePartner: string;
  scaleEvaluation: {};
  starMandatory: number;
}

export interface IEvaluationState {
  readonly data: IEvaluation;
  readonly loading: boolean;
}