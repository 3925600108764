import { Reducer } from 'redux';
import { IOurPartnersState, OurPartnersTypes } from './types';

const INITIAL_STATE: IOurPartnersState = {
  data: [
    {
      indexWord: '',
      partners: [],
    },
  ],
  loading: true,
  indexWordSelected: '',
};

const reducer: Reducer<IOurPartnersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OurPartnersTypes.LOAD_REQUEST:
      return {
        ...state,
        stateID: 0,
        loading: true,
      };
    case OurPartnersTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case OurPartnersTypes.SET_INDEX_WORD_SELECTED:
      return {
        ...state,
        indexWordSelected: action.payload.indexWordSelected,
      };
    default:
      return state;
  }
};

export default reducer;
