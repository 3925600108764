import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadEvaluation(action) {
  try {
    const response = yield call(API.get, `v1/api/evaluation/getEvaluation/${action.payload.id}`);
    yield put(loadSuccess(response.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}