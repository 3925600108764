import classNames from 'classnames';
import React from 'react';
import Style from './Logotype.module.scss';

interface IOwnProps {
  parentClassCss: string;
}

const Logotype: React.FC<IOwnProps> = ({ parentClassCss }) => {
  return <h1 className={classNames([Style.title, parentClassCss])}>vivo valoriza</h1>;
};

export default Logotype;
