import { call, put } from 'redux-saga/effects';
import { setError } from 'store/ducks/error';
import API from '_services/_API/Api';
import { loadSuccess } from './actions';

export function* loadOurPartners(action) {
  try {
    const { data } = yield call(
      API.get,
      `v1/api/search/partner/list?categoryId=0&stateId=${action.payload.stateID}`,
    );
    yield put(loadSuccess(data.data));
  } catch (error) {
    yield put(setError(error.response));
  }
}
