// Action types
export enum AccountsTypes {
  LOAD_REQUEST = '@accounts/LOAD_REQUEST',
  LOAD_SUCCESS = '@accounts/LOAD_SUCCESS',
}

// Data types
export interface IAccounts {
  accountId: number;
  state: string;
  areaCode: string;
  lineNumber: string;
  areaCodeLineNumber: string;
  externalAccount: number;
  externalProductCode: string;
  platform: string;
  sourceSystem: string;
  channel: string;
  productName: string;
  billingSource: string;
}
// State type
export interface IAccountsState {
  readonly data: IAccounts[];
  readonly loading: boolean;
}
