import API from '../../../_services/_API/Api';
import { call, put } from 'redux-saga/effects';
import { loadSuccess } from './actions';

export function* loadTextParam(action) {
  try {
    const { data } = yield call(API.get, `v1/api/screenTextParameterizations/all`);
    yield put(loadSuccess(data.data));
  } catch (error) {
    console.log(error.response);
  }
}
