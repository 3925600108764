import { action } from 'typesafe-actions';
import { UseBenefitWithSeasonDetailsTypes, IUseBenefitWithSeasonDetails, IUseBenefitWithSeasonCancel } from './types';

export const getUseBenefitWithSeasonDetails = (id: number) =>
  action(UseBenefitWithSeasonDetailsTypes.LOAD_REQUEST, { id });
export const cancelBenefitWithSeason = (id: number) =>
  action(UseBenefitWithSeasonDetailsTypes.LOAD_CANCEL_REQUEST, { id });
export const loadSuccess = (data: IUseBenefitWithSeasonDetails) =>
  action(UseBenefitWithSeasonDetailsTypes.LOAD_SUCCESS, { data });
export const loadCancelSuccess = (data: IUseBenefitWithSeasonCancel) =>
  action(UseBenefitWithSeasonDetailsTypes.LOAD_CANCEL_SUCCESS, { data });
export const loadCancelError = (data: IUseBenefitWithSeasonCancel) =>
  action(UseBenefitWithSeasonDetailsTypes.LOAD_CANCEL_ERROR, { data });

