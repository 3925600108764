import { LinkTargetEnum } from '_utils/LinkTarget.enum';

export enum RewardUnforgettableBannerTypes {
  LOAD_REQUEST = '@rewardUnforgettableTypes/LOAD_REQUEST',
  LOAD_SUCCESS = '@rewardUnforgettableTypes/LOAD_SUCCESS',
  LOAD_FAILURE = '@rewardUnforgettableTypes/LOAD_FAILURE',
}
export interface IRewardUnforgettableBanner {
  unforgettableRedeemId: number;
  title: string;
  linkTitle: string;
  unforgettableRedeemTypeLinkId?: LinkTargetEnum;
  link: string;
  description: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  urlDownload430: string;
}

export interface IRewardUnforgettableBannerState {
  readonly data: IRewardUnforgettableBanner;
  readonly loading: boolean;
  readonly error: boolean;
}
