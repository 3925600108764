export enum SurveyInterceptionTypes {
  LOAD_REQUEST = '@survey_interception/LOAD_REQUEST',
  LOAD_SUCCESS = '@survey_interception/LOAD_SUCCESS',
  RESET_INTERCEPTION_SURVEY = '@survey_interception/RESET_INTERCEPTION_SURVEY',
  GET_ROUTE_OF_NAVIGATION = '@survey_interception/GET_ROUTE_OF_NAVIGATION',
  GET_ACTION_OF_REWARD = '@survey_interception/GET_ACTION_OF_REWARD',
  SET_SHOWED_SURVEY = '@survey_interception/SET_SHOWED_SURVEY',
  UPDATE_TIMES_SHOWED = '@survey_interception/UPDATE_TIMES_SHOWED',
  SEND_GIVEUP = '@survey_interception/SEND_GIVEUP',
}

export enum SurveyInterceptionPageId {
  LIST_SURVEY = 0,
  HOME = 1,
  MY_VALORIZA = 3,
  REWARD_START = 4,
  REWARD_END = 5,
}

export const surveyInterceptionUrl = '/pesquisa/apresentacao';
export const surveyInterceptionUrlRewardStart = `${surveyInterceptionUrl}/${SurveyInterceptionPageId.REWARD_START}`;
export const surveyInterceptionUrlRewardEnd = `${surveyInterceptionUrl}/${SurveyInterceptionPageId.REWARD_END}`;

export interface ISurveyInterception {
  campaignID: number;
  name: string;
  campaignTypeID: number;
  description: string;
  rewardID: string;
  externalURL: string;
  createDate: string;
  categoryID: null;
  startShowDate: string;
  newUserExclusive: boolean;
  server: null;
  directory: null;
  original: string;
  timesShowed: null;
  campaignTotalShow: number;
  identityExclusive: boolean;
  segments: null;
  states: null;
  callActionIntercept: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
}

export interface ISurveyInterceptionState {
  readonly data: ISurveyInterception[] | any;
  readonly showedSurveys: number[];
  readonly loading: boolean;
  readonly isViewSurveyPageHome: boolean;
  readonly isViewSurveyPageMyValoriza: boolean;
  readonly rewardID: number;
  readonly isNotInterceptionOfReward: boolean;
  readonly routeOfnavigation: string;
  readonly actionOfReward: any;
  readonly loadingToReward: boolean;
  readonly campaignID: number;
  readonly pageId: number;
}
