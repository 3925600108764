export enum SearchTypes {
  LOAD_REQUEST = '@search/LOAD_REQUEST',
  LOAD_SUCCESS = '@search/LOAD_SUCCESS',
  LOAD_ERROR = '@search/LOAD_ERROR',
  RESET_SEARCH_FILTER_ID = '@search/RESET_SEARCH_FILTER_ID',
  SET_SEARCH_VALUE = '@search/SET_SEARCH_VALUE',
  SET_SEARCH_VALUE_AND_REDIRECT = '@search/SET_SEARCH_VALUE_AND_REDIRECT',
  SET_CATEGORY_ID = '@search/SET_CATEGORY_ID',
  SET_EVENTTYPE_IDS = '@search/SET_EVENTTYPE_IDS',
  SET_GENRE_IDS = '@search/SET_GENRE_IDS',
  SET_REGION_ID = '@search/SET_REGION_ID',
  SET_LABEL_SEARCH_COMMON = '@search/SET_LABEL_SEARCH_COMMON',
  SET_LABEL_SEARCH_BENEFITS = '@search/SET_LABEL_SEARCH_BENEFITS',
}

export interface ISearchRequest {
  partnerName: string;
  stateId: number;
  partnerID?: number;
}

export interface ISearchActionRequest {
  filter: ISearchRequest;
}

export interface ISearchItems {
  rewardID: number;
  tag: string;
  situation: number;
  title: string;
  link: string;
  urlDownload210: string;
  urlDownload384: string;
  urlDownload480: string;
  urlDownload690: string;
  partnerID: number;
  locationID: number;
  mainCategoryID: number;
}

export interface ISearchResult {
  items: ISearchItems[];
  numberPage: number;
  totalRows: number;
  pageRows: number;
}

export enum SearchLabelType {
  LABEL_SEARCH_COMMON = 'Resultados da busca:',
  LABEL_SEARCH_BENEFITS = 'Benefícios',
}

export interface ISearchState {
  readonly data: ISearchResult;
  readonly isEmpty: boolean;
  readonly emptyValue: string;
  readonly filter: ISearchRequest;
  readonly loading: boolean;
  readonly label: SearchLabelType;
}
